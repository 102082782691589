import {
  AvailabilityPreferenceV3ExperimentJoinKeys,
  SearchForCareHomebaseHeaderExperimentJoinKeys,
  UrgencyToBookExperimentJoinKeys,
} from './searchForCareExperimentTypes'
import { COMMON_MIXPANEL_PAGE } from '../common/constants/mixpanelConstants'

export enum ExperimentUUIDs {
  LatestMatchesUUID = '65658a7b-e7c2-4fbf-923f-5640ffe20954',
  SearchForCareHomebaseHeaderUUID = '7491d7ac-742d-4687-89ae-3f338fd27eba',
  TriageSummaryUUID = 'd03d7916-6f2e-4bdc-b7db-d6fba47f3a43',
  AvailabilityPreferenceV3UUID = '2f1eb991-f6fa-4387-9281-a335307cf277',
  UrgencyToBookUUID = '2979101c-6651-4b5c-9e78-54985bcdf955',
}

export const LatestMatchesArmMapping = {
  CAN_SEE: 'can see past provider result',
  CANNOT_SEE: 'cannot see past provider result',
}

export enum AvailabilityPreferencesV3ArmMapping {
  CAN_SEE = 'can see availability preference',
  CANNOT_SEE = 'cannot see availability preference',
}

export enum UrgencyToBookArmMapping {
  CAN_SEE = 'sees urgency to book v2',
  CANNOT_SEE = 'does not see urgency to book v2',
}

export enum MemberPreferencesPageLocation {
  ENTRYPOINT = 'member preferences entrypoint page',
  PREFERENCE_SELECTION = 'member preferences preference selection page',
}

export enum UrgencyToBookV2PageLocation {
  PROVIDER_PROFILE = COMMON_MIXPANEL_PAGE.PROVIDER_PROFILE,
  APPOINTMENT_SELECTION = COMMON_MIXPANEL_PAGE.APPOINTMENT_SELECTION,
}

interface LatestMatchesExperimentJoinKeys {
  client_lyra_id: string
}

type JoinKeys =
  | LatestMatchesExperimentJoinKeys
  | SearchForCareHomebaseHeaderExperimentJoinKeys
  | AvailabilityPreferenceV3ExperimentJoinKeys
  | UrgencyToBookExperimentJoinKeys

interface Assignment {
  arm: string
  joinKeys: JoinKeys
}

interface Experiment {
  assignment: Assignment[]
}

interface Experiments {
  [key: string]: Experiment
}

export interface ExperimentPayload {
  experimentUUID: string
  deviceUUID: string
  experiments: Experiments
}
