import { UTM_CAMPAIGN, UTM_MEDIUM, UTM_SOURCE } from '../common/constants/utm'

export const ELECTION_UTM_CAMPAIGN = UTM_CAMPAIGN.ELECTION
export const ELECTION_UTM_SOURCE = UTM_SOURCE.HOMEBASE
export const ELECTION_UTM_MEDIUM = UTM_MEDIUM.PRODUCT

export const ELECTION_HOMEBASE_UTM_PARAMS = `utm_campaign=${ELECTION_UTM_CAMPAIGN}&utm_source=${ELECTION_UTM_SOURCE}&utm_medium=${ELECTION_UTM_MEDIUM}`

export const NEW_YEAR_UTM_CAMPAIGN = UTM_CAMPAIGN.NEW_YEAR

export const NEW_YEAR_WEBINAR_LINK = '/secure/index/essentials/events' // '/secure/index/essentials/events/the-science-of-living-well'
