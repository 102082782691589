import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { toNumber } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  getWellnessCheckInTotalNumPages,
  WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET,
  WELLNESS_CHECK_IN_EVENTS,
  WELLNESS_CHECK_IN_SOCIAL_CARE_PAGE_NUMBER,
  WellnessCheckInDomain,
} from '@lyrahealth-inc/shared-app-logic'
import {
  NavBar,
  ThemeType,
  WellnessCheckInBackgroundDesktop,
  WellnessCheckInBackgroundMobile,
  WellnessCheckInBackgroundTablet,
  WellnessCheckInDomainHeader,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { getIsLyraSocialCareEnabled } from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { useAppDispatch } from '../../../data/storeConfiguration/store'
import { PAGE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'
import { REDIRECT_URL_KEY } from '../../constants/routingConstants'

export enum WELLNESS_CHECK_IN_PAGE_TYPE {
  Overview,
  Questionnaire,
  Results,
  Preview,
}

export const WellnessCheckInHeader = ({ pageType }: { pageType: WELLNESS_CHECK_IN_PAGE_TYPE }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pageIndex } = useParams()
  const pageNumber = toNumber(pageIndex)
  const {
    breakpoints: { isMinWidthLaptop, isMobileSized },
    colors,
  } = useTheme() as ThemeType
  const socialCareEnabled = useSelector(getIsLyraSocialCareEnabled)

  const isOverviewPage = pageType === WELLNESS_CHECK_IN_PAGE_TYPE.Overview
  const isQuestionnairePage = pageType === WELLNESS_CHECK_IN_PAGE_TYPE.Questionnaire
  const isResultsPage =
    pageType === WELLNESS_CHECK_IN_PAGE_TYPE.Results || pageType === WELLNESS_CHECK_IN_PAGE_TYPE.Preview
  const isPreviewPage = pageType === WELLNESS_CHECK_IN_PAGE_TYPE.Preview

  const showDomainInHeader =
    pageNumber >= WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET &&
    pageNumber < WELLNESS_CHECK_IN_SOCIAL_CARE_PAGE_NUMBER

  const handleBackPress = () => {
    if (isOverviewPage) {
      navigate('/')
    } else if (isQuestionnairePage) {
      if (pageNumber === 1) {
        navigate('/wellness-check-in')
      } else {
        navigate(`/wellness-check-in/${pageNumber - 1}`)
      }
    }
  }

  const handleExitPress = () => {
    // makes sure the user navigates to homebase and isn't redirected to triage
    if (window.sessionStorage.getItem(REDIRECT_URL_KEY)) {
      window.sessionStorage.removeItem(REDIRECT_URL_KEY)
    }
    dispatch(
      trackEventWithObj({
        event: WELLNESS_CHECK_IN_EVENTS.CLICK_EXIT_BUTTON,
        [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.WELLNESS_CHECK_IN,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      }),
    )
    navigate(`${PAGE_ROUTES.HOMEBASE}`)
  }

  const commonStyle = { zIndex: 1 }

  const navigateToHome = () => {
    dispatch(
      trackEventWithObj({
        event: WELLNESS_CHECK_IN_EVENTS.CLICK_HEADER_LYRA_LOGO,
        [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.WELLNESS_CHECK_IN,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      }),
    )
    navigate('/')
  }

  return (
    <NavBar
      shouldShowCareNavigatorHeaderEntrypoint={false}
      isTransparent
      showLogoMobileSized={!isQuestionnairePage}
      isFixed={!isResultsPage}
      showCloseButton={!isPreviewPage && isResultsPage}
      showBackButton={!isResultsPage}
      onBack={isResultsPage ? undefined : handleBackPress}
      onExit={isResultsPage ? handleExitPress : undefined}
      onLogoPress={isResultsPage ? navigateToHome : undefined}
      style={
        (isResultsPage
          ? { ...commonStyle, position: 'absolute' }
          : {
              ...commonStyle,
              backgroundImage: `url(${
                isMobileSized
                  ? WellnessCheckInBackgroundMobile
                  : isMinWidthLaptop
                  ? WellnessCheckInBackgroundDesktop
                  : WellnessCheckInBackgroundTablet
              })`,
            }) as ViewStyle
      }
      {...(isQuestionnairePage && {
        progressBarProps: {
          progress: pageNumber / (getWellnessCheckInTotalNumPages(socialCareEnabled) + 1),
          backgroundColor: colors.backgroundPrimary,
          color: colors.textActive,
          width: -1,
          height: 6,
        },
      })}
      {...(!isResultsPage && {
        customRightComponent: showDomainInHeader ? (
          <WellnessCheckInDomainHeader
            wellnessCheckInDomain={
              Object.values(WellnessCheckInDomain)[pageNumber - WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET]
            }
          />
        ) : (
          // placeholder view that takes up same amount of space as WellnessCheckInDomainHeader
          // so the header is the same size throughout the Wellness Check In Questionnaire
          <View style={{ width: 40, height: 40 }} />
        ),
      })}
    />
  )
}
