// List of content slugs that can be viewed in preview mode by a user that is not authenticated
export const ALLOWABLE_ESSENTIALS_UNGATED_CONTENT = new Set([
  'when-to-consider-seeking-care-from-a-mental-health-professional',
  'how-to-assess-how-youre-really-doing',
  'sleep-101-how-sleep-works-how-to-sleep-better',
  'stress-101-causes-symptoms-and-coping',
  'coping-with-financial-anxiety',
  'fields',
  'finding-balance-in-the-present',
  'balance-circle',
  'summer-rain',
  'burnout-101-symptoms-and-coping',
  'understanding-and-celebrating-neurodiversity',
  'brown-noise',
  '10-misconceptions-about-mental-illness',
  'create-this-really-beautiful-new-habit',
])

export const isURLPathForEssentialsPreviewContent = (): boolean => {
  const contentSlug = window.location.pathname.split('/').pop()

  if (contentSlug) {
    return ALLOWABLE_ESSENTIALS_UNGATED_CONTENT.has(contentSlug)
  } else {
    return false
  }
}
