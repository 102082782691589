import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import styled, { useTheme } from 'styled-components/native'

import { ChatMenu } from './ChatMenu'
import { ChatMenuItem } from './ChatMenuItem'
import { ChevronIcon, ChevronIconDirection } from '../../atoms'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { Divider } from '../../atoms/divider/Divider'
import { HeaderText } from '../../atoms/headerText/HeaderText'
import { ArrowIcon, ArrowIconDirection } from '../../atoms/icons/ArrowIcon'
import { TextBadge } from '../../atoms/icons/TextBadge'
import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { ThemeType, tID } from '../../utils'

export type ChatHeaderProps = {
  titleMsg: string
  subTitleMsg?: string
  textBadgeMsg?: string
  onClosePress: () => void
  closeStyle?: CloseStyles
  titleCentered?: boolean
  showClose?: boolean
  showChevron?: boolean
  showMenu?: boolean
  markMessagesAsUnread?: () => void
  onChevronPressed?: () => void
  showDivider?: boolean
}

export enum CloseStyles {
  BackArrow = 'BackArrow',
  XIcon = 'XIcon',
}

const ChatHeaderContainer = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['0px'],
}))

const HeaderContainer = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing['0px'],
  padding: `10px ${theme.spacing['0px']}`,
  alignItems: 'center',
}))

const HeaderTextContainer = styled.View<{ centered?: boolean; theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['24px'],
  flexShrink: 1,
  justifyContent: 'center',
}))

const ArrowIconContainer = styled(PressableOpacity)({
  marginTop: '2px',
  marginLeft: '20px',
})

const XIconContainer = styled(PressableOpacity)({
  justifyContent: 'center',
  marginLeft: '20px',
})

const RightContainer = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  paddingRight: theme.spacing['16px'],
  position: 'relative',
}))

const ChatMenuContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingRight: theme.spacing['8px'],
}))

/**
 * This component is the header for the chat screen
 * which consists of the receiver's name, back arrow, timestamp, and badge
 */
export const ChatHeader: FunctionComponent<ChatHeaderProps> = ({
  titleMsg,
  textBadgeMsg,
  subTitleMsg,
  onClosePress,
  closeStyle = CloseStyles.BackArrow,
  titleCentered,
  showClose = true,
  showChevron = false,
  showMenu,
  markMessagesAsUnread,
  onChevronPressed,
  showDivider = true,
}) => {
  const { colors } = useTheme() as ThemeType
  const { textBadge } = colors.components.chatHeader

  return (
    <ChatHeaderContainer testID={tID('ChatHeader')} edges={['top']}>
      <HeaderContainer edges={['left', 'right']}>
        {closeStyle === CloseStyles.BackArrow && showClose && (
          <ArrowIconContainer testID={tID('ChatHeader-back')} onPress={onClosePress}>
            <ArrowIcon
              size={24}
              fillColor={colors.iconDefault}
              direction={ArrowIconDirection.LEFT}
              testID={tID('ChatHeader-backArrowIcon')}
            />
          </ArrowIconContainer>
        )}
        <HeaderTextContainer centered={titleCentered} testID={tID('ChatHeader-headerTextContainer')}>
          <HeaderText text={titleMsg} />
          {!!subTitleMsg && (
            <BodyText
              text={subTitleMsg}
              testID={tID('ChatHeader-subTitle')}
              size={Size.SMALL}
              color={colors.textSecondary}
            />
          )}
        </HeaderTextContainer>
        <RightContainer edges={['right']}>
          {showMenu && (
            <ChatMenuContainer>
              <ChatMenu>
                <ChatMenuItem
                  formattedText={<FormattedMessage defaultMessage='Mark unread' description='Mark unread' />}
                  name='markUnread'
                  onPress={() => {
                    markMessagesAsUnread && markMessagesAsUnread()
                  }}
                  key='markUnread'
                />
              </ChatMenu>
            </ChatMenuContainer>
          )}
          {Boolean(textBadgeMsg) && (
            <TextBadge text={textBadgeMsg ?? ''} color={textBadge.text} backgroundColor={textBadge.background} />
          )}
          {showClose && closeStyle === CloseStyles.XIcon && (
            <XIconContainer testID={tID('ChatHeader-back')} onPress={onClosePress}>
              <XIcon fillColor={colors.iconDefault} />
            </XIconContainer>
          )}
          {showChevron && (
            <XIconContainer testID={tID('ChatHeader-close')} onPress={onChevronPressed ?? onClosePress}>
              <ChevronIcon
                fillColor={colors.iconDefault}
                direction={ChevronIconDirection.UP}
                testID={tID('ChatHeader-chevronIcon')}
              />
            </XIconContainer>
          )}
        </RightContainer>
      </HeaderContainer>
      {showDivider && (
        <View>
          <Divider color={colors.dividerSecondary} />
        </View>
      )}
    </ChatHeaderContainer>
  )
}
