import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  AUTO_ENROLLMENT_TOKEN,
  IDENTITY_PROVIDERS,
  LANG_QUERY_PARAM,
  PATHWAY_QUERY_PARAM,
  REGISTRATION_ENTRY_POINT_KEY,
  REGISTRATION_EVENTS,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { SSO_BUTTON_TYPE } from '@lyrahealth-inc/ui-core-crossplatform/src/atoms/ssoButton/SsoButton'

import { getAppleSSOClientId, getGoogleSSOClientId } from '../../data/appGlobals/appGlobalsSelectors'
import { getCustomerName, getIsAppleSSODisabled, getIsGoogleSSODisabled } from '../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'
import { setSsoIdentityProvider, setSsoToken } from '../../features/register/data/registerActions'
import { SSOIframe } from '../../features/register/setupAccount/SSOIframe'
import { BANNER_TYPE, MESSAGE, setBannerPropsAndStatus } from '../components/banner/banner-actions'
import {
  CLICK_SSO_SIGN_UP_BUTTON,
  GET_APPLE_SIGN_UP_ERROR,
  GET_APPLE_SIGN_UP_TOKEN,
  GET_GOOGLE_SIGN_UP_BUTTON_WIDTH_MESSAGE,
  GET_GOOGLE_SIGN_UP_TOKEN,
  SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE,
} from '../constants/appConstants'

export const useGetSsoIframeContent = (
  selectedPathwayOption?: string,
  entryPointToTrackAfterRegistering?: string,
  onHasLoadedAppleSSO?: () => void,
  onHasLoadedGoogleSSO?: () => void,
  hideLoadingIndicators?: boolean,
  autoEnrollmentToken?: string,
) => {
  const SSO_SIGN_UP_IFRAME_HEIGHT = 70

  const { activeLanguage } = useLyraIntl()
  const isGoogleSSODisabled = useSelector(getIsGoogleSSODisabled)
  const googleSSOClientId = useSelector(getGoogleSSOClientId)
  const isAppleSSODisabled = useSelector(getIsAppleSSODisabled)
  const appleSSOClientId = useSelector(getAppleSSOClientId)
  const customerName = useSelector(getCustomerName)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const appleSSOAvailable = !isAppleSSODisabled && appleSSOClientId
  const googleSSOAvailable = !isGoogleSSODisabled && googleSSOClientId

  const googleIframeRef = useRef<HTMLIFrameElement>(null)
  const googleIframeContainerRef = useRef<HTMLDivElement>(null)
  const appleIframeRef = useRef<HTMLIFrameElement>(null)
  const appleIframeContainerRef = useRef<HTMLDivElement>(null)

  const [hasLoadedAppleSSO, setHasLoadedAppleSSO] = useState(appleSSOAvailable ? false : undefined)
  const [hasLoadedGoogleSSO, setHasLoadedGoogleSSO] = useState(googleSSOAvailable ? false : undefined)
  const [ssoIframeHeight, setSsoIframeHeight] = useState(0)
  const [isSSOLoading, setIsSSOLoading] = useState(true)

  const careOrigin = window.location.origin.replace(customerName + '.', 'care.')

  useEffect(() => {
    window.onmessage = (event) => {
      const googleIframe = googleIframeRef.current
      const googleIframeContainer = googleIframeContainerRef.current

      const sendGoogleButtonContainerWidthMsg = () => {
        if (googleIframe?.contentWindow && googleIframeContainer) {
          const width = googleIframeContainer.offsetWidth
          googleIframe.contentWindow?.postMessage({ message: SEND_SIGN_UP_BUTTON_WIDTH_MESSAGE, width }, careOrigin)
        }
      }

      if (event.origin === careOrigin) {
        // Handle Google iframe sizing (communicates with iframe via window.onmessage since we use Google JS API to render button)
        if (event.data === GET_GOOGLE_SIGN_UP_BUTTON_WIDTH_MESSAGE) {
          sendGoogleButtonContainerWidthMsg()
          const resizeObserver = new ResizeObserver(sendGoogleButtonContainerWidthMsg)
          googleIframeContainer && resizeObserver.observe(googleIframeContainer)
        }

        // Handle error on Apple button click
        if (event.data === GET_APPLE_SIGN_UP_ERROR) {
          dispatch(
            setBannerPropsAndStatus({
              bannerType: BANNER_TYPE.DANGER,
              message: MESSAGE.EMAIL_REGISTRATION_METHOD_FAILED,
              shouldAutomaticallyClose: true,
            }),
          )
        }

        // Handle mixpanel tracking for respective sso button click(s)
        if (event?.data?.message === CLICK_SSO_SIGN_UP_BUTTON) {
          if (event.data?.identityProvider === IDENTITY_PROVIDERS.APPLE) {
            dispatch(trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_SIGN_UP_WITH_APPLE }))
          } else if (event.data?.identityProvider === IDENTITY_PROVIDERS.GOOGLE) {
            dispatch(trackEventWithObj({ event: REGISTRATION_EVENTS.CLICK_SIGN_UP_WITH_GOOGLE }))
          }
        }

        // Handle storing google/apple sdk response data in redux and redirecting
        if (
          event.data &&
          (event.data?.message === GET_APPLE_SIGN_UP_TOKEN || event.data?.message === GET_GOOGLE_SIGN_UP_TOKEN)
        ) {
          if (event.data.message === GET_APPLE_SIGN_UP_TOKEN) {
            dispatch(setSsoIdentityProvider(IDENTITY_PROVIDERS.APPLE))
          } else if (event.data.message === GET_GOOGLE_SIGN_UP_TOKEN) {
            dispatch(setSsoIdentityProvider(IDENTITY_PROVIDERS.GOOGLE))
          }

          event.data?.token && dispatch(setSsoToken(event.data.token))
          event.data?.redirectUrl && navigate(event.data.redirectUrl)
        }
      }
    }
  }, [careOrigin, dispatch])

  useEffect(() => {
    if (hasLoadedAppleSSO || hasLoadedGoogleSSO) {
      isSSOLoading && setIsSSOLoading(false)
      setSsoIframeHeight(SSO_SIGN_UP_IFRAME_HEIGHT)
    }
  }, [hasLoadedAppleSSO, hasLoadedGoogleSSO, isSSOLoading, setIsSSOLoading])

  const getRegistrationIframeURL = (ssoType: IDENTITY_PROVIDERS.APPLE | IDENTITY_PROVIDERS.GOOGLE) => {
    const ssoRoutes: Record<IDENTITY_PROVIDERS.APPLE | IDENTITY_PROVIDERS.GOOGLE, string> = {
      [IDENTITY_PROVIDERS.APPLE]: PAGE_ROUTES.SSO_REGISTRATION_APPLE,
      [IDENTITY_PROVIDERS.GOOGLE]: PAGE_ROUTES.SSO_REGISTRATION_GOOGLE,
    }
    const iframeUrl = new URL(ssoRoutes[ssoType], careOrigin)

    iframeUrl.searchParams.set(LANG_QUERY_PARAM, activeLanguage)

    if (selectedPathwayOption) {
      iframeUrl.searchParams.set(PATHWAY_QUERY_PARAM, selectedPathwayOption)
    }

    if (entryPointToTrackAfterRegistering) {
      iframeUrl.searchParams.set(REGISTRATION_ENTRY_POINT_KEY, entryPointToTrackAfterRegistering)
    }

    if (autoEnrollmentToken) {
      iframeUrl.searchParams.set(AUTO_ENROLLMENT_TOKEN, autoEnrollmentToken)
    }

    const iframeUrlString = iframeUrl.toString()

    return iframeUrlString
  }

  const onGoogleSSOLoadCallback = useCallback(() => {
    setHasLoadedGoogleSSO(true)
    onHasLoadedGoogleSSO && onHasLoadedGoogleSSO()
  }, [setHasLoadedGoogleSSO, onHasLoadedGoogleSSO])

  const onAppleSSOLoadCallback = useCallback(() => {
    setHasLoadedAppleSSO(true)
    onHasLoadedAppleSSO && onHasLoadedAppleSSO()
  }, [setHasLoadedAppleSSO, onHasLoadedAppleSSO])

  const renderGoogleSSOIframe = () => {
    if (!googleSSOAvailable) return undefined

    return (
      <SSOIframe
        iframeRef={googleIframeRef}
        registrationIframeURL={getRegistrationIframeURL(IDENTITY_PROVIDERS.GOOGLE)}
        onLoadCallback={onGoogleSSOLoadCallback}
        iFrameHeight={ssoIframeHeight}
        // This part is so the height of the SSO button section stays constant when loading
        loadingContainerHeight={
          googleSSOAvailable && appleSSOAvailable ? SSO_SIGN_UP_IFRAME_HEIGHT * 2 : SSO_SIGN_UP_IFRAME_HEIGHT
        }
        iframeContainerRef={googleIframeContainerRef}
        isSSOLoading={hideLoadingIndicators ? false : isSSOLoading}
        type={SSO_BUTTON_TYPE.GOOGLE}
      />
    )
  }

  const renderAppleSSOIframe = () => {
    if (!appleSSOAvailable) return undefined

    return (
      <SSOIframe
        iframeRef={appleIframeRef}
        registrationIframeURL={getRegistrationIframeURL(IDENTITY_PROVIDERS.APPLE)}
        onLoadCallback={onAppleSSOLoadCallback}
        iFrameHeight={ssoIframeHeight}
        iframeContainerRef={appleIframeContainerRef}
        // This part is so we only show one loading spinner when both google and apple SSO is available
        isSSOLoading={hideLoadingIndicators ? false : googleSSOAvailable ? false : isSSOLoading}
        loadingContainerHeight={googleSSOAvailable ? 0 : SSO_SIGN_UP_IFRAME_HEIGHT}
        padding={googleSSOAvailable ? 20 : 0}
        type={SSO_BUTTON_TYPE.APPLE}
      />
    )
  }

  return { renderGoogleSSOIframe: renderGoogleSSOIframe, renderAppleSSOIframe: renderAppleSSOIframe }
}
