import React, { FunctionComponent, ReactNode, Ref, useEffect, useState } from 'react'
import {
  type ModalBaseProps,
  Pressable,
  Modal as ReactNativeModal,
  TouchableOpacity,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native'
import Animated, { interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import { getDocumentObject } from '@lyrahealth-inc/shared-app-logic'

import { XIcon } from '../../atoms/icons/XIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB } from '../../constants'
import { useMediaQuerySize } from '../../hooks/useMediaQuerySize'
import { BottomSheet } from '../../molecules/bottomSheet/BottomSheet'
import { Flex1View } from '../../templates/content/CommonViews'
import { ThemeType, tID } from '../../utils'
import { isChromaticWeb } from '../../utils/utils'

export interface ModalProps {
  modalContents: ReactNode | ReactNode[]
  modalTitle?: ReactNode
  onRequestClose: () => void
  visible: boolean
  bottomSheetRef?: Ref<any>
  snapPoints?: number[]
  width?: string
  scrollableModalWidth?: string
  scrollableModalHeight?: string
  /** Called on close of the bottom sheet */
  onCloseEnd?: () => void
  onLayout?: () => void
  // disables bottomsheet for smaller screens if content is long
  disableBottomSheet?: boolean
  scrollable?: boolean
  backgroundColor?: string
  isHeaderTransparent?: boolean
  /** Set bottom sheet height based on the modal contents. Overrides `snapPoints` */
  useDynamicHeightForBottomSheet?: boolean
  closeIconColor?: string
  mobileContentCentered?: boolean
  withBottomSheetPortal?: boolean
  // when combined with FullScreenOverlay, the modal adds another scroll bar. disable if using alongside FullScreenOverlay
  allowScrollBarOnFullscreen?: boolean
  // This styles the modal to open up a side panel instead.
  isSidePanel?: boolean
  customSidePanelStyle?: CSSObject
  closeOnScrim?: boolean
  useWebPortalForBottomSheet?: boolean
  style?: ViewStyle
  showCloseIconInBottomSheet?: boolean
  indexOverride?: number
  customBottomSheetHeight?: number
  showHandle?: boolean
  animationType?: ModalBaseProps['animationType']
  centered?: boolean
  showDelay?: number
  hideDelay?: number
  showCloseIcon?: boolean
  scrollableModalMaxHeight?: number | string
  disableClose?: boolean // modal cannot be closed
  enableTopOffset?: boolean // adds top padding to prevent content from being covered by header for mobile-sized fullscreen modal
  scrollableStyle?: CSSObject
  bottomSheetContentPanningGestureEnabled?: boolean // enables bounce effect for bottom sheet
  removeBottomSheetBorderRadius?: boolean
  disableFullScreenModal?: boolean
  customHeader?: ReactNode
}

const ModalContainer = styled(Pressable)<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  alignItems: 'center',
  backgroundColor: colors.components.modal.background,
  justifyContent: 'flex-start',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  overflow: 'auto',
  ...(IS_WEB && { cursor: 'default' }),
}))

const ModalCard = styled.View<{ marginTop: number; theme: ThemeType; width?: string }>(
  ({ marginTop, theme: { colors, spacing }, width }) => ({
    backgroundColor: colors.backgroundPrimary,
    borderRadius: spacing['16px'],
    marginTop,
    padding: spacing['24px'],
    shadowColor: colors.components.modal.modalCardShadow.background,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: '15px',
    width,
    ...(IS_WEB && { cursor: 'default' }),
  }),
)

const ScrollableModalCard = styled.ScrollView<{
  backgroundColor: string
  scrollableModalWidth: string | undefined
  scrollableModalHeight: string | undefined
  maxHeight: number | string
  theme: ThemeType
  scrollableStyle?: CSSObject
}>(({ theme, scrollableModalWidth, scrollableModalHeight, maxHeight, backgroundColor, scrollableStyle }) => ({
  backgroundColor,
  padding: `${theme.spacing['24px']} ${theme.spacing['48px']}`,
  width: scrollableModalWidth ?? '600px',
  height: scrollableModalHeight ?? '800px',
  maxHeight,
  shadowColor: theme.colors.components.modal.scrollableModalShadow.background,
  shadowOffset: {
    width: '0',
    height: '2',
  },
  shadowOpacity: '0.25',
  shadowRadius: '4',
  elevation: '5',
  borderRadius: '10px',
  ...(IS_WEB && { cursor: 'default' }),
  ...scrollableStyle,
}))

const ScrollableModalContainer = styled(Pressable)({
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(74,74,74,.25)',
  justifyContent: 'center',
  alignItems: 'center',
  ...(IS_WEB && { cursor: 'default' }),
})

const FullScreenModalContentContainer = styled.View<{ backgroundColor: string; headerOffset?: number }>(
  ({ backgroundColor, headerOffset }) => ({
    backgroundColor,
    ...(headerOffset && { paddingTop: `${headerOffset}px` }),
  }),
)

const CenteredContainer = styled(Flex1View)<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
  justifyContent: 'center',
}))

const ScrollableContainer = styled.ScrollView<{ backgroundColor: string }>(({ backgroundColor }) => ({
  backgroundColor,
}))

const SidePanelOuterContainer = styled(Animated.View)({
  position: 'relative',
  width: '100vw' /* stylelint-disable-line -- TODO: Fix this, 'vw' is not valid in react native */,
  height: '100vh' /* stylelint-disable-line -- TODO: Fix this, 'vh' is not valid in react native */,
})

const SidePanelBackground = styled(TouchableOpacity)({
  flex: 'auto',
  ...(IS_WEB && { cursor: 'default' }),
})

const SidePanelInnerContainer = styled(Animated.View)<{
  theme: ThemeType
  backgroundColor?: string
  customSidePanelStyle?: CSSObject
}>(({ theme, backgroundColor, customSidePanelStyle }) => ({
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  backgroundColor: backgroundColor ?? theme.colors.backgroundPrimary,
  position: 'absolute',
  right: 0,
  height: '100%',
  paddingTop: theme.spacing['32px'],
  borderTopLeftRadius: theme.spacing['16px'],
  borderBottomLeftRadius: theme.spacing['16px'],
  boxShadow: `0px 2px 10px ${theme.colors.components.modal.sidePanelShadow.background}`,
  maxWidth: '452px',
  ...customSidePanelStyle,
}))

const Header = styled.View<{
  backgroundColor: string
  isHeaderTransparent: boolean
  hasTitle: boolean
  theme: ThemeType
}>(({ theme, backgroundColor, isHeaderTransparent, hasTitle }) => ({
  flexDirection: 'row',
  justifyContent: hasTitle ? 'space-between' : 'flex-end',
  backgroundColor: isHeaderTransparent ? 'transparent' : backgroundColor,
  position: 'absolute',
  width: '95%',
  height: theme.spacing['48px'],
  alignItems: 'flex-end',
  zIndex: 1,
  borderRadius: '10px',
  paddingBottom: theme.spacing['16px'],
  paddingTop: theme.spacing['48px'],
  ...(theme.breakpoints.isMobileSized && { paddingTop: theme.spacing['0px'], height: '10%' }),
  ...(IS_WEB && { cursor: 'default' }),
}))

export const Modal: FunctionComponent<ModalProps> = ({
  modalContents,
  modalTitle,
  bottomSheetRef = null,
  visible,
  onRequestClose,
  snapPoints = [],
  width = '544px',
  scrollableModalWidth,
  scrollableModalHeight,
  onCloseEnd = noop,
  onLayout = noop,
  disableBottomSheet,
  scrollable = false,
  backgroundColor,
  isHeaderTransparent = false,
  useDynamicHeightForBottomSheet = false,
  closeIconColor,
  mobileContentCentered = false,
  withBottomSheetPortal,
  allowScrollBarOnFullscreen = true,
  isSidePanel = false,
  closeOnScrim = false,
  useWebPortalForBottomSheet = false,
  style,
  showCloseIcon = true,
  showCloseIconInBottomSheet = false,
  indexOverride,
  customBottomSheetHeight,
  showHandle,
  animationType,
  showDelay = 0,
  hideDelay = 0,
  centered = false,
  scrollableModalMaxHeight,
  disableClose = false,
  enableTopOffset = false,
  scrollableStyle,
  customSidePanelStyle,
  bottomSheetContentPanningGestureEnabled = true,
  removeBottomSheetBorderRadius,
  disableFullScreenModal = false,
  customHeader,
}) => {
  const { isMinWidthTablet } = useMediaQuerySize()
  const { height: screenHeight } = useWindowDimensions()
  const { colors } = useTheme() as ThemeType
  const { animationShadow } = colors.components.modal

  const modalCardMaxMarginTop = 80
  const [modalCardMarginTop, setModalCardMarginTop] = useState(modalCardMaxMarginTop)
  const [modalCardHeight, setModalCardHeight] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [shouldShow, setShouldShow] = useState(false)

  useEffect(() => {
    const delay = visible ? showDelay : hideDelay

    const delayVisibility = setTimeout(() => {
      setShouldShow(visible)
    }, delay)

    return () => clearTimeout(delayVisibility)
  }, [showDelay, hideDelay, shouldShow, visible])

  useEffect(() => {
    if (centered || modalCardHeight + modalCardMaxMarginTop >= screenHeight) {
      setModalCardMarginTop(Math.max(0, (screenHeight - modalCardHeight) / 2))
    } else {
      setModalCardMarginTop(modalCardMaxMarginTop)
    }
  }, [centered, modalCardHeight, screenHeight])

  useEffect(() => {
    const documentObject = getDocumentObject()
    if (IS_WEB && documentObject && allowScrollBarOnFullscreen) {
      if (shouldShow) {
        documentObject.body.style.overflow = 'hidden'
      } else {
        documentObject.body.style.overflow = 'auto'
      }
    }
    return () => {
      if (IS_WEB && documentObject && allowScrollBarOnFullscreen) {
        documentObject.body.style.overflow = 'auto'
      }
    }
  }, [allowScrollBarOnFullscreen, shouldShow])

  const handleOnLayout = () => {
    onLayout()
  }

  // Define a shared value to control the side panel's translateX position and the containers background color
  const panelAnimationValue = useSharedValue(isSidePanel ? (shouldShow ? 0 : 1000) : 0)
  const backgroundColorAnimationValue = useSharedValue(isSidePanel ? (shouldShow ? 0 : 1) : 0)

  useEffect(() => {
    if (isSidePanel) {
      panelAnimationValue.value = withTiming(shouldShow ? 0 : 1000)
      backgroundColorAnimationValue.value = withTiming(shouldShow ? 0 : 1)
    }
  }, [backgroundColorAnimationValue, panelAnimationValue, isSidePanel, shouldShow])

  const animatedBackgroundColorStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      backgroundColorAnimationValue.value,
      [0, 1],
      [animationShadow.background, colors.backgroundTransparent],
    )

    return {
      backgroundColor,
    }
  })

  const animatedSidePanelStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: panelAnimationValue.value }],
  }))

  const onScrim = closeOnScrim ? onRequestClose : undefined
  const defaultAnimationType = !isMinWidthTablet ? 'slide' : 'fade'

  const renderPopUpModal = () => {
    return scrollable ? (
      <ScrollableModalContainer onPressOut={onScrim} focusable={false}>
        <Pressable focusable={false}>
          <View>
            {customHeader ? (
              customHeader
            ) : (
              <Header
                testID={tID('Modal-XIcon')}
                backgroundColor={backgroundColor || colors.backgroundPrimary}
                isHeaderTransparent={isHeaderTransparent}
                hasTitle={Boolean(modalTitle)}
              >
                {modalTitle}
                {showCloseIcon && (
                  <PressableOpacity onPress={onRequestClose}>
                    <XIcon size={24} fillColor={closeIconColor || colors.iconDefault} />
                  </PressableOpacity>
                )}
              </Header>
            )}
            <ScrollableModalCard
              scrollableModalWidth={scrollableModalWidth}
              scrollableModalHeight={scrollableModalHeight}
              backgroundColor={backgroundColor || colors.backgroundPrimary}
              maxHeight={scrollableModalMaxHeight || screenHeight}
              scrollableStyle={scrollableStyle}
            >
              <ScrollableContainer backgroundColor={backgroundColor || colors.backgroundPrimary}>
                {modalContents}
              </ScrollableContainer>
            </ScrollableModalCard>
          </View>
        </Pressable>
      </ScrollableModalContainer>
    ) : (
      <ModalContainer onPressOut={onScrim} focusable={false}>
        <Pressable focusable={false}>
          <ModalCard
            width={width}
            style={style}
            marginTop={modalCardMarginTop}
            onLayout={(e) => {
              setModalCardHeight(e.nativeEvent.layout.height)
            }}
          >
            {modalContents}
          </ModalCard>
        </Pressable>
      </ModalContainer>
    )
  }

  const fullScreenModalBgColor = backgroundColor || colors.backgroundPrimary

  // Setting a dynamic key for FullScreenModalContentContainer when enableTopOffset is true so that modal is forced to re-render
  // when headerHeight is updated. Without this Android does not include the top padding on the container when the modal
  // is first opened, seems that react native's rendering pipeline for android is a bit different than for IOS and web.
  const fullscreenModalContainerKey = enableTopOffset
    ? `fullscreenModalContentContainer-paddingTop${headerHeight}`
    : 'fullscreenModalContentContainer'

  const fullScreenModal = (
    <>
      {customHeader ? (
        customHeader
      ) : (
        <Header
          backgroundColor={fullScreenModalBgColor}
          testID={tID('Modal-XIcon')}
          isHeaderTransparent={isHeaderTransparent}
          hasTitle={Boolean(modalTitle)}
          onLayout={(e) => {
            enableTopOffset && setHeaderHeight(e.nativeEvent.layout.height)
          }}
        >
          {modalTitle}
          {showCloseIcon && (
            <PressableOpacity onPress={onRequestClose}>
              <XIcon size={24} fillColor={closeIconColor} />
            </PressableOpacity>
          )}
        </Header>
      )}

      {mobileContentCentered ? (
        <CenteredContainer backgroundColor={fullScreenModalBgColor}>
          <FullScreenModalContentContainer
            backgroundColor={fullScreenModalBgColor}
            headerOffset={enableTopOffset ? headerHeight : undefined}
            key={fullscreenModalContainerKey}
          >
            {modalContents}
          </FullScreenModalContentContainer>
        </CenteredContainer>
      ) : (
        <ScrollableContainer backgroundColor={fullScreenModalBgColor}>
          <FullScreenModalContentContainer
            backgroundColor={fullScreenModalBgColor}
            headerOffset={enableTopOffset ? headerHeight : undefined}
            key={fullscreenModalContainerKey}
          >
            {modalContents}
          </FullScreenModalContentContainer>
        </ScrollableContainer>
      )}
    </>
  )

  if (isSidePanel) {
    return (
      <ReactNativeModal animationType={'none'} onRequestClose={onRequestClose} transparent visible={shouldShow}>
        <SidePanelOuterContainer style={animatedBackgroundColorStyle}>
          <SidePanelBackground onPress={onRequestClose} />
          <SidePanelInnerContainer
            style={animatedSidePanelStyle}
            backgroundColor={backgroundColor}
            customSidePanelStyle={customSidePanelStyle}
          >
            {modalContents}
          </SidePanelInnerContainer>
        </SidePanelOuterContainer>
      </ReactNativeModal>
    )
  }
  return isMinWidthTablet || disableBottomSheet ? (
    <ReactNativeModal
      animationType={isChromaticWeb() ? 'none' : animationType || defaultAnimationType}
      onRequestClose={onRequestClose}
      transparent
      visible={shouldShow}
    >
      {isMinWidthTablet || disableFullScreenModal ? renderPopUpModal() : fullScreenModal}
    </ReactNativeModal>
  ) : shouldShow ? (
    <BottomSheet
      ref={bottomSheetRef}
      snapPoints={snapPoints}
      onCloseEnd={onCloseEnd}
      withPortal={withBottomSheetPortal}
      useWebPortal={useWebPortalForBottomSheet}
      index={indexOverride ?? 0}
      useDynamicHeight={useDynamicHeightForBottomSheet}
      enableScroll={scrollable}
      showCloseIcon={showCloseIconInBottomSheet}
      customBottomSheetHeight={customBottomSheetHeight}
      showHandle={showHandle}
      disableClose={disableClose}
      backgroundColor={backgroundColor}
      enableContentPanningGesture={bottomSheetContentPanningGestureEnabled}
      removeBorderRadius={removeBottomSheetBorderRadius}
    >
      <View onLayout={handleOnLayout}>{modalContents}</View>
    </BottomSheet>
  ) : null
}
