// @intl project:microsite

import React, { MouseEvent, useEffect, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Pressable, View } from 'react-native'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import classnames from 'classnames'
import { fromJS, List } from 'immutable'
import { isEmpty } from 'lodash-es'
import { useTheme } from 'styled-components/native'

import {
  COMMON_ENTRY_POINTS,
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  COMMON_REGISTRATION_SCREENS,
  DirectLinkSource,
  INTEGRATED_BENEFITS_EVENTS,
  LOGIN_EVENTS,
  MIXPANEL_EVENT_ATTRIBUTES,
  PATHWAYS_EVENTS,
  REFERRAL_ENTRY_POINTS,
  shouldLinkToWorkHub,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import { useIsMobile } from '@lyrahealth-inc/ui-core'
import {
  Header as AppHeader,
  HeaderNavMenu,
  LyraLogo,
  ReferButton,
  tID,
  toJS,
  useStorageWithExpiration,
} from '@lyrahealth-inc/ui-core-crossplatform'
import { HeaderNavMenuProps } from '@lyrahealth-inc/ui-core-crossplatform/src/organisms/headerNavMenu/HeaderNavMenu'
import { benefitsHeaderText } from '@lyrahealth-inc/ui-core-crossplatform/src/pages/integratedBenefitsPage/IntegratedBenefitsPage'

import AccountSetupHeader from './AccountSetupHeader'
import DefaultHeader from './DefaultHeader'
import styles from './header.module.scss'
import MobileHeader from './MobileHeader'
import NavBarHeader from './NavBarHeader'
import OnboardingNavBar from './OnboardingNavBar'
import { setToastContent } from '../../../data/appGlobals/appGlobalsActions'
import {
  getBeforeLaunch,
  getCustomerName,
  getCustomerWorkLifeBenefits,
  getEssentialsDisabled,
  getHealthPlanExtensionEnabled,
  getHideExtraHeaderOptions,
  getIsWorkhubDisabled,
} from '../../../data/customer/customerSelectors'
import { resetHealthPlan, resetTriage } from '../../../data/logout/logoutActions'
import * as MixpanelActions from '../../../data/mixpanel'
import { MIXPANEL_EVENTS, trackEventWithObj } from '../../../data/mixpanel'
import mixpanel from '../../../data/mixpanel/mixpanel-browser'
import { convertRouteToMixpanelPage } from '../../../data/mixpanel/utils'
import {
  getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer,
  getIsUserInternational,
  getShouldShowAccountBadge,
} from '../../../data/user/userSelectors'
import { clearWellnessCheckInForm } from '../../../data/wellnessCheckIn/wellnessCheckInActions'
import { isURLPathForEssentialsPreviewContent } from '../../../features/essentials/essentialsUtils'
import { getHealthPlanPaymentCardLast4 } from '../../../features/healthPlan/data/healthPlanSelectors'
import LanguageSelector from '../../../features/intl/LanguageSelector'
import { isInLoginFlow } from '../../../features/login/loginUtils'
import MessagesPopover from '../../../features/lyraTherapy/messagesPopover/MessagesPopover'
import { getOnboardDirectLinkSource } from '../../../features/onboard/data/onboardSelectors'
import {
  HPI_ROUTES,
  PAGE_ROUTES,
  PROFILE_CONFIRMATION_PAGES,
  PROFILE_EDIT_PAGES,
} from '../../../features/onboard/data/page-navigation/location-actions'
import { isInRegisterFlow, isPathInAutoEnrollmentFlow } from '../../../features/register/data/registerUtils'
import { LOCAL_STORAGE_KEY } from '../../constants/appConstants'
import {
  PARENT_LED_TEEN_WELCOME_PAGE_PATH,
  REQUIRE_ELIGIBILITY_FORM_PATH,
} from '../../constants/registrationFormConstants'
import { withTrackIntegratedBenefits } from '../../hocs/withTrackIntegratedBenefits'
import { useCareNavigatorModal } from '../../hooks/useCareNavigatorModal'
import { useDefaultMixpanelPage } from '../../hooks/useDefaultMixpanelPage'
import { rerouteFromMicrosite } from '../../utils/micrositeUtils'
import { updateStoredProviderResultsSurveyValues } from '../../utils/pendoUtils'
import { shareReferral } from '../../utils/referralUtils'
import { matchPaths } from '../../utils/stringUtils'
import { hasRole, toggleIsSessionTimerDisabled } from '../../utils/userUtils'

export const Header = ({
  user,
  videoSessionOpen,
  isLyraTherapy,
  carepage,
  opaque,
  appointmentSize,
  essentialsDisabled,
  isWorkhubDisabled,
  beforeLaunch,
  isUserInternational,
  resetHealthPlan,
  resetTriage,
  isClientHPI,
  trackEventWithObj,
  customerName,
  isUserAssumedInternational,
  trackIntegratedBenefits,
  setToastContent,
  shouldShowAccountBadge,
  currentDirectPathLinkSource,
  clearWellnessCheckInForm,
}: $TSFixMe) => {
  const intl = useIntl()
  const {
    breakpoints: { isMobileSized, isMinWidthLaptop },
    colors,
  } = useTheme()
  const {
    isLanguageSelectorEnabled,
    isDisplayLanguageEnabled,
    shouldShowCareNavigatorHeaderEntrypoint,
    shouldShowCareTabInitial,
    isRegistrationAndLoginDisabled,
    adultTriageDisabled,
    isGPSv1Enabled,
  } = useFlags()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const entryPoint = queryParams.get('entryPoint')
  const showReferral = !adultTriageDisabled

  const isLoggedIn = !isEmpty(user)
  const pageName = useMemo(() => convertRouteToMixpanelPage(pathname), [pathname])
  const pressableRef = useRef<View>(null)

  const isFindEmployerPage = carepage && pathname === '/'

  const isRegistrationOnboardingPage = pathname.includes(PAGE_ROUTES.REGISTRATION_ONBOARDING)
  const mixpanelPage = useDefaultMixpanelPage()
  const [isMobile] = useIsMobile(Number(styles.x_tablet_min_width))

  const { careNavigatorModal, careNavigatorEntryPoint } = useCareNavigatorModal({
    clickEntryPoint: COMMON_MIXPANEL_PAGE.GLOBAL_NAV_CARE_NAVIGATOR,
  })

  const isInternational = isLoggedIn ? isUserInternational : isUserAssumedInternational
  const isViewingEssentialsUngatedContent =
    window.location.pathname.startsWith(`${PAGE_ROUTES.ESSENTIALS_UNGATED_CONTENT}`) &&
    isURLPathForEssentialsPreviewContent()

  useEffect(() => {
    if (search.includes('utm_medium=tbd')) {
      shareReferral({
        source: 'welcome-email',
        formatMessage: intl.formatMessage,
        setToastContent,
        trackEventWithObj,
        isMobileSized,
        entryPoint: REFERRAL_ENTRY_POINTS.WELCOME_EMAIL,
      })
    }
  }, [customerName, intl.formatMessage, isMobileSized, search, setToastContent, trackEventWithObj])

  // Disable account badge animation after the first visit for some time.
  // The badge state should be specific to each user account, but this uses LocalStorage which is shared across users.
  // This is not ideal, but animation behavior is not critical and we don't have a way to store this per-user.
  const [animateAccountBadge, setAnimateAccountBadge] = useStorageWithExpiration(
    LOCAL_STORAGE_KEY.ANIMATE_MENU_BADGE,
    true,
    20 * 60 * 1000 /* 20 minutes */,
  )

  if (carepage && pathname !== '/' && !isLoggedIn) {
    return null
  }

  if (videoSessionOpen) {
    return null
  }

  if (pathname.includes('satisfaction') || pathname.includes('verify-attestation')) {
    return null
  }

  // Use container headers for health plan and payment forms
  if (
    HPI_ROUTES.some((hpiRoute) => pathname.includes(hpiRoute) && pathname.includes(PAGE_ROUTES.PROFILE.COVERAGE_CHECK))
  ) {
    return null
  }

  if (matchPaths(location.pathname, PROFILE_EDIT_PAGES) || matchPaths(location.pathname, PROFILE_CONFIRMATION_PAGES)) {
    return null
  }

  if (pathname.includes(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)) {
    return null
  }

  if (isMobile && isViewingEssentialsUngatedContent) {
    return null
  }

  if (
    matchPaths(location.pathname, [
      '/secure/onboard/about',
      '/secure/onboard/about/:id',
      '/secure/onboard/treatment-options',
      '/secure/onboard/match-location',
    ])
  ) {
    // Triage, Treatment recs, and registration onboarding have custom navigation actions, so the navbar is included in the respective containers.
    return null
  } else if (
    pathname.includes('/secure/onboard/') ||
    pathname.includes('/secure/coverage-check/onboard') ||
    pathname.includes('/secure/coverage-check/option') ||
    pathname.includes('/secure/coverage-check/provider') ||
    matchPaths(location.pathname, ['/secure/wellness/toolkit/:id'])
  ) {
    const hideBackButton =
      matchPaths(location.pathname, ['/secure/wellness/toolkit/:id']) &&
      entryPoint === COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_BACKGROUND_INFO
    return <OnboardingNavBar showBackButton={!hideBackButton} />
  }

  // Hide the header for when user is within assignment subpages
  if (!isMinWidthLaptop && pathname.includes('assignments/')) {
    return null
  }

  const handleLogin = () => {
    trackEventWithObj({
      event: LOGIN_EVENTS.CLICK_LOGIN,
      ...mixpanelPage,
    })
    navigate(PAGE_ROUTES.LOGIN)
  }

  const handleLogout = () => {
    trackEventWithObj({
      event: LOGIN_EVENTS.CLICK_LOG_OUT,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: COMMON_ENTRY_POINTS.HAMBURGER_MENU,
    })
    mixpanel.reset()
    resetHealthPlan()
    resetTriage()
    clearWellnessCheckInForm()
    toggleIsSessionTimerDisabled(false)
    pendo?.clearSession?.()
    updateStoredProviderResultsSurveyValues()
    window.location.href = '/logout'
  }

  const handleProfile = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_ACCOUNT,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: COMMON_ENTRY_POINTS.HAMBURGER_MENU,
      [COMMON_MIXPANEL_PROPERTIES.NOTIFICATION_BADGE_SHOWN]: shouldShowAccountBadge,
    })
    navigate(PAGE_ROUTES.PROFILE.DEFAULT, {
      state: { mixpanelEntryPoint: MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.NAV_BAR },
    })
  }

  const handleBenefitsClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_MY_BENEFITS,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: COMMON_ENTRY_POINTS.HAMBURGER_MENU,
    })
    trackIntegratedBenefits({
      event: INTEGRATED_BENEFITS_EVENTS.VIEW_INTEGRATED_BENEFITS_PAGE,
      entryPoint: COMMON_MIXPANEL_PAGE.HOME_BASE,
      action: MixpanelActions.MIXPANEL_ACTION.MENU,
    })
    navigate('/secure/index/search-care/benefits')
  }

  const handleMenuBtnClick = () => {
    setAnimateAccountBadge(false)
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_HAMBURGER_MENU,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: pageName,
      [COMMON_MIXPANEL_PROPERTIES.NOTIFICATION_BADGE_SHOWN]: shouldShowAccountBadge,
    })
  }

  const handleGetCareClick = () => {
    rerouteFromMicrosite({ user, navigate })
  }

  const handleSignUpClick = () => {
    rerouteFromMicrosite({
      user,
      navigate,
      trackEventWithObj,
      mixpanelEvent: PATHWAYS_EVENTS.CLICK_CREATE_ACCOUNT,
      mixpanelPage: mixpanelPage?.page,
    })
  }

  const handleFaqClick = (
    e?: React.MouseEvent<HTMLButtonElement | HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e?.preventDefault()
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_FAQS,
      [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: COMMON_ENTRY_POINTS.HAMBURGER_MENU,
    })
    if (isLoggedIn) {
      navigate('/faq')
    } else {
      navigate('/#faq')
    }
  }

  const handleReferralClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()

    shareReferral({
      source: 'hamburger-menu',
      formatMessage: intl.formatMessage,
      setToastContent,
      trackEventWithObj,
      isMobileSized,
      entryPoint: REFERRAL_ENTRY_POINTS.HAMBURGER_MENU,
    })
  }

  const handleHomeClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    navigate('/secure/index/search-care')
  }

  const handleCareClick = () => {
    navigate('/secure/index/assignments', {
      state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
    })
  }

  const handleCareEmptyStateClick = () => {
    navigate(PAGE_ROUTES.CARE_INITIAL)
  }

  const handleSessionsClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    navigate('/secure/index/sessions')
  }

  const handleProgressClick = () => {
    navigate('/secure/index/progress')
  }

  const handleEssentialsClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    shouldLinkToWorkHub(essentialsDisabled === true, isWorkhubDisabled)
      ? navigate(
          `/secure/index/work-hub?${COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT}=${MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.LIBRARY}`,
          {
            state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
          },
        )
      : navigate(
          `/secure/index/essentials?${COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT}=${MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.LIBRARY}`,
          {
            state: { [COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE]: pageName },
          },
        )
  }

  const shouldShowEssentialsLT = () => !essentialsDisabled || !isWorkhubDisabled

  const shouldShowNotInCareTabs = isLoggedIn && !carepage && !isLyraTherapy && !isRegistrationOnboardingPage

  const shouldShowEssentials = () => shouldShowNotInCareTabs && shouldShowEssentialsLT()

  const shouldShowHome = () => shouldShowNotInCareTabs

  const shouldShowSessions = () => shouldShowNotInCareTabs && appointmentSize > 0

  const shouldShowCare = () => shouldShowNotInCareTabs && shouldShowCareTabInitial

  if (pathname === REQUIRE_ELIGIBILITY_FORM_PATH || pathname === PARENT_LED_TEEN_WELCOME_PAGE_PATH) {
    return (
      <NavBarHeader
        style={{ backgroundColor: colors.backgroundSecondary }}
        onExit={pathname === REQUIRE_ELIGIBILITY_FORM_PATH ? handleLogout : () => navigate(PAGE_ROUTES.SECURE_INDEX)}
        borderBottomColor={pathname === PARENT_LED_TEEN_WELCOME_PAGE_PATH ? 'transparent' : undefined}
        shouldShowCareNavigatorHeaderEntrypoint
        isInternational={isInternational}
        careNavigatorModal={careNavigatorModal}
        careNavigatorEntryPoint={careNavigatorEntryPoint}
      />
    )
  }

  if (
    pathname.includes(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE) ||
    pathname.includes(PAGE_ROUTES.PATHWAYS.SELF_CARE) ||
    pathname.includes(PAGE_ROUTES.FAMILY_CARE_OPTIONS) ||
    (pathname.includes(PAGE_ROUTES.SEARCH_CARE.BENEFITS) && isMobileSized)
  ) {
    const customHeaderProps = {
      [PAGE_ROUTES.SEARCH_CARE.BENEFITS]: {
        showBackButton: true,
        title: benefitsHeaderText,
        backgroundColor: colors.backgroundPrimary,
        shouldShowCareNavigatorHeaderEntrypoint: false,
        borderBottomColor: colors.borderDefault,
      },
    }

    const getCustomHeaderProps = (pathname: string) => {
      return customHeaderProps[pathname] || { shouldShowCareNavigatorHeaderEntrypoint: true, isTransparent: true }
    }

    return (
      <NavBarHeader
        isInternational={isInternational}
        onBack={() =>
          // if navigated from microsite we should navigate back to homebase
          currentDirectPathLinkSource === DirectLinkSource.MICROSITE_FORKED_TRIAGE ? navigate('/') : navigate(-1)
        }
        careNavigatorModal={careNavigatorModal}
        careNavigatorEntryPoint={careNavigatorEntryPoint}
        {...getCustomHeaderProps(pathname)}
      />
    )
  }

  const logoClickHandler = () => {
    const generateLogoHref = () => {
      if (isLoggedIn && hasRole(user, 'ROLE_care_manager')) {
        return '/secure/care-manager/provider-matcher/'
      } else if (isLoggedIn && hasRole(user, 'ROLE_usr')) {
        return '/secure/index/'
      } else {
        return '/'
      }
    }

    trackEventWithObj({
      event: MIXPANEL_EVENTS.CLICK_HEADER_LYRA_LOGO,
      page: convertRouteToMixpanelPage(pathname, undefined, false),
    })

    if (isPathInAutoEnrollmentFlow(pathname)) {
      window.open('/', '_blank')
    } else {
      navigate(generateLogoHref())
    }
    if (pressableRef.current) {
      pressableRef.current.blur()
    }
  }

  const homePage = ['/'].includes(pathname)
  const isLogInPage = isInLoginFlow(pathname)
  const isUnsubscribe = pathname.includes(PAGE_ROUTES.UNSUBSCRIBE)
  const LOGGED_OUT_BUTTONS = [
    {
      condition: () => (isLanguageSelectorEnabled && !isLoggedIn && (homePage || isFindEmployerPage)) || beforeLaunch,
      button: <LanguageSelector textColor={colors.linkDefault} customActionTextColor={colors.linkHover} />,
    },
    {
      condition: () => showReferral && !carepage && isLogInPage,
      button: (
        <ReferButton
          onPress={() => {
            shareReferral({
              source: 'login-page',
              formatMessage: intl.formatMessage,
              setToastContent,
              trackEventWithObj,
              isMobileSized,
              entryPoint: REFERRAL_ENTRY_POINTS.LOGIN_PAGE,
            })
          }}
        />
      ),
    },
    {
      condition: () =>
        isDisplayLanguageEnabled && ((isLogInPage && !carepage) || isRegistrationOnboardingPage || isUnsubscribe),
      button: <LanguageSelector textColor={colors.linkDefault} dropdownAlignment='right' />,
    },
  ]
  const loggedOutButtons = LOGGED_OUT_BUTTONS.map(({ condition, button }) => {
    return condition() && button
  })

  let customClass = ''
  const lyraLogo = (
    <Pressable
      ref={pressableRef}
      onPress={logoClickHandler}
      accessibilityRole='button'
      accessibilityLabel={intl.formatMessage({
        defaultMessage: 'Lyra Logo',
        description: 'Button that takes user to home page',
      })}
      testID={tID('Header-Lyra-Logo')}
    >
      <LyraLogo width={54} height={31} fillColor={colors.linkDefault} />
    </Pressable>
  )
  let ghosted = false
  if (homePage || beforeLaunch || isFindEmployerPage) {
    customClass = classnames(styles['header-microsite-ghosted-relative-transparent'], {
      [styles.loggedOut]: !isLoggedIn,
    })
    if (opaque && !carepage) {
      customClass = `${styles['header-microsite-opaque']}`
    } else {
      ghosted = true
    }
  } else if (
    isInRegisterFlow(pathname) ||
    isLogInPage ||
    pathname.includes(PAGE_ROUTES.REGISTRATION_ONBOARDING) ||
    isUnsubscribe
  ) {
    customClass = styles['transparent-header']
  } else {
    customClass = styles['lt-header']
    if (videoSessionOpen) {
      customClass = classnames(customClass, styles['video-session-open'])
    }
  }

  const pathsWithMobileHeader = [
    '/care-team-phone-numbers',
    '/secure/profile/update-location',
    '/secure/profile/update-notification-settings',
    '/secure/profile/update-personal-information',
    '/secure/profile/update-child-information',
    PAGE_ROUTES.PROFILE.DEFAULT,
    PAGE_ROUTES.PROFILE.ELIGIBILITY,
    PAGE_ROUTES.PROFILE.SETTINGS,
    PAGE_ROUTES.PROFILE.SESSION_HISTORY,
    PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS,
    PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL,
  ]
  if (isMobile && pathsWithMobileHeader.includes(pathname)) {
    return <MobileHeader pathname={pathname} isClientHPI={isClientHPI} />
  }

  if (['/register'].some((path) => pathname.startsWith(path))) {
    return (
      <AccountSetupHeader
        customClass={customClass}
        handleLogout={handleLogout}
        handleLogin={handleLogin}
        trackMenuClick={handleMenuBtnClick}
        lyraLogo={lyraLogo}
        ghost={ghosted}
      />
    )
  }

  const hideLoginButton =
    [
      '/login',
      '/worklife',
      '/unsubscribe',
      '/verify-attestation',
      '/forgot-password',
      '/reset-password',
      PAGE_ROUTES.CARE_TEAM_PHONE_NUMBERS,
      PAGE_ROUTES.REGISTRATION_ONBOARDING,
      PAGE_ROUTES.PATHWAYS.SELF_CARE,
    ].includes(pathname) ||
    beforeLaunch ||
    isRegistrationAndLoginDisabled ||
    carepage ||
    isViewingEssentialsUngatedContent
  const navigationMenuProps = {
    className: classnames(
      styles.loggedInNavigationHeader,
      shouldShowCareNavigatorHeaderEntrypoint && styles['no-margin'],
    ),
  }

  const shouldShowLTHeader = isLyraTherapy
  /** @todo: support logged out and registration headers in the crossplatform Header. stop using DefaultHeader */
  const shouldShowNewHeader = isLoggedIn && !isRegistrationOnboardingPage

  const headerNavMenuProps: HeaderNavMenuProps = {
    handleBenefitsPress: handleBenefitsClick,
    handleAccountPress: handleProfile,
    handleFAQsPress: handleFaqClick,
    handleReferralPress: handleReferralClick,
    handleLogOutPress: handleLogout,
    handleHomePress: handleHomeClick,
    handleCarePress: shouldShowLTHeader ? handleCareClick : handleCareEmptyStateClick,
    handleSessionsPress: handleSessionsClick,
    handleProgressPress: handleProgressClick,
    handleEssentialsPress: handleEssentialsClick,
    shouldShowReferral: () => showReferral,
    currentPathname: pathname,
    trackMenuClick: handleMenuBtnClick,
    showAccountBadge: shouldShowAccountBadge,
    animateAccountBadge,
    shouldShowEssentials: shouldShowLTHeader ? shouldShowEssentialsLT : shouldShowEssentials,
    shouldShowProgress: shouldShowLTHeader ? () => true : undefined,
    shouldShowCare: shouldShowLTHeader ? () => true : shouldShowCare,
    shouldShowHome: shouldShowLTHeader ? () => true : shouldShowHome,
    shouldShowSessions: shouldShowLTHeader ? () => true : shouldShowSessions,
  }

  const mainNavItemsProps = { ...headerNavMenuProps }
  mainNavItemsProps.shouldShowSessions = () => {
    return !isGPSv1Enabled && (headerNavMenuProps.shouldShowSessions ? headerNavMenuProps.shouldShowSessions() : false)
  }
  mainNavItemsProps.shouldShowProgress = () => {
    return !isGPSv1Enabled && (headerNavMenuProps.shouldShowProgress ? headerNavMenuProps.shouldShowProgress() : false)
  }

  const appHeader = (
    <AppHeader
      shouldShowCareNavigatorHeaderEntrypoint={shouldShowCareNavigatorHeaderEntrypoint}
      careNavigatorModal={careNavigatorModal}
      careNavigatorEntryPoint={careNavigatorEntryPoint}
      headerNavMenuProps={headerNavMenuProps}
      mainNavItemsProps={mainNavItemsProps}
      currentPathname={pathname}
      onLogoPressed={logoClickHandler}
      messagesPopover={shouldShowLTHeader && <MessagesPopover shouldUseHeaderStyle />}
      showMessages
    />
  )

  const showCareNavigatorButtonInMicrositeHeader = homePage && !beforeLaunch && !carepage

  const defaultHeader = (
    <DefaultHeader
      customClass={customClass}
      handleLogout={handleLogout}
      handleLogin={handleLogin}
      trackMenuClick={handleMenuBtnClick}
      user={isRegistrationOnboardingPage ? undefined : fromJS(user)}
      lyraLogo={lyraLogo}
      handleLogoClick={logoClickHandler}
      shouldHideLoginButton={hideLoginButton}
      handleRegister={handleGetCareClick}
      loggedOutButtons={loggedOutButtons}
      loginButtonClassName={styles.updatedLoginButton}
      ghost={ghosted}
      navigationMenuProps={navigationMenuProps}
      shouldShowCareNavigatorHeaderEntrypoint={
        (shouldShowCareNavigatorHeaderEntrypoint && isLoggedIn && !isRegistrationOnboardingPage) ||
        showCareNavigatorButtonInMicrositeHeader
      }
      isMobile={isMobileSized}
      careNavigatorModal={careNavigatorModal}
      careNavigatorEntryPoint={careNavigatorEntryPoint}
      handleSignUpClick={handleSignUpClick}
      headerNavMenu={<HeaderNavMenu {...headerNavMenuProps} />}
    />
  )

  return shouldShowNewHeader ? appHeader : defaultHeader
}

const mapStateToProps = ($$state: $TSFixMe, ownProps: $TSFixMe) => {
  const customerName = getCustomerName($$state)
  const episodes = $$state.getIn(['lyraTherapy', 'episodes'], List())
  const isLyraTherapy = !episodes.isEmpty()

  return {
    isLyraTherapy,
    appointmentSize: $$state.getIn(['appointmentDashboard', 'appointments']).size ?? 0,
    user: $$state.get('user'),
    videoSessionOpen: $$state.getIn(['lyraTherapy', 'video', 'sessionOpen']),
    carepage: ownProps.carepage ?? customerName === 'care',
    opaque: ownProps.opaque ?? customerName === 'care',
    worklifeEnabled: !!getCustomerWorkLifeBenefits($$state),
    hideExtraHeaderOptions: getHideExtraHeaderOptions($$state),
    essentialsDisabled: getEssentialsDisabled($$state),
    isWorkhubDisabled: getIsWorkhubDisabled($$state),
    beforeLaunch: getBeforeLaunch($$state),
    isUserInternational: getIsUserInternational($$state),
    isClientHPI: getHealthPlanExtensionEnabled($$state) || getHealthPlanPaymentCardLast4($$state),
    customerName,
    isUserAssumedInternational: getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer($$state),
    shouldShowAccountBadge: getShouldShowAccountBadge($$state),
    currentDirectPathLinkSource: getOnboardDirectLinkSource($$state),
  }
}

const mapDispatchToProps = {
  trackEventWithObj,
  resetHealthPlan,
  resetTriage,
  setToastContent,
  clearWellnessCheckInForm,
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(withTrackIntegratedBenefits(Header)))
