import React, { FunctionComponent } from 'react'
import { MessageDescriptor, useIntl } from 'react-intl'
import { ImageSourcePropType } from 'react-native'

import { isEmpty, isString } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { PressableOpacity } from '../../atoms'
import { BodyText, Size as BodyTextSize } from '../../atoms/bodyText/BodyText'
import { ChevronIconDirection } from '../../atoms/icons/ChevronIcon'
import { ChevronV2Icon } from '../../atoms/icons/ChevronV2Icon'
import { Image } from '../../atoms/image/Image'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export interface IntegratedBenefitsItemProps {
  title: string | MessageDescriptor
  image: React.ReactNode | string | number
  description: MessageDescriptor | string
  badges?: React.ReactNode[]
  testID?: string
  widerBenefitsItemTitleContainer?: boolean
  onPress: () => any
}

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  minWidth: '48px',
  margin: 'auto',
  marginRight: spacing['16px'],
}))

const ImageComponent = styled(Image)<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  height: spacing['48px'],
  width: spacing['48px'],
  borderRadius: spacing['8px'],
}))

const Link = styled(PressableOpacity)({
  alignItems: 'flex-start',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: '16px',
})

const Row = styled.View<{ wrap?: boolean }>(({ wrap }) => ({
  flexDirection: 'row',
  flexWrap: wrap ? 'wrap' : 'nowrap',
  flexGrow: 1,
  flexShrink: 1,
}))

const TitleContainer = styled.View<{ theme: ThemeType; widerTitleContainer?: boolean }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
    widerTitleContainer,
  }) => ({
    flexShrink: isMinWidthTablet ? 0 : 1,
    paddingRight: isMinWidthTablet ? spacing['32px'] : 0,
    justifyContent: 'center',
    width: widerTitleContainer ? '290px' : '210px',
  }),
)

const DescriptionContainer = styled.View({
  maxWidth: '594px',
  flexShrink: 1,
  justifyContent: 'center',
})

const BadgesContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginTop: spacing['8px'],
  width: '100%',
  flexDirection: 'row',
}))

const ChevronContainer = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
      spacing,
    },
  }) => ({
    paddingLeft: isMinWidthTablet ? spacing['16px'] : 'auto',
    marginLeft: 'auto',
    paddingTop: isMinWidthTablet ? 0 : '2px',
    alignSelf: isMinWidthTablet ? 'center' : 'auto',
  }),
)

const Chevron = () => {
  const { colors } = useTheme()

  return (
    <ChevronContainer>
      <ChevronV2Icon size={12} direction={ChevronIconDirection.RIGHT} fillColor={colors.iconInactive} />
    </ChevronContainer>
  )
}

type ResponsiveLayoutProps = {
  title: React.ReactNode
  description: MessageDescriptor | string
  badges?: React.ReactNode[]
  isMobileSized: boolean
  widerTitleContainer?: boolean
}

const MobileWrapper = styled.View({
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 1,
})

const DesktopWrapper = styled.View<{ theme: ThemeType }>(
  ({
    theme: {
      breakpoints: { isMinWidthTablet },
    },
  }) => ({
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    alignItems: isMinWidthTablet ? 'center' : 'flex-start',
    margin: 'auto',
  }),
)

const ResponsiveLayout = ({
  title,
  description,
  badges,
  isMobileSized,
  widerTitleContainer,
}: ResponsiveLayoutProps) => {
  const { formatMessage } = useIntl()
  const { colors } = useTheme()

  let textToDisplay = ''
  if (description) {
    textToDisplay = isString(description) ? description : formatMessage(description, { br: '\n' })
  }

  const Description = () => (
    <DescriptionContainer>
      <BodyText size={BodyTextSize.SMALL} color={colors.textSecondary} text={textToDisplay} />
    </DescriptionContainer>
  )

  if (isMobileSized) {
    return (
      <MobileWrapper>
        <Row>
          <TitleContainer widerTitleContainer={widerTitleContainer}>{title}</TitleContainer>
          <Chevron />
        </Row>
        <Description />
        {!isEmpty(badges) && <BadgesContainer>{badges}</BadgesContainer>}
      </MobileWrapper>
    )
  }

  return (
    <DesktopWrapper>
      <Row>
        <TitleContainer widerTitleContainer={widerTitleContainer}>{title}</TitleContainer>
        <Row wrap={true}>
          <Row>
            <Description />
            <Chevron />
          </Row>
          {!isEmpty(badges) && <BadgesContainer>{badges}</BadgesContainer>}
        </Row>
      </Row>
    </DesktopWrapper>
  )
}

export const IntegratedBenefitsItem: FunctionComponent<IntegratedBenefitsItemProps> = ({
  image,
  title,
  description,
  badges,
  testID,
  widerBenefitsItemTitleContainer,
  onPress,
}) => {
  const {
    breakpoints: { isMinWidthTablet },
  } = useTheme()
  const { formatMessage } = useIntl()
  const imagePropIsASource = typeof image === 'string' || typeof image === 'number'
  const titleToDisplay = isString(title) ? title : formatMessage(title)
  return (
    <Link testID={tID(`IntegratedBenefitsItem${testID ? `-${testID}` : ''}`)} onPress={onPress}>
      <ImageContainer>
        {imagePropIsASource ? <ImageComponent source={image as ImageSourcePropType} /> : image}
      </ImageContainer>
      <ResponsiveLayout
        title={<Subhead size={SubheadSize.XSMALL} text={titleToDisplay} />}
        description={description}
        badges={badges}
        isMobileSized={!isMinWidthTablet}
        widerTitleContainer={widerBenefitsItemTitleContainer}
      />
    </Link>
  )
}
