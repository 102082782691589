import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router'

import { userProfileMessages } from '@lyrahealth-inc/shared-app-logic'
import { LightHomeToolbar } from '@lyrahealth-inc/ui-core-crossplatform'

import { PAGE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'

const MobileHeader = ({ pathname, isClientHPI }: { pathname: string; isClientHPI?: boolean }) => {
  const navigate = useNavigate()

  const pathToTitle = (path: string, isClientHPI?: boolean) => {
    switch (path) {
      case '/care-team-phone-numbers':
        return (
          <FormattedMessage
            defaultMessage='Care support'
            description='Header text for care navigator team support page'
          />
        )
      case '/secure/profile/update-location':
        return <FormattedMessage {...userProfileMessages.location} />
      case '/secure/profile/update-notification-settings':
        return <FormattedMessage {...userProfileMessages.notifications} />
      case '/secure/profile/update-personal-information':
        return <FormattedMessage {...userProfileMessages.personalInformation} />
      case '/secure/profile/update-child-information':
        return <FormattedMessage {...userProfileMessages.childInformation} />
      case PAGE_ROUTES.PROFILE.ELIGIBILITY:
        return <FormattedMessage defaultMessage='Eligibility' description='Title for the profile eligibility page' />
      case PAGE_ROUTES.PROFILE.SETTINGS:
        return <FormattedMessage {...userProfileMessages.settings} />
      case PAGE_ROUTES.PROFILE.SESSION_HISTORY:
        return <FormattedMessage {...userProfileMessages.sessionHistory} />
      case PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS:
        return isClientHPI ? (
          <FormattedMessage {...userProfileMessages.healthPlanAndPayments} />
        ) : (
          <FormattedMessage {...userProfileMessages.payment} />
        )
      case PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL:
        return <FormattedMessage {...userProfileMessages.privacyAndLegal} />
      default:
        return undefined
    }
  }

  const handleBackPress = () => {
    switch (pathname) {
      case PAGE_ROUTES.PROFILE.HEALTH_PLAN_AND_PAYMENTS:
      case PAGE_ROUTES.PROFILE.SETTINGS:
      case PAGE_ROUTES.PROFILE.PRIVACY_AND_LEGAL:
        navigate('/secure/profile')
        break
      default:
        navigate(-1)
    }
  }
  return <LightHomeToolbar title={pathToTitle(pathname, isClientHPI)} showBack onBackPressed={handleBackPress} />
}

export default MobileHeader
