import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ScrollView, View, ViewStyle } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CloseIcon, LyraLogo, MessagingIcon, XButton } from '../../atoms'
import { ArrowIcon, ArrowIconDirection } from '../../atoms/icons/ArrowIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { Subhead } from '../../atoms/subhead/Subhead'
import { Toolbar, ToolbarTab } from '../../molecules'
import { getFocusBoxShadow, SubheadSize } from '../../styles'
import { RowView } from '../../templates'
import { ThemeType, tID } from '../../utils'
import { HeaderNavMenu, HeaderNavMenuProps } from '../headerNavMenu/HeaderNavMenu'

export interface HeaderNavItem {
  text: string
  id: string
  testId?: string
  onPressItem?: () => void
  condition?: () => boolean
  activePathnames?: string[]
  activeScreens?: string[]
}

export type LightHomeToolbarProps = {
  testID?: string
  /** Care programs */
  tabs?: Array<{ name: string; tabId: string }>
  activeTabId?: string
  onTabPressed?: (tabId: string) => void
  /** Main toolbar title */
  title?: string | JSX.Element
  /** Title text size */
  textSize?: SubheadSize
  topInset?: number
  showBack?: boolean
  onBackPressed?: () => void
  showNavMenu?: boolean
  showMessages?: boolean
  unreadMessages?: number
  onMessagesPressed?: () => void
  messagesPopover?: JSX.Element
  shouldShowCareNavigatorHeaderEntrypoint?: boolean
  careNavigatorModal?: React.ReactElement
  careNavigatorEntryPoint?: React.ReactElement
  isInternational?: boolean
  showClose?: boolean
  onClosePressed?: () => void
  showExit?: boolean
  showLogo?: boolean
  onLogoPressed?: () => void
  headerNavMenuProps?: HeaderNavMenuProps
  navItems?: HeaderNavItem[]
  currentPathname?: string
  currentScreen?: string
}

const Container = styled.View<{ theme: ThemeType }>(({ theme: { colors } }) => ({
  flexDirection: 'column',
  borderBottomWidth: '1px',
  borderBottomColor: colors.dividerSecondary,
}))

const HeaderRow = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  minHeight: theme.breakpoints.isMobileSized ? '56px' : '62px',
  alignItems: 'center',
  marginHorizontal: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['32px'],
}))

const LeftContainer = styled.View({
  zIndex: 1,
})

const RightContainer = styled(RowView)({
  marginLeft: 'auto',
  alignItems: 'center',
})

const MenuButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['32px'],
  alignSelf: 'center',
}))

const StyledXButton = styled(XButton)({
  alignSelf: 'center',
  top: 0,
})

const LogoButtonContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['4px']} ${theme.spacing['8px']}`,
  borderRadius: theme.spacing['8px'],
}))

const MessagesPopoverContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['16px'],
  borderRadius: theme.spacing['8px'],
}))

const PageTitleContainer = styled.View({
  position: 'absolute',
  left: 0,
  right: 0,
})

const NavItemsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['48px'],
  flexDirection: 'row',
  gap: theme.spacing['24px'],
}))

const NavItemButton = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  padding: theme.spacing['8px'],
  borderRadius: theme.spacing['8px'],
}))

const NavItemButtonActiveUnderline = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  height: '3px',
  backgroundColor: theme.colors.backgroundActive,
  position: 'absolute',
  borderTopLeftRadius: theme.spacing['16px'],
  borderTopRightRadius: theme.spacing['16px'],
  left: 0,
  right: 0,
  bottom: '-12px',
  marginLeft: 'auto',
  marginRight: 'auto',
}))

/**
 * Tool bar component representing the home page
 * - If in the mobile app - use `AppHeader`
 */
export const LightHomeToolbar: FunctionComponent<LightHomeToolbarProps> = ({
  testID,
  tabs = [],
  activeTabId = '',
  onTabPressed,
  title,
  topInset = 0,
  showBack = false,
  onBackPressed,
  showNavMenu = true,
  showMessages = false,
  unreadMessages = 0,
  onMessagesPressed,
  messagesPopover,
  textSize = SubheadSize.SMALL,
  shouldShowCareNavigatorHeaderEntrypoint = false,
  careNavigatorEntryPoint,
  careNavigatorModal,
  showClose = false,
  showLogo = true,
  onLogoPressed,
  showExit = false,
  onClosePressed,
  headerNavMenuProps,
  navItems,
  currentPathname,
  currentScreen,
}) => {
  const { formatMessage } = useIntl()
  const { colors, isDarkMode, breakpoints } = useTheme() as ThemeType
  const shouldShowCareNavigator = shouldShowCareNavigatorHeaderEntrypoint && careNavigatorEntryPoint
  const shouldShowCareNavigatorOverLogo = shouldShowCareNavigator && breakpoints.isMobileSized
  const shouldShowCareNavigatorOnRight = shouldShowCareNavigator && !breakpoints.isMobileSized

  const getTabs = () =>
    tabs.map(({ name, tabId }) => {
      return (
        <ToolbarTab
          key={`Tab-${name}`}
          name={name}
          onPress={onTabPressed ? () => onTabPressed(tabId) : noop}
          active={activeTabId === tabId}
        />
      )
    })

  const pressableStyle = {
    hoveredBackgroundColor: colors.tertiaryButtonBackgroundHover,
    pressedBackgroundColor: colors.tertiaryButtonBackgroundPressed,
    styleByState: (pressed: boolean, focusVisible: boolean) => {
      const style: ViewStyle = {}
      if (pressed) {
        style.boxShadow = getFocusBoxShadow({ colors, outerBoxShadowSize: '6px' }).boxShadow
        style.backgroundColor = colors.tertiaryButtonBackgroundPressed
      }
      if (focusVisible && !pressed) {
        style.backgroundColor = colors.tertiaryButtonBackgroundHover
      }
      return style
    },
  }

  const showTitle = (showBack || showClose) && Boolean(title)
  const showTabs = !showBack && tabs.length > 0

  const messagesIcon = (
    <PressableOpacity onPress={onMessagesPressed} hitSlop={10} testID={tID('MessageIcon-button')}>
      <MessagingIcon fillColor={colors.iconDefault} unreadMessages={unreadMessages} />
    </PressableOpacity>
  )

  const navMenu = (
    <>
      {headerNavMenuProps && (
        <MenuButtonContainer>
          <HeaderNavMenu {...headerNavMenuProps} highlightTrigger pressableTriggerStyle={pressableStyle.styleByState} />
        </MenuButtonContainer>
      )}
    </>
  )

  const backIcon = (
    <PressableOpacity
      onPress={onBackPressed}
      hitSlop={10}
      testID={tID('GoBack-button')}
      accessibilityLabel={formatMessage({
        defaultMessage: 'Go back',
        description: 'Button to go back',
      })}
    >
      <ArrowIcon fillColor={colors.iconDefault} direction={ArrowIconDirection.LEFT} />
    </PressableOpacity>
  )

  const closeIcon = (
    <PressableOpacity onPress={onClosePressed} hitSlop={10} testID={tID('LightHomeToolbar-close-button')}>
      <CloseIcon fillColor={colors.iconDefault} size={16} />
    </PressableOpacity>
  )

  const careNavigatorButton = careNavigatorEntryPoint ? (
    <View
      accessible
      accessibilityLabel={formatMessage({
        defaultMessage: 'Care Navigator',
        description: 'Care navigator entry point',
      })}
      testID={tID('Care-Navigator-Global-EntryPoint-Button')}
    >
      {React.cloneElement(careNavigatorEntryPoint, { lightBgColor: !isDarkMode })}
    </View>
  ) : null

  const getActiveNavItem = (item: HeaderNavItem) => {
    const isActivePath =
      item.activePathnames &&
      currentPathname &&
      item.activePathnames.some((activePath) => {
        return currentPathname.includes(activePath)
      })
    const isActiveScreen =
      item.activeScreens &&
      currentScreen &&
      item.activeScreens.some((activePath) => {
        return currentScreen.includes(activePath)
      })
    return isActivePath || isActiveScreen
  }

  const messageButton = messagesPopover ? (
    <MessagesPopoverContainer>{messagesPopover}</MessagesPopoverContainer>
  ) : onMessagesPressed ? (
    messagesIcon
  ) : null

  return (
    <>
      <Toolbar topInset={topInset} backgroundColor={colors.backgroundPrimary}>
        <Container testID={testID || tID('LightHomeToolbar')}>
          <HeaderRow>
            <LeftContainer>
              {showBack ? (
                backIcon
              ) : shouldShowCareNavigatorOverLogo ? (
                careNavigatorButton
              ) : showLogo ? (
                <LogoButtonContainer
                  onPress={onLogoPressed}
                  accessibilityRole='button'
                  accessibilityLabel={formatMessage({
                    defaultMessage: 'Lyra Logo',
                    description: 'Button that takes user to home page',
                  })}
                  testID={tID('Header-Lyra-Logo')}
                  {...pressableStyle}
                >
                  <LyraLogo width={54} height={31} fillColor={colors.linkDefault} />
                </LogoButtonContainer>
              ) : null}
            </LeftContainer>
            {navItems && breakpoints.isMinWidthLaptop && !showBack && !showClose && (
              <NavItemsContainer>
                {navItems.map((item) => {
                  const isActive = getActiveNavItem(item)
                  return item.condition && item.condition() ? (
                    <NavItemButton
                      key={item.id}
                      testID={tID(`Header-${item.id}`)}
                      onPress={item.onPressItem}
                      {...pressableStyle}
                    >
                      <Subhead
                        text={item.text}
                        size={SubheadSize.XSMALL}
                        regular
                        color={isActive ? colors.textActive : colors.textPrimary}
                      />
                      {isActive && <NavItemButtonActiveUnderline testID={tID(`Header-${item.id}-active`)} />}
                    </NavItemButton>
                  ) : (
                    <></>
                  )
                })}
              </NavItemsContainer>
            )}
            {showTitle && (
              <PageTitleContainer>
                <Subhead text={title} size={textSize} textAlign='center' />
              </PageTitleContainer>
            )}
            <RightContainer>
              {shouldShowCareNavigatorOnRight && careNavigatorButton}
              {showMessages && messageButton}
              {showNavMenu && navMenu}
              {showClose && closeIcon}
              {showExit && onClosePressed && (
                <StyledXButton testID={tID(`LightHomeToolbar-exitButton`)} onPress={onClosePressed} />
              )}
            </RightContainer>
          </HeaderRow>
          {showTabs && (
            <ScrollView horizontal bounces={false}>
              {getTabs()}
            </ScrollView>
          )}
        </Container>
      </Toolbar>
      {careNavigatorModal}
    </>
  )
}
