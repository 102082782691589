import { LDFlagSet } from 'launchdarkly-react-client-sdk'

import { useFlags as useLDFlags } from './launchdarkly-client-sdk.web'
import { CustomerInstanceMap } from '../common/constants/customer'
import { DEFAULT_SPECULATIVE_FILTER_THRESHOLDS } from '../wellnessToolkit/constants'
import { SpeculativeSearchThresholds } from '../wellnessToolkit/types'

export interface LDFlags extends LDFlagSet {
  shouldSkipTreatmentRecsPageForTier3: boolean
  isDisplayLanguageEnabled: boolean
  isLanguageSelectorEnabled: boolean
  isSmartlingContextCaptureEnabled: boolean
  showDevLanguages: boolean
  showNewCaseloadDashboardProviders: boolean
  showPlatformEngagementDashboard: boolean
  requireEligibilityConfirmation?: {
    isEnabled: boolean
    skipIneligibleUserWithLyraCode?: boolean
  }
  languagesEnabled: string[]
  micrositeLanguagesEnabled: string[]
  customersToShowClientEAPCheck: { supportedCustomers: string[] }
  isSessionTimerEnabled: boolean
  isECDCEnabled: boolean
  isNewRegistrationNomenclatureEnabled: boolean
  useUpdatedCareTeamPhoneNumberPage: boolean
  shouldIncludeRelID: boolean
  shouldShowPastQuartersInBCXDashboards: boolean
  showPastQuartersInLCCDashboard: boolean
  shouldShowCareNavigatorHeaderEntrypoint: boolean
  isUSCountryDisabled: boolean
  withLicenses: boolean
  isLatestMatchesEnabled: boolean
  shouldUseNewRescheduleScreen: boolean
  isInPersonPreferenceEnabled: boolean
  isMaintenanceModeEnabled: boolean
  isLCSmartSchedulingEnabled: boolean
  shouldReplaceWalkmeTooltips: boolean
  searchForCareHeaderExperimentQ12025: { headerVariant?: string }
  isCoachingEntryPointEnabled: boolean
  parentToolKitEntryPointEnabled: boolean
  showInfoNeededWhenUnresolvedInfraction: boolean
  allowDemoBeforeLaunch: boolean
  shouldSendNewWLQ: boolean
  customerInstanceMap: CustomerInstanceMap
  maxBenefitsToShowInExplorer: number
  isClientDataShareEnabled: boolean
  shouldDisplayFastTrackBooking: boolean
  enableMixpanelDebug: boolean
  shouldDisplayPPUIPSABanner: boolean
  PPUIPSABannerContentString: string
  isCareNavigatorTeamBookingEnabled: boolean
  isPathwaysMicrositeMultiCTAEnabled: boolean
  isProviderProfileVideosEnabled: boolean
  isProgramLevelSessionLimitEnabled: boolean
  showMessageProviderAlternateTimesLink: boolean
  isCHCOutage?: boolean
  isCopayForMaxCostEstimateEnabled: boolean
  shouldShowCapacityToAvailabilityRatioAlert: boolean
  showCalendarAvailabilitiesWithCapacity: boolean
  showDetailedWeeklyViewForCMD: boolean
  showCaseloadFromDropdown: boolean
  shouldShowDirectProgramLinkInClientProfile: boolean
  supervisorsThatCanMarkConversationsAsRead: string[]
  showNotificationModalOnLoginLandingPage: boolean
  isHPIConsentWithMedicareEnabled: boolean
  showCustomNotifications: boolean
  enableAINotesFeatures: boolean
  showAINotesFeatures: boolean
  showDevFeatureFlagContainer?: boolean
  isMobileRegistrationEnabled?: boolean
  isMobileEligibilityBannerEnabled?: boolean
  shouldShowLCPositioning: boolean
  triageScreenersMetadataVersion: string
  isSecondaryHealthPlanCollectionFeatureEnabled?: boolean
  hideUserTestimonialsFromLCxModal: boolean
  isDedicatedCareNavigatorEnabled: boolean
  isFamilyHubEnabled: boolean
  shouldSeePendoGuides: boolean
  shouldShowCostEstimatesAsFullyCovered: boolean
  isCostMessagingEnabled: boolean
  isPreferredNameEnabled: boolean
  shouldShowNewMinimizedIntegratedBenefitsDesign: boolean
  isElectionsEnabled: boolean
  showPickedForYouV2: boolean
  showEnhancedLCxDiagnosis: boolean
  shouldShowTriageSummary: boolean
  isInProductCalendarEnabled: boolean
  triageAnimationsDisabled: boolean
  isZendeskWidgetEnabled: boolean
  showUpdatedConnectedCalendar: boolean
  shouldShowInAppMessaging: boolean
  isAutoEnrollmentEnabled: boolean
  showPickedForYouV3: boolean
  isWellnessCheckInEnabled: boolean
  enableNewClientTestimonials: boolean
  shouldHardCodeFreenomeSessionResetDate: boolean
  routeUserDirectlyToAgentInInAppMessagingChatWindow: boolean
  chatSessionTimeoutDuration: number
  shouldDisplayLWPSABanner: boolean
  LWPSABannerContentString: string
  isCFPEnabled: boolean
  differentialPricingRatesEnabled: boolean
  isGPSv1Enabled: boolean
  differentialPricingCalculationEnabled: boolean
  isCareOnboardingPhase2Enabled: boolean
  isEnhancedRiskProtocolEnabled: boolean
  isMicrositeLookAndFeelUpdateEnabled: boolean
  shouldShowCareTabInitial: boolean
  forkedTriageV2Enabled: boolean
  parentLedBookingV3Enabled: boolean
  shouldShowAvailabilityPreference: boolean
  shouldDisplayDemoText: boolean
  isSSLPinningEnabled: boolean
  enableInAppMessagingForTesting: boolean
  isSpeculativeFilterEnabled: boolean
  speculativeSearchThresholds: SpeculativeSearchThresholds
  isNewYearToolkitEnabled: boolean
  renderSaveYourSpotV2Assignment: boolean[]
  shouldShowProviderQuickNavigation: boolean
  isRegistrationAndLoginDisabled: boolean
  isElectionsEntryPointEnabled: boolean
  adultTriageDisabled: boolean
  forceAdultFlowsToHPI: boolean
  showInProductCalendarAlerts: boolean
  isAutoEnrollmentV2Enabled: boolean
  isPhoneNumberRequiredForApptRequest: boolean
  isCookieBannerEnabled: boolean
  showProviderResultsWithElevatedTopMatch: boolean
  inProductCalendarRefreshIntervalMS: number
  isAutoEnrollmentSSOEnabled: boolean
  isAppleSwitchToDateOfBirthEligibilityEnabled: boolean
  showAINotesForBCCAndGSC: boolean
  useUICCLessonComponent: boolean
  isSUDSExpansionEnabled: boolean
}

export const INITIAL_LD_FLAGS: LDFlags = {
  shouldShowCapacityToAvailabilityRatioAlert: false,
  showInfoNeededWhenUnresolvedInfraction: false,
  allowDemoBeforeLaunch: false,
  shouldSendNewWLQ: false,
  isClientDataShareEnabled: false,
  enableMixpanelDebug: false,
  shouldDisplayPPUIPSABanner: false,
  PPUIPSABannerContentString: '',
  isCareNavigatorTeamBookingEnabled: false,
  isPathwaysMicrositeMultiCTAEnabled: false,
  showMessageProviderAlternateTimesLink: false,
  showCalendarAvailabilitiesWithCapacity: false,
  showDetailedWeeklyViewForCMD: false,
  showCaseloadFromDropdown: false,
  shouldShowDirectProgramLinkInClientProfile: false,
  supervisorsThatCanMarkConversationsAsRead: [],
  enableAINotesFeatures: false,
  showAINotesFeatures: false,
  showDevFeatureFlagContainer: false,
  isDisplayLanguageEnabled: true,
  showCustomNotifications: false,
  languagesEnabled: [],
  isUSCountryDisabled: false,
  isLatestMatchesEnabled: false,
  isMobileRegistrationEnabled: false,
  isMobileEligibilityBannerEnabled: false,
  requireEligibilityConfirmation: { isEnabled: false },
  isCoachingEntryPointEnabled: false,
  parentToolKitEntryPointEnabled: false,
  customerInstanceMap: {},
  maxBenefitsToShowInExplorer: 3,
  shouldDisplayFastTrackBooking: false,
  shouldSkipTreatmentRecsPageForTier3: false,
  isProviderProfileVideosEnabled: false,
  isCHCOutage: false,
  isCopayForMaxCostEstimateEnabled: false,
  isHPIConsentWithMedicareEnabled: false,
  isLanguageSelectorEnabled: false,
  isSmartlingContextCaptureEnabled: false,
  showDevLanguages: false,
  showNewCaseloadDashboardProviders: false,
  showPlatformEngagementDashboard: false,
  micrositeLanguagesEnabled: [],
  customersToShowClientEAPCheck: { supportedCustomers: [] },
  isSessionTimerEnabled: false,
  isECDCEnabled: false,
  isNewRegistrationNomenclatureEnabled: false,
  useUpdatedCareTeamPhoneNumberPage: false,
  shouldIncludeRelID: false,
  shouldShowPastQuartersInBCXDashboards: false,
  showPastQuartersInLCCDashboard: false,
  shouldShowCareNavigatorHeaderEntrypoint: false,
  shouldUseNewRescheduleScreen: false,
  withLicenses: false,
  isInPersonPreferenceEnabled: false,
  isLCSmartSchedulingEnabled: false,
  shouldReplaceWalkmeTooltips: false,
  isMaintenanceModeEnabled: false,
  showNotificationModalOnLoginLandingPage: false,
  isProgramLevelSessionLimitEnabled: false,
  searchForCareHeaderExperimentQ12025: {},
  shouldShowLCPositioning: false,
  triageScreenersMetadataVersion: 'latest',
  isSecondaryHealthPlanCollectionFeatureEnabled: false,
  hideUserTestimonialsFromLCxModal: false,
  isDedicatedCareNavigatorEnabled: false,
  isFamilyHubEnabled: false,
  shouldSeePendoGuides: false,
  shouldShowCostEstimatesAsFullyCovered: false,
  isCostMessagingEnabled: false,
  isPreferredNameEnabled: false,
  shouldShowNewMinimizedIntegratedBenefitsDesign: false,
  isElectionsEnabled: false,
  showPickedForYouV2: false,
  showEnhancedLCxDiagnosis: false,
  shouldShowTriageSummary: false,
  isInProductCalendarEnabled: false,
  triageAnimationsDisabled: false,
  isZendeskWidgetEnabled: false,
  showUpdatedConnectedCalendar: false,
  shouldShowInAppMessaging: false,
  isAutoEnrollmentEnabled: false,
  showPickedForYouV3: false,
  isWellnessCheckInEnabled: false,
  enableNewClientTestimonials: false,
  shouldHardCodeFreenomeSessionResetDate: false, // ACCOUNT-2596: Remove this flag on 1/1/25
  routeUserDirectlyToAgentInInAppMessagingChatWindow: false,
  chatSessionTimeoutDuration: 600,
  shouldDisplayLWPSABanner: false,
  LWPSABannerContentString: '',
  isCFPEnabled: false,
  differentialPricingRatesEnabled: false,
  isGPSv1Enabled: false,
  differentialPricingCalculationEnabled: false,
  isCareOnboardingPhase2Enabled: false,
  isEnhancedRiskProtocolEnabled: false,
  isMicrositeLookAndFeelUpdateEnabled: false,
  shouldShowCareTabInitial: false,
  forkedTriageV2Enabled: false,
  parentLedBookingV3Enabled: false,
  shouldShowAvailabilityPreference: false,
  shouldDisplayDemoText: false,
  enableInAppMessagingForTesting: false,
  renderSaveYourSpotV2Assignment: [],
  isSpeculativeFilterEnabled: false,
  speculativeSearchThresholds: DEFAULT_SPECULATIVE_FILTER_THRESHOLDS,
  isNewYearToolkitEnabled: false,
  isSSLPinningEnabled: false,
  shouldShowProviderQuickNavigation: false,
  isRegistrationAndLoginDisabled: false,
  isElectionsEntryPointEnabled: false,
  adultTriageDisabled: false,
  forceAdultFlowsToHPI: false,
  showInProductCalendarAlerts: false,
  isAutoEnrollmentV2Enabled: false,
  isPhoneNumberRequiredForApptRequest: false,
  isCookieBannerEnabled: false,
  showProviderResultsWithElevatedTopMatch: false,
  inProductCalendarRefreshIntervalMS: 300000,
  isAutoEnrollmentSSOEnabled: false,
  isAppleSwitchToDateOfBirthEligibilityEnabled: false,
  showAINotesForBCCAndGSC: false,
  useUICCLessonComponent: false,
  isSUDSExpansionEnabled: false,
}

export const FLAGS_PARAM_NAME = 'flags'

function isDevFlagsEnabled() {
  return typeof __DEV__ !== 'undefined' && __DEV__
}

/**
 * Get flags from dev hooks:
 *  1. flags set using localstorage
 *  2. flags set using URL param
 */
export function getDevFlags(): Partial<LDFlags> {
  if (!isDevFlagsEnabled()) {
    return {}
  }

  const devFlags = []

  // try to get flags from localstorage
  if (typeof localStorage !== 'undefined' && localStorage.getItem(FLAGS_PARAM_NAME)) {
    devFlags.push(localStorage.getItem(FLAGS_PARAM_NAME))
  }

  // try to get flags from URL param
  const searchParams = new URLSearchParams(window?.location?.search)
  if (searchParams.has(FLAGS_PARAM_NAME)) {
    devFlags.push(searchParams.get(FLAGS_PARAM_NAME))
  }

  let result = {}
  try {
    // merge dev flags with original LD flags from LD server
    result = devFlags.reduce((acc, flagsStr) => {
      const flags = flagsStr ? JSON.parse(flagsStr) : {}
      return { ...acc, ...flags }
    }, {})
  } catch (e) {
    console.error('Error parsing flags param. Make sure flags has a valid JSON string.', e)
  }

  return result
}

/**
 * Dev hook to overwrite flags set on LD server.
 * They are overwritten in the following order:
 *  1. flags set using localstorage
 *  2. flags set using URL param
 *
 * @param ldFlags flags on LD server
 */
export function overrideFlagsWithSearchParams(ldFlags: LDFlags): LDFlags {
  const devFlags = getDevFlags()
  // `ldFlags` is a Proxy object that has a get trap that triggers an evaluation event when a flag is being read.
  // Here in order to read the devFlags first, we create a another Proxy object around it.
  // We can't simply return { ...ldFlags, ...devFlags } neither use Object.assign here because that causes
  // the Proxy get trap to be called for all flags, triggering an evaluation event for all flags,
  // which blows up the batch events queue and causes events to be dropped.
  return new Proxy(ldFlags, {
    get(target, prop, receiver) {
      return devFlags[prop as string] ?? Reflect.get(target, prop, receiver)
    },
  })
}

const useFlagsDev = (): LDFlags => {
  const ldFlags = useLDFlags()
  return overrideFlagsWithSearchParams(ldFlags)
}

export const useFlags = isDevFlagsEnabled() ? useFlagsDev : (useLDFlags as () => LDFlags)

export const defaultFlags: Partial<LDFlags> = {
  speculativeSearchThresholds: DEFAULT_SPECULATIVE_FILTER_THRESHOLDS,
  isPhoneNumberRequiredForApptRequest: true,
  renderSaveYourSpotV2Assignment: [],
}
