/**
 * Base colors should never be exportable to not encourage direct access
 * We want to only rely on our useTheme hook to use colorTokens

 * See [old to new color transitions](https://www.figma.com/file/3GhAfv6ajZBTVgn4gGtjiQ/Foundations-Guidelines?type=design&node-id=3132-4318&mode=design&t=OkWv1lS2UTAEbZDF-0)
*/
const baseColors = {
  // Core UI
  oatmeal_60: '#1A1918',
  oatmeal_50: '#4F4D4A',
  oatmeal_40: '#777673',
  oatmeal_30: '#DFDBD6',
  oatmeal_20: '#F5F2EE',
  oatmeal_15: '#F7F6F5',
  oatmeal_10: '#FBFAF9',
  black: '#000000',
  white: '#FFFFFF',

  // Success
  green_70: '#123817',
  green_60: '#00520A',
  green_50: '#357806',
  green_40: '#5F9C22',
  green_30: '#8CC94B',
  green_20: '#D6E8C1',
  green_10: '#EDF5DC',

  // Interactive
  teal_80: '#021117',
  teal_75: '#031821',
  teal_70: '#05212B',
  teal_65: '#062938',
  teal_60: '#073345',
  teal_50: '#05697E',
  teal_40: '#01798D',
  teal_30: '#3BA5B5',
  teal_20: '#CAE9ED',
  teal_10: '#E7F8F8',
  teal_0: '#F8FFFF',

  // Warning
  gold_60: '#8C351C',
  gold_50: '#AB4C11',
  gold_40: '#CC7614',
  gold_30: '#F3B962',
  gold_20: '#FEEAC4',
  gold_10: '#FFF1D6',

  // Accent
  peach_60: '#983726',
  peach_50: '#B84C33',
  peach_40: '#D96D4A',
  peach_30: '#FCB097',
  peach_20: '#FCD0C2',
  peach_10: '#FFF3F0',

  // Error
  red_60: '#7D2F42',
  red_50: '#A63D51',
  red_40: '#D16B74',
  red_30: '#EB989B',
  red_20: '#FFDEDE',
  red_10: '#FFF0F0',

  // Transparent
  scrim: '#41444ECC',
  transparent: '#FFFFFF00',
  shadow: '#00000033',

  peri_70: '#131353',
  peri_60: '#1f1f6E',
  peri_50: '#444693',
  peri_40: '#6871C4',
  peri_30: '#93A1E1',
  peri_20: '#DCE0F7',
  peri_10: '#EDF0FC',

  // Gradient colors
  gradient_box_purple: '#F3F5FF',
  gradient_box_teal: '#EFFDFF',
}

interface CustomComponentColorTokens {
  background: string
  border: string
  text: string
  fill: string
}

type BackgroundToken = Pick<CustomComponentColorTokens, 'background'>
type BorderToken = Pick<CustomComponentColorTokens, 'border'>
type TextToken = Pick<CustomComponentColorTokens, 'text'>
type FillToken = Pick<CustomComponentColorTokens, 'fill'>

interface CustomComponents {
  activityBadge: BackgroundToken & TextToken
  activityBadgeTeen: BackgroundToken & TextToken
  activityCard: {
    activityBadgeWarn: BackgroundToken & TextToken
  }
  activityHeader: {
    headline: BorderToken
  }
  addFamilyMemberPage: {
    dobWarning: BorderToken
  }
  aiTab: BackgroundToken &
    TextToken & {
      aiTabPanel: BackgroundToken
    }
  alertBanner: {
    danger: BackgroundToken & TextToken
    success: BackgroundToken & TextToken
    warning: BackgroundToken & TextToken
    info: BackgroundToken & TextToken
    notification: BackgroundToken & TextToken
    calm: BackgroundToken & TextToken
  }
  assignmentsListItem: {
    success: BackgroundToken & TextToken
    default: BackgroundToken & TextToken
    rightActionHover: BackgroundToken
    rightActionPressed: BackgroundToken
  }
  avatar: {
    tealTheme: BackgroundToken & TextToken
    greenTheme: BackgroundToken & TextToken
    goldTheme: BackgroundToken & TextToken
    peachTheme: BackgroundToken & TextToken
    periwinkleTheme: BackgroundToken & TextToken
  }
  fieldBadge: {
    periwinkleTheme: BackgroundToken & TextToken
  }
  backButton: {
    defaultIcon: FillToken
  }
  banner: {
    outlineButton: BorderToken
  }
  biometrics: BackgroundToken
  bulletedList: FillToken
  calendarCard: {
    appointmentTodayText: TextToken
  }
  chatBubble: BackgroundToken &
    BorderToken & {
      outOfOffice: BackgroundToken & TextToken
    }
  chatHeader: {
    textBadge: BackgroundToken & TextToken
  }
  chatInput: BorderToken
  chatMenu: {
    active: BackgroundToken
    hover: BackgroundToken
  }
  checkIconListItem: {
    checkmark: FillToken
  }
  coachMark: BackgroundToken & {
    arrow: BorderToken
  }
  connectionIndicator: {
    signalHigh: BackgroundToken
    signalMedium: BackgroundToken
    signalLow: BackgroundToken
    signalDefault: BackgroundToken
  }
  countryIcon: BackgroundToken & TextToken
  coverageMessagingModal: FillToken
  dateBlock: BorderToken & TextToken & BackgroundToken
  eligibilityWarningBanner: BackgroundToken
  feedbackStarIcon: {
    default: FillToken &
      BorderToken & {
        hover: FillToken & BorderToken
        highlighted: FillToken & BorderToken
      }
    periwinkle: FillToken &
      BorderToken & {
        hover: FillToken & BorderToken
        highlighted: FillToken & BorderToken
      }
  }
  focusBoxShadow: {
    innerBox: FillToken
    outerBox: FillToken
  }
  formBody: {
    submitButton: BorderToken & {
      boxShadow: FillToken
    }
  }
  formErrorBanner: BackgroundToken
  genericHeader: BackgroundToken
  genericListItem: {
    badge: BackgroundToken
  }
  homeToolbar: {
    toolbarIcon: FillToken
  }
  indicator: TextToken
  inlineInfoBanner: BackgroundToken &
    TextToken & {
      infoIcon: FillToken
    }
  inlineWarningBanner: BackgroundToken &
    BorderToken &
    TextToken & {
      warningIcon: FillToken
    }
  inputAccessory: BorderToken
  integratedBenefitsEntryCard: {
    fencepost: BorderToken
  }
  integratedBenefitsLabeledItemGroup: {
    itemGroup: {
      boxShadow: FillToken
      shadow: FillToken
    }
  }
  internationalCareNavigatorContactInfo: {
    tooltip: TextToken
  }
  lockedField: {
    lockIcon: FillToken
  }
  lessonPreview: {
    activityBadge: BackgroundToken & TextToken
    progressBar: FillToken
  }
  LessonPreviewMaximized: BackgroundToken
  lessonThumbnail: {
    iconDisplay: BackgroundToken
    disabledIcon: FillToken
    progressBar: FillToken
  }
  locationSearchNavigation: {
    highlightColor: BackgroundToken
    separatorColor: BackgroundToken
  }
  messageAttachment: {
    innerContainer: BackgroundToken
  }
  messageItem: {
    chatBubbleReceiver: BackgroundToken & BorderToken
    chatBubbleSender: BackgroundToken & BorderToken
    icon: {
      receiver: FillToken
    }
  }
  modal: BackgroundToken & {
    modalCardShadow: BackgroundToken
    scrollableModalShadow: BackgroundToken
    sidePanelShadow: BackgroundToken
    animationShadow: BackgroundToken
  }
  newMessageIndicator: {
    arrow: FillToken
  }
  nextVideo: BackgroundToken
  notesBadge: {
    available: BackgroundToken & TextToken
    processing: BackgroundToken & TextToken
    unavailable: BackgroundToken & TextToken
  }
  notificationChannel: {
    warningContainer: BackgroundToken
    warningIcon: FillToken
  }
  notificationsOverview: {
    notificationIcon: FillToken
  }
  passwordChecklist: {
    checklistItemSuccess: FillToken & TextToken
    checklistBullet: FillToken
  }
  platformEngagementQuarterlyDetails: TextToken
  privacyScreen: {
    textContainer: BackgroundToken
  }
  profileFooter: {
    logOut: TextToken
  }
  profilePageCard: BorderToken
  progressBar: FillToken & BackgroundToken
  progressBarSecondary: FillToken & BackgroundToken
  providerCalendar: {
    event: {
      bookable: BackgroundToken & BorderToken
      bookableHover: BackgroundToken & BorderToken
      initialSession: BackgroundToken & BorderToken & TextToken
      recurringSession: BackgroundToken & BorderToken & TextToken
      mirror: BackgroundToken & BorderToken
      default: BackgroundToken & BorderToken
      ooo: BackgroundToken & BorderToken
    }
    badge: {
      ooo: BackgroundToken & TextToken
      conflict: BackgroundToken & TextToken
    }
  }
  providerCredentials: {
    checkIcon: FillToken
  }
  providerLanguagesSpoken: {
    bulletPoint: BackgroundToken
    textBadge: TextToken
  }
  providerBadges: {
    bctFellowshipBadge: {
      shootingStarIcon: FillToken
    }
    overflowBadge: {
      open: FillToken
      closed: FillToken
    }
    preferenceMatchBadge: {
      checkIcon: FillToken
    }
    modalityBadge: BackgroundToken
    listItemBadge: BackgroundToken
  }
  providerBaseHeader: BorderToken
  providerSpecializations: {
    bulletPoint: BackgroundToken
  }
  providerWithNoPreferenceMatchModal: {
    noPreferenceMatchIcon: FillToken
  }
  recordingBadge: BackgroundToken & TextToken
  s4cLoadingIndicator: TextToken
  s4cHeader: BackgroundToken
  selectedBenefitItem: {
    badge: FillToken
  }
  selfHarmNotice: BorderToken
  sessionModality: {
    separator: FillToken
    modalityBullet: FillToken
  }
  sliderField: TextToken & {
    thumb: BorderToken & {
      dashedOutline: BackgroundToken
    }
    track: {
      filled: BackgroundToken
      unFilled: BackgroundToken
      minimumTint: BackgroundToken
      maximumTint: BackgroundToken
    }
    stepMarker: {
      filled: BackgroundToken
      unFilled: BackgroundToken
      active: BackgroundToken
    }
    floatingLabel: BorderToken & BackgroundToken & { shadow: FillToken }
  }
  multicolorSliderField: TextToken & {
    thumb: BorderToken & {
      dashedOutline: BackgroundToken
      shadow: FillToken
    }
    track: {
      filled: BackgroundToken
      unFilled: BackgroundToken
      minimumTint: BackgroundToken
      maximumTint: BackgroundToken
    }
    stepMarker: {
      filled: BackgroundToken
      unFilled: BackgroundToken
      active: BackgroundToken
    }
    floatingLabel: BorderToken & BackgroundToken & { shadow: FillToken }
  }
  triageSummary: {
    badge: FillToken & BackgroundToken
    line: {
      stroke: FillToken
    }
  }
  toast: {
    success: BackgroundToken
    info: BackgroundToken
    error: BackgroundToken
    warning: BackgroundToken
  }
  toolbar: BackgroundToken
  treatmentCard: BackgroundToken & {
    findCounsellorSection: {
      icon: FillToken
    }
  }
  unsupportedLanguageDisclaimer: TextToken
  wellnessCheckInResultsDomainScore: {
    low: {
      outerCircle: FillToken
      innerCircle: FillToken
    } & TextToken
    moderate: {
      outerCircle: FillToken
      innerCircle: FillToken
    } & TextToken
    high: {
      outerCircle: FillToken
      innerCircle: FillToken
    } & TextToken
    default: {
      outerCircle: FillToken
      innerCircle: FillToken
    } & TextToken
  }
  videoCallPreview: BackgroundToken & {
    cameraContainer: BackgroundToken
  }
  videoControlButton: {
    enabledColor: FillToken
    disabledColor: FillToken
    messengerDisabledColor: FillToken
    endButton: FillToken
  }
  videoLessonComplete: {
    check: FillToken
    completeText: TextToken
    videoTitleText: TextToken
  }
  videoSessionControls: {
    dark: BackgroundToken
    default: BackgroundToken
  }
  videoSessionLocalViewContainer: BackgroundToken
  videoSessionMiniViewContainer: BackgroundToken
  videoSessionParticipantViewContainer: BackgroundToken
  videoSessionParticipantView: {
    defaultView: BackgroundToken
    miniView: BackgroundToken
  }
  videoPlayer: BackgroundToken & {
    thumbTint: BackgroundToken
    minimumTrackTint: BackgroundToken
    maximumTrackTint: BackgroundToken
  }
  videoPlayerModal: BackgroundToken
  videoSession: {
    backgroundDark: BackgroundToken
  }
  weekItem: {
    activityBadge: BackgroundToken
    pressedBackground: BackgroundToken
  }
  welcomeSplashScreen: {
    backButton: FillToken
  }
  wellnessCheckInOverview: {
    bulletPoint: FillToken
  }
  wellnessToolkitEssentials: TextToken
  wordBubbleGradient: TextToken
  zoomEmptyView: BackgroundToken & TextToken
  zoomMuteButton: BackgroundToken & FillToken
  zoomParticipantStatus: {
    left: BackgroundToken & TextToken
    waiting: BackgroundToken & TextToken
    ready: BackgroundToken & TextToken
  }
  zoomSelfView: BackgroundToken & TextToken
  zoomVideoControls: {
    enabled: BackgroundToken
    disabled: BackgroundToken
    end: BackgroundToken
    icon: TextToken
  }
  zoomVideoSession: BackgroundToken
}

interface CustomIconTokens {
  activityIcon: {
    notepadBack: FillToken
    notepadPaper: FillToken
    notepadText: FillToken
    notepadSpiral: FillToken
    pencil: FillToken
    pencilFerrule: FillToken
    pencilEraser: FillToken
    pencilWood: FillToken
    pencilTip: FillToken
  }
  alarmIcon: {
    leftBulb: FillToken
    rightBulb: FillToken
    center: FillToken
    rightBase: FillToken
    leftBase: FillToken
  }
  appIcon: BackgroundToken & TextToken
  appleIcon: FillToken
  arrowIcon: FillToken
  avatarIcon: FillToken & {
    body: FillToken
    shadow: FillToken
  }
  bellIcon: {
    clipPath: FillToken
  }
  boltIcon: FillToken
  briefCaseIcon: {
    rect: FillToken
  }
  calendarBookingIcon: FillToken
  chevronIcon: FillToken
  checkMarkIcon: FillToken
  checkMarkInCircleIcon: FillToken
  closeCircleBorderedIcon: FillToken
  closeCircleIcon: FillToken
  creditCardIcon: FillToken
  essentialsIcon: FillToken
  eyeIcon: FillToken
  feedbackStarIcon: FillToken
  fingerprintIcon: FillToken
  linkIcon: {
    chain: FillToken
  }
  listIcon: FillToken
  liveChatMessagingIcon: FillToken
  liveMessagingIcon: BackgroundToken & TextToken
  lockPromptIcon: {
    shackle: FillToken
    lockBody: FillToken
    rightBody: FillToken
    keyHole: FillToken
  }
  logoutIcon: {
    rectangle: FillToken
    arrow: FillToken
  }
  menuIcon: FillToken
  messageIcon: {
    numberBadge: BorderToken
  }
  messagePromptIcon: {
    backgroundMessageLight: FillToken
    backgroundMessageDark: FillToken
    foregroundMessage: FillToken
  }
  messagingIcon: BorderToken
  notificationIcon: FillToken
  numberBadgeIcon: BackgroundToken & BorderToken
  phoneIcon: FillToken
  progressIcon: FillToken
  scoringIcon: {
    negative: FillToken & {
      arrow: FillToken
      stroke: FillToken
    }
    positive: FillToken & {
      arrow: FillToken
      stroke: FillToken
    }
    neutral: FillToken & {
      arrow: FillToken
      stroke: FillToken
    }
  }
  searchIcon: FillToken
  selfCareIcon: FillToken & {
    clipPath: FillToken
  }
  sendIcon: FillToken
  sessionIcon: FillToken
  sparklesAvatarIcon: FillToken
  sparklesIcon: {
    clipPath: FillToken
  }
  starRatingIcon: FillToken
  timeIcon: BackgroundToken & {
    clockHandBackground: FillToken
    clockHandForeground: FillToken
  }
  warningIcon: FillToken
  xIcon: FillToken
  xIconRound: BorderToken & FillToken
}

export interface ThemeColors {
  // Text tokens
  textPrimary: string
  textSecondary: string
  textInactive: string
  textActive: string
  textSuccess: string
  textWarning: string
  textError: string
  textButton: string
  textInverse: string
  textLightBackground: string
  textDarkBackground: string

  // Link tokens
  linkDefault: string
  linkHover: string
  linkInactive: string

  // Background tokens
  backgroundPrimary: string
  backgroundSecondary: string
  backgroundSection: string
  backgroundHighlightPrimary: string
  backgroundHighlightSecondary: string
  backgroundHighlightTertiary: string
  backgroundTransparent: string
  backgroundActive: string
  backgroundHover: string
  backgroundPressed: string
  backgroundLoading: string
  backgroundFocus: string
  backgroundInactive: string
  backgroundSuccess: string
  backgroundWarning: string
  backgroundError: string
  backgroundTooltip: string
  backgroundScrim: string
  backgroundElement: string
  backgroundIcon: string
  backgroundHeaderPeriwinkle: string
  backgroundNavHeader: string
  backgroundHeaderPrimary: string
  backgroundPhoto: string
  backgroundTopMatch: string
  backgroundDarkOverlay: string

  // Border tokens
  borderDefault: string
  borderActive: string
  borderHover: string
  borderFocus: string
  borderInput: string
  borderSuccess: string
  borderWarning: string
  borderError: string
  borderOverlay: string
  borderTopMatch: string
  borderContrast: string

  // Shadow tokens
  shadowLow: string
  outlineMedium: string
  outlineHigh: string

  // Icon tokens
  iconDefault: string
  iconActive: string
  iconInactive: string
  iconSuccess: string
  iconWarning: string
  iconError: string
  iconAccent: string
  iconInverse: string
  iconLightBackground: string
  iconDarkBackground: string

  // Divider tokens
  dividerPrimary: string
  dividerSecondary: string

  // Primary button tokens
  primaryButtonBackgroundDefault: string
  primaryButtonBackgroundHover: string
  primaryButtonBackgroundPressed: string
  primaryButtonBackgroundInactive: string
  primaryButtonBackgroundLoading: string
  primaryButtonBackgroundFocus: string
  primaryButtonTextActive: string
  primaryButtonTextInactive: string

  // Primary button varient tokens
  primaryButtonPeriwinkleBackgroundDefault: string
  primaryButtonPeriwinkleBackgroundHover: string
  primaryButtonPeriwinkleBackgroundPressed: string
  primaryButtonPeriwinkleBackgroundInactive: string
  primaryButtonPeriwinkleBackgroundLoading: string
  primaryButtonPeriwinkleBackgroundFocus: string

  // Secondary button tokens
  secondaryButtonBackgroundDefault: string
  secondaryButtonBackgroundHover: string
  secondaryButtonBackgroundPressed: string
  secondaryButtonBackgroundInactive: string
  secondaryButtonBackgroundFocus: string
  secondaryButtonOutlineDefault: string
  secondaryButtonOutlineInactive: string
  secondaryButtonTextActive: string
  secondaryButtonTextInactive: string

  // Secondary button variant tokens
  secondaryButtonInverseBackgroundDefault: string
  secondaryButtonInverseBackgroundHover: string
  secondaryButtonInverseBackgroundPressed: string
  secondaryButtonInverseBackgroundFocus: string
  secondaryButtonInverseOutlineDefault: string
  secondaryButtonInverseOutlinePressed: string
  secondaryButtonInverseOutlineInactive: string
  secondaryButtonInverseTextActive: string
  secondaryButtonInverseTextInactive: string
  secondaryButtonBlackBackgroundDefault: string
  secondaryButtonBlackOutlineDefault: string
  secondaryButtonBlackTextActive: string
  secondaryButtonPeriwinkleOutlineDefault: string
  secondaryButtonPeriwinkleTextActive: string
  secondaryButtonPeriwinkleBackgroundHover: string
  secondaryButtonPeriwinkleBackgroundPressed: string
  secondaryButtonPeriwinkleBackgroundLoading: string
  secondaryButtonPeriwinkleBackgroundFocus: string

  // Tertiary button (icon button) tokens
  tertiaryButtonBackgroundDefault: string
  tertiaryButtonBackgroundHover: string
  tertiaryButtonBackgroundPressed: string
  tertiaryButtonBackgroundInactive: string
  tertiaryButtonBackgroundFocus: string
  tertiaryButtonTextActive: string
  tertiaryButtonTextInactive: string

  // Secondary Tertiary button (icon button) tokens
  secondaryTertiaryButtonBackgroundDefault: string
  secondaryTertiaryButtonBackgroundHover: string
  secondaryTertiaryButtonBackgroundPressed: string
  secondaryTertiaryButtonBackgroundInactive: string
  secondaryTertiaryButtonBackgroundFocus: string
  secondaryTertiaryButtonTextActive: string
  secondaryTertiaryButtonTextInactive: string

  // Word cloud button tokens
  wordCloudButtonBackgroundDefault: string
  wordCloudButtonBackgroundSelected: string
  wordCloudPeriwinkleButtonBackgroundSelected: string
  wordCloudButtonOutlineDefault: string
  wordCloudButtonOutlineHover: string
  wordCloudPeriwinkleButtonOutlineHover: string
  wordCloudButtonOutlinePressed: string
  wordCloudPeriwinkleButtonOutlinePressed: string
  wordCloudButtonOutlineInactive: string
  wordCloudButtonOutlineSelected: string
  wordCloudPeriwinkleButtonOutlineSelected: string
  wordCloudButtonTextActive: string
  wordCloudButtonTextInactive: string
  wordCloudButtonTextPressed: string
  wordCloudPeriwinkleButtonTextPressed: string
  wordCloudButtonTextSelected: string
  wordCloudPeriwinkleButtonTextSelected: string

  // Input field tokens
  inputOutlineDefault: string
  inputOutlineSelected: string
  inputOutlineInactive: string
  inputOutlineHover: string
  inputOutlineFocus: string
  inputOutlineError: string
  inputOutlineTriageDefault: string
  inputTextDefault: string
  inputTextPlaceholder: string
  inputTextActive: string
  inputTextInactive: string
  inputTextSuccess: string
  inputTextWarning: string
  inputTextError: string
  inputBackgroundDefault: string

  // Checkbox tokens
  checkboxBackgroundPrimary: string
  checkboxBackgroundSelected: string
  checkboxBackgroundPressed: string
  checkboxBackgroundSelectedInactive: string
  checkboxOutlineDefault: string
  checkboxOutlineInactive: string
  checkboxOutlineSelected: string
  checkboxOutlineSelectedInactive: string
  checkboxCheckSelected: string

  // Radio tokens
  radioBackgroundPrimary: string
  radioBackgroundSelected: string
  radioBackgroundSelectedInactive: string
  radioTextDefault: string
  radioTextHighlight: string
  radioTextSelected: string
  radioOutlineDefault: string
  radioOutlineInactive: string
  radioOutlineSelected: string
  radioOutlineSelectedInactive: string
  radioOutlineFocused: string

  // Radio with outline tokens
  radioWithOutlineBackgroundHighlight: string
  radioWithOutlinePrimaryTextHighlight: string
  radioWithOutlineBackgroundSelected: string
  radioWithOutlineBorderSelected: string
  radioWithOutlinePrimaryTextSelected: string
  radioWithOutlineSecondaryTextSelected: string
  radioWithOutlineBorderHovered: string
  radioWithOutlinePrimaryTextInactive: string
  radioWithOutlineSecondaryTextInactive: string
  radioWithOutlineBorderInactive: string
  radioWithOutlineBackgroundDefault: string
  radioWithOutlinePrimaryTextDefault: string
  radioWithOutlineSecondaryTextDefault: string
  radioWithOutlineBorderDefault: string

  // Cards
  cardBackgroundPrimary: string
  cardOutlinePrimary: string
  cardBackgroundSecondary: string
  cardOutlineSecondary: string
  cardOutlineInactive: string

  // Progress indicator
  progressIndicator: string
  progressIndicatorBackgroundTop: string
  progressIndicatorBackgroundBottom: string

  // ScrollView Refresher
  scrollViewRefresherLoadingIcon: string

  // Gradient Colors
  gradientBubbleStart: string
  gradientBubbleEnd: string
  gradientBoxStart: string
  gradientBoxEnd: string
  gradientBoxText: string

  // Page background gradient tokens
  backgroundGradientFirst: string
  backgroundGradientSecond: string
  backgroundGradientThird: string
  backgroundGradientFourth: string

  // Custom component tokens
  components: CustomComponents
  icons: CustomIconTokens
}

export const lightThemeColors: ThemeColors = {
  // Text
  textPrimary: baseColors.oatmeal_60,
  textSecondary: baseColors.oatmeal_50,
  textInactive: baseColors.oatmeal_40,
  textActive: baseColors.teal_40,
  textSuccess: baseColors.green_50,
  textWarning: baseColors.gold_50,
  textError: baseColors.red_50,
  textButton: baseColors.white,
  textInverse: baseColors.white,
  textLightBackground: baseColors.oatmeal_60,
  textDarkBackground: baseColors.white,

  // Link
  linkDefault: baseColors.teal_40,
  linkHover: baseColors.teal_50,
  linkInactive: baseColors.oatmeal_40,

  // Background
  backgroundPrimary: baseColors.white,
  backgroundSecondary: baseColors.oatmeal_10,
  backgroundSection: baseColors.oatmeal_20,
  backgroundHighlightPrimary: baseColors.teal_10,
  backgroundHighlightTertiary: baseColors.peach_10,
  backgroundHighlightSecondary: baseColors.peri_10,
  backgroundTransparent: baseColors.transparent,
  backgroundActive: baseColors.teal_40,
  backgroundHover: baseColors.teal_50,
  backgroundPressed: baseColors.teal_60,
  backgroundLoading: baseColors.teal_60,
  backgroundFocus: baseColors.oatmeal_20,
  backgroundInactive: baseColors.oatmeal_20,
  backgroundSuccess: baseColors.green_10,
  backgroundWarning: baseColors.gold_10,
  backgroundError: baseColors.red_10,
  backgroundTooltip: baseColors.black,
  backgroundScrim: baseColors.scrim,
  backgroundElement: baseColors.oatmeal_15,
  backgroundIcon: baseColors.oatmeal_20,
  backgroundHeaderPeriwinkle: baseColors.peri_10,
  backgroundHeaderPrimary: baseColors.transparent,
  backgroundNavHeader: baseColors.white,
  backgroundPhoto: baseColors.transparent,
  backgroundTopMatch: baseColors.teal_20,
  backgroundDarkOverlay: baseColors.black,

  // Border
  borderDefault: baseColors.oatmeal_30,
  borderActive: baseColors.oatmeal_50,
  borderHover: baseColors.teal_40,
  borderFocus: baseColors.teal_40,
  borderInput: baseColors.teal_40,
  borderSuccess: baseColors.green_50,
  borderWarning: baseColors.gold_50,
  borderError: baseColors.red_50,
  borderOverlay: baseColors.oatmeal_20,
  borderTopMatch: baseColors.teal_20,
  borderContrast: baseColors.white,

  // Shadows
  shadowLow: baseColors.shadow,
  outlineMedium: baseColors.shadow,
  outlineHigh: baseColors.shadow,

  // Icon
  iconDefault: baseColors.oatmeal_50,
  iconActive: baseColors.teal_40,
  iconInactive: baseColors.oatmeal_40,
  iconSuccess: baseColors.green_50,
  iconWarning: baseColors.gold_50,
  iconError: baseColors.red_50,
  iconAccent: baseColors.peach_30,
  iconInverse: baseColors.white,
  iconLightBackground: baseColors.oatmeal_50,
  iconDarkBackground: baseColors.white,

  // Divider
  dividerPrimary: baseColors.oatmeal_20,
  dividerSecondary: baseColors.oatmeal_30,

  // Primary button
  primaryButtonBackgroundDefault: baseColors.teal_40,
  primaryButtonBackgroundHover: baseColors.teal_50,
  primaryButtonBackgroundPressed: baseColors.teal_60,
  primaryButtonBackgroundInactive: baseColors.oatmeal_20,
  primaryButtonBackgroundLoading: baseColors.teal_60,
  primaryButtonBackgroundFocus: baseColors.teal_50,
  primaryButtonTextActive: baseColors.white,
  primaryButtonTextInactive: baseColors.oatmeal_40,

  // Primary button variants
  primaryButtonPeriwinkleBackgroundDefault: baseColors.peri_40,
  primaryButtonPeriwinkleBackgroundHover: baseColors.peri_50,
  primaryButtonPeriwinkleBackgroundPressed: baseColors.peri_60,
  primaryButtonPeriwinkleBackgroundInactive: baseColors.oatmeal_20,
  primaryButtonPeriwinkleBackgroundLoading: baseColors.peri_60,
  primaryButtonPeriwinkleBackgroundFocus: baseColors.peri_50,

  // Secondary button
  secondaryButtonBackgroundDefault: baseColors.transparent,
  secondaryButtonBackgroundHover: baseColors.teal_10,
  secondaryButtonBackgroundPressed: baseColors.teal_20,
  secondaryButtonBackgroundInactive: baseColors.transparent,
  secondaryButtonBackgroundFocus: baseColors.teal_10,
  secondaryButtonOutlineDefault: baseColors.teal_40, // confirm this change
  secondaryButtonOutlineInactive: baseColors.oatmeal_30,
  secondaryButtonTextActive: baseColors.teal_40, // confirm this change
  secondaryButtonTextInactive: baseColors.oatmeal_40,

  // Secondary button variants
  secondaryButtonInverseBackgroundDefault: baseColors.transparent,
  secondaryButtonInverseBackgroundHover: baseColors.teal_60,
  secondaryButtonInverseBackgroundPressed: baseColors.teal_60,
  secondaryButtonInverseBackgroundFocus: baseColors.teal_60,
  secondaryButtonInverseOutlineDefault: baseColors.teal_20,
  secondaryButtonInverseOutlinePressed: baseColors.teal_40,
  secondaryButtonInverseOutlineInactive: baseColors.oatmeal_40,
  secondaryButtonInverseTextActive: baseColors.teal_20,
  secondaryButtonInverseTextInactive: baseColors.oatmeal_40,
  secondaryButtonBlackBackgroundDefault: baseColors.white,
  secondaryButtonBlackOutlineDefault: baseColors.black,
  secondaryButtonBlackTextActive: baseColors.black,
  secondaryButtonPeriwinkleOutlineDefault: baseColors.peri_50,
  secondaryButtonPeriwinkleTextActive: baseColors.peri_50,
  secondaryButtonPeriwinkleBackgroundHover: baseColors.peri_10,
  secondaryButtonPeriwinkleBackgroundPressed: baseColors.peri_30,
  secondaryButtonPeriwinkleBackgroundLoading: baseColors.peri_10,
  secondaryButtonPeriwinkleBackgroundFocus: baseColors.peri_10,

  // Tertiary button (icon button)
  tertiaryButtonBackgroundDefault: baseColors.transparent,
  tertiaryButtonBackgroundHover: baseColors.oatmeal_20,
  tertiaryButtonBackgroundPressed: baseColors.oatmeal_30,
  tertiaryButtonBackgroundInactive: baseColors.transparent,
  tertiaryButtonBackgroundFocus: baseColors.oatmeal_20,
  tertiaryButtonTextActive: baseColors.oatmeal_50,
  tertiaryButtonTextInactive: baseColors.oatmeal_40,

  // Secondary Tertiary button (icon button) tokens
  secondaryTertiaryButtonBackgroundDefault: baseColors.oatmeal_20,
  secondaryTertiaryButtonBackgroundHover: baseColors.oatmeal_30,
  secondaryTertiaryButtonBackgroundPressed: baseColors.transparent,
  secondaryTertiaryButtonBackgroundInactive: baseColors.oatmeal_20,
  secondaryTertiaryButtonBackgroundFocus: baseColors.oatmeal_30,
  secondaryTertiaryButtonTextActive: baseColors.oatmeal_50,
  secondaryTertiaryButtonTextInactive: baseColors.oatmeal_40,

  // Word cloud button
  wordCloudButtonBackgroundDefault: baseColors.white,
  wordCloudButtonBackgroundSelected: baseColors.teal_10,
  wordCloudPeriwinkleButtonBackgroundSelected: baseColors.peri_10,
  wordCloudButtonOutlineDefault: baseColors.oatmeal_30,
  wordCloudButtonOutlineHover: baseColors.teal_40,
  wordCloudPeriwinkleButtonOutlineHover: baseColors.peri_40,
  wordCloudButtonOutlinePressed: baseColors.teal_40,
  wordCloudPeriwinkleButtonOutlinePressed: baseColors.peri_40,
  wordCloudButtonOutlineInactive: baseColors.oatmeal_30,
  wordCloudButtonOutlineSelected: baseColors.teal_40,
  wordCloudPeriwinkleButtonOutlineSelected: baseColors.peri_40,
  wordCloudButtonTextActive: baseColors.oatmeal_50,
  wordCloudButtonTextInactive: baseColors.oatmeal_40,
  wordCloudButtonTextPressed: baseColors.teal_50,
  wordCloudPeriwinkleButtonTextPressed: baseColors.peri_50,
  wordCloudButtonTextSelected: baseColors.teal_50,
  wordCloudPeriwinkleButtonTextSelected: baseColors.peri_50,

  // Input field
  inputOutlineDefault: baseColors.oatmeal_50,
  inputOutlineSelected: baseColors.teal_20,
  inputOutlineInactive: baseColors.oatmeal_20,
  inputOutlineHover: baseColors.teal_40,
  inputOutlineFocus: baseColors.teal_40,
  inputOutlineError: baseColors.red_50,
  inputOutlineTriageDefault: baseColors.oatmeal_30,
  inputTextDefault: baseColors.oatmeal_50,
  inputTextPlaceholder: baseColors.oatmeal_40,
  inputTextActive: baseColors.oatmeal_60,
  inputTextInactive: baseColors.oatmeal_40,
  inputTextSuccess: baseColors.green_50,
  inputTextWarning: baseColors.gold_50,
  inputTextError: baseColors.red_50,
  inputBackgroundDefault: baseColors.white,

  // Checkbox
  checkboxBackgroundPrimary: baseColors.white,
  checkboxBackgroundSelected: baseColors.teal_40,
  checkboxBackgroundPressed: baseColors.teal_40,
  checkboxBackgroundSelectedInactive: baseColors.oatmeal_40,
  checkboxOutlineDefault: baseColors.oatmeal_40,
  checkboxOutlineInactive: baseColors.oatmeal_30,
  checkboxOutlineSelected: baseColors.teal_40,
  checkboxOutlineSelectedInactive: baseColors.oatmeal_40,
  checkboxCheckSelected: baseColors.white,

  // Radio
  radioBackgroundPrimary: baseColors.white,
  radioBackgroundSelected: baseColors.teal_40,
  radioBackgroundSelectedInactive: baseColors.teal_20,
  radioTextDefault: baseColors.oatmeal_50,
  radioTextHighlight: baseColors.black,
  radioTextSelected: baseColors.teal_50,
  radioOutlineDefault: baseColors.oatmeal_40,
  radioOutlineInactive: baseColors.oatmeal_30,
  radioOutlineSelected: baseColors.teal_40,
  radioOutlineSelectedInactive: baseColors.teal_20,
  radioOutlineFocused: baseColors.teal_40,

  // Radio with outline
  radioWithOutlineBackgroundHighlight: baseColors.oatmeal_15,
  radioWithOutlinePrimaryTextHighlight: baseColors.teal_60,
  radioWithOutlineBackgroundSelected: baseColors.teal_10,
  radioWithOutlineBorderSelected: baseColors.teal_40,
  radioWithOutlinePrimaryTextSelected: baseColors.teal_50,
  radioWithOutlineSecondaryTextSelected: baseColors.teal_40,
  radioWithOutlineBorderHovered: baseColors.teal_40,
  radioWithOutlinePrimaryTextInactive: baseColors.oatmeal_40,
  radioWithOutlineSecondaryTextInactive: baseColors.oatmeal_30,
  radioWithOutlineBackgroundDefault: baseColors.white,
  radioWithOutlineBorderInactive: baseColors.teal_20,
  radioWithOutlinePrimaryTextDefault: baseColors.oatmeal_60,
  radioWithOutlineSecondaryTextDefault: baseColors.oatmeal_50,
  radioWithOutlineBorderDefault: baseColors.oatmeal_30,

  // Cards
  cardBackgroundPrimary: baseColors.white,
  cardOutlinePrimary: baseColors.oatmeal_30,
  cardBackgroundSecondary: baseColors.peri_10,
  cardOutlineSecondary: baseColors.oatmeal_30,
  cardOutlineInactive: baseColors.oatmeal_20,

  // Progress indicator
  progressIndicator: baseColors.peach_50,
  progressIndicatorBackgroundTop: baseColors.oatmeal_30,
  progressIndicatorBackgroundBottom: baseColors.oatmeal_20,

  // ScrollView Refresher
  scrollViewRefresherLoadingIcon: baseColors.teal_30,

  // Gradient Tokens
  gradientBubbleStart: baseColors.teal_10,
  gradientBubbleEnd: baseColors.teal_0,
  gradientBoxStart: baseColors.gradient_box_purple,
  gradientBoxEnd: baseColors.gradient_box_teal,
  gradientBoxText: baseColors.oatmeal_60,

  // Page background gradient tokens
  backgroundGradientFirst: baseColors.teal_10,
  backgroundGradientSecond: baseColors.gradient_box_teal,
  backgroundGradientThird: baseColors.gradient_box_purple,
  backgroundGradientFourth: baseColors.white,

  // Custom component tokens
  components: {
    activityBadge: {
      background: baseColors.peri_50,
      text: baseColors.white,
    },
    activityBadgeTeen: {
      background: baseColors.peri_20,
      text: baseColors.peri_60,
    },
    activityCard: {
      activityBadgeWarn: {
        background: baseColors.gold_20,
        text: baseColors.gold_60,
      },
    },
    activityHeader: {
      headline: {
        border: baseColors.teal_30,
      },
    },
    addFamilyMemberPage: {
      dobWarning: {
        border: baseColors.gold_40,
      },
    },
    aiTab: {
      background: baseColors.peri_50,
      text: baseColors.white,
      aiTabPanel: {
        background: baseColors.peri_10,
      },
    },
    alertBanner: {
      danger: { background: baseColors.red_20, text: baseColors.red_60 },
      success: { background: baseColors.green_20, text: baseColors.green_60 },
      warning: { background: baseColors.gold_20, text: baseColors.gold_60 },
      info: { background: baseColors.peri_20, text: baseColors.oatmeal_60 },
      notification: { background: baseColors.red_50, text: baseColors.white },
      calm: { background: baseColors.teal_60, text: baseColors.white },
    },
    assignmentsListItem: {
      success: { background: baseColors.green_10, text: baseColors.green_50 },
      default: { background: baseColors.gold_10, text: baseColors.gold_50 },
      rightActionHover: { background: baseColors.oatmeal_20 },
      rightActionPressed: { background: baseColors.oatmeal_30 },
    },
    avatar: {
      tealTheme: { background: baseColors.teal_10, text: baseColors.teal_40 },
      greenTheme: { background: baseColors.green_10, text: baseColors.green_40 },
      goldTheme: { background: baseColors.gold_10, text: baseColors.gold_40 },
      peachTheme: { background: baseColors.peach_10, text: baseColors.peach_40 },
      periwinkleTheme: { background: baseColors.peri_10, text: baseColors.peri_40 },
    },
    fieldBadge: {
      periwinkleTheme: { background: baseColors.peri_10, text: baseColors.peri_40 },
    },
    backButton: {
      defaultIcon: { fill: baseColors.oatmeal_60 },
    },
    banner: {
      outlineButton: {
        border: baseColors.white,
      },
    },
    biometrics: { background: baseColors.oatmeal_15 },
    bulletedList: {
      fill: baseColors.oatmeal_50,
    },
    calendarCard: {
      appointmentTodayText: {
        text: baseColors.peri_40,
      },
    },
    chatBubble: {
      background: baseColors.white,
      border: baseColors.oatmeal_20,
      outOfOffice: {
        background: baseColors.teal_40,
        text: baseColors.white,
      },
    },
    chatHeader: {
      textBadge: {
        background: baseColors.red_10,
        text: baseColors.red_50,
      },
    },
    chatInput: {
      border: baseColors.white,
    },
    chatMenu: {
      active: { background: baseColors.oatmeal_30 },
      hover: { background: baseColors.oatmeal_20 },
    },
    checkIconListItem: {
      checkmark: { fill: baseColors.peach_40 },
    },
    coachMark: {
      background: baseColors.teal_60,
      arrow: {
        border: baseColors.teal_60,
      },
    },
    connectionIndicator: {
      signalHigh: { background: baseColors.green_40 },
      signalMedium: { background: baseColors.gold_40 },
      signalLow: { background: baseColors.red_50 },
      signalDefault: { background: baseColors.oatmeal_30 },
    },
    countryIcon: {
      background: baseColors.green_20,
      text: baseColors.green_60,
    },
    coverageMessagingModal: {
      fill: baseColors.peach_40,
    },
    dateBlock: {
      border: baseColors.teal_40,
      text: baseColors.teal_40,
      background: baseColors.teal_10,
    },
    eligibilityWarningBanner: {
      background: baseColors.gold_10,
    },
    feedbackStarIcon: {
      default: {
        fill: baseColors.white,
        border: baseColors.oatmeal_40,
        hover: {
          fill: baseColors.white,
          border: baseColors.teal_40,
        },
        highlighted: {
          fill: baseColors.teal_40,
          border: baseColors.teal_40,
        },
      },
      periwinkle: {
        fill: baseColors.white,
        border: baseColors.peri_40,
        hover: {
          fill: baseColors.peri_40,
          border: baseColors.peri_40,
        },
        highlighted: {
          fill: baseColors.peri_40,
          border: baseColors.peri_40,
        },
      },
    },
    formBody: {
      submitButton: {
        border: baseColors.oatmeal_20,
        boxShadow: { fill: baseColors.oatmeal_50 },
      },
    },
    formErrorBanner: {
      background: baseColors.red_50,
    },
    focusBoxShadow: {
      innerBox: { fill: baseColors.white },
      outerBox: { fill: baseColors.teal_40 },
    },
    genericHeader: {
      background: baseColors.teal_60,
    },
    genericListItem: {
      badge: { background: baseColors.peri_40 },
    },
    homeToolbar: {
      toolbarIcon: { fill: baseColors.white },
    },
    indicator: {
      text: baseColors.gold_50,
    },
    inlineInfoBanner: {
      background: baseColors.peri_10,
      text: baseColors.oatmeal_60,
      infoIcon: {
        fill: baseColors.oatmeal_50,
      },
    },
    inlineWarningBanner: {
      background: baseColors.gold_10,
      border: baseColors.gold_60,
      text: baseColors.gold_60,
      warningIcon: {
        fill: baseColors.gold_60,
      },
    },
    inputAccessory: {
      border: baseColors.oatmeal_20,
    },
    integratedBenefitsEntryCard: {
      fencepost: {
        border: baseColors.oatmeal_20,
      },
    },
    integratedBenefitsLabeledItemGroup: {
      itemGroup: {
        boxShadow: { fill: 'rgba(0, 0, 0, 0.1)' },
        shadow: { fill: baseColors.black },
      },
    },
    internationalCareNavigatorContactInfo: {
      tooltip: { text: baseColors.teal_30 },
    },
    lockedField: {
      lockIcon: {
        fill: baseColors.oatmeal_60,
      },
    },
    lessonPreview: {
      activityBadge: {
        background: baseColors.gold_20,
        text: baseColors.gold_60,
      },
      progressBar: {
        fill: baseColors.teal_40,
      },
    },
    LessonPreviewMaximized: {
      background: baseColors.black,
    },
    lessonThumbnail: {
      iconDisplay: {
        background: baseColors.teal_20,
      },
      disabledIcon: {
        fill: baseColors.teal_30,
      },
      progressBar: {
        fill: baseColors.teal_40,
      },
    },
    locationSearchNavigation: {
      highlightColor: { background: '#F0F0F0' },
      separatorColor: { background: '#C8C7CC' },
    },
    messageAttachment: {
      innerContainer: {
        background: baseColors.teal_50,
      },
    },
    messageItem: {
      chatBubbleReceiver: {
        background: baseColors.white,
        border: baseColors.oatmeal_30,
      },
      chatBubbleSender: {
        background: baseColors.teal_50,
        border: baseColors.teal_50,
      },
      icon: {
        receiver: { fill: baseColors.black },
      },
    },
    modal: {
      background: 'rgba(65, 68, 78, 0.8)', // FIXME: shadow80 equivalent
      modalCardShadow: { background: baseColors.black },
      scrollableModalShadow: { background: baseColors.oatmeal_10 },
      sidePanelShadow: { background: 'rgba(0, 0, 0, 0.1)' }, // FIXME: shadow10 equivalent
      animationShadow: { background: 'rgba(0, 0, 0, 0.4)' }, // FIXME: shadow40 equivalent
    },
    newMessageIndicator: {
      arrow: { fill: baseColors.oatmeal_60 },
    },
    nextVideo: {
      background: baseColors.black,
    },
    notesBadge: {
      available: {
        background: baseColors.peri_10,
        text: baseColors.peri_50,
      },
      processing: {
        background: baseColors.teal_10,
        text: baseColors.teal_50,
      },
      unavailable: {
        background: baseColors.oatmeal_15,
        text: baseColors.oatmeal_50,
      },
    },
    notificationChannel: {
      warningContainer: {
        background: baseColors.gold_50,
      },
      warningIcon: {
        fill: baseColors.white,
      },
    },
    notificationsOverview: {
      notificationIcon: {
        fill: baseColors.teal_60,
      },
    },
    passwordChecklist: {
      checklistItemSuccess: {
        fill: baseColors.green_60,
        text: baseColors.green_60,
      },
      checklistBullet: {
        fill: baseColors.oatmeal_40,
      },
    },
    platformEngagementQuarterlyDetails: {
      text: baseColors.teal_60,
    },
    privacyScreen: {
      textContainer: { background: baseColors.teal_60 },
    },
    profileFooter: {
      logOut: {
        text: baseColors.red_60,
      },
    },
    profilePageCard: { border: baseColors.oatmeal_20 },
    progressBar: {
      fill: baseColors.teal_20,
      background: baseColors.oatmeal_40,
    },
    progressBarSecondary: {
      fill: baseColors.teal_30,
      background: baseColors.teal_20,
    },
    providerCalendar: {
      event: {
        bookable: {
          background: baseColors.white,
          border: baseColors.oatmeal_30,
        },
        bookableHover: {
          background: baseColors.teal_10,
          border: baseColors.teal_40,
        },
        initialSession: {
          background: baseColors.green_20,
          border: baseColors.green_20,
          text: baseColors.green_70,
        },
        recurringSession: {
          background: baseColors.teal_20,
          border: baseColors.teal_20,
          text: baseColors.teal_60,
        },
        mirror: {
          background: baseColors.teal_10,
          border: baseColors.teal_40,
        },
        default: {
          background: baseColors.oatmeal_30,
          border: baseColors.oatmeal_30,
        },
        ooo: {
          background: baseColors.peri_20,
          border: baseColors.peri_20,
        },
      },
      badge: {
        ooo: {
          background: baseColors.peri_10,
          text: baseColors.peri_50,
        },
        conflict: {
          background: baseColors.gold_10,
          text: baseColors.gold_50,
        },
      },
    },
    providerCredentials: {
      checkIcon: { fill: baseColors.peach_40 },
    },
    providerLanguagesSpoken: {
      bulletPoint: {
        background: baseColors.peach_40,
      },
      textBadge: {
        text: baseColors.teal_50,
      },
    },
    providerBadges: {
      bctFellowshipBadge: {
        shootingStarIcon: { fill: baseColors.gold_30 },
      },
      overflowBadge: {
        open: { fill: baseColors.oatmeal_30 },
        closed: { fill: baseColors.oatmeal_20 },
      },
      preferenceMatchBadge: {
        checkIcon: { fill: baseColors.teal_30 },
      },
      modalityBadge: {
        background: baseColors.white,
      },
      listItemBadge: {
        background: baseColors.oatmeal_15,
      },
    },
    providerBaseHeader: {
      border: baseColors.white,
    },
    providerSpecializations: {
      bulletPoint: {
        background: baseColors.peach_40,
      },
    },
    providerWithNoPreferenceMatchModal: {
      noPreferenceMatchIcon: {
        fill: baseColors.oatmeal_60,
      },
    },
    recordingBadge: {
      background: baseColors.black,
      text: baseColors.red_40,
    },
    s4cLoadingIndicator: {
      text: baseColors.teal_60,
    },
    s4cHeader: {
      background: baseColors.teal_10,
    },
    selectedBenefitItem: {
      badge: { fill: baseColors.oatmeal_30 },
    },
    selfHarmNotice: {
      border: baseColors.gold_30,
    },
    sessionModality: {
      separator: { fill: baseColors.oatmeal_40 },
      modalityBullet: { fill: baseColors.peach_40 },
    },
    sliderField: {
      text: baseColors.teal_50,
      thumb: {
        border: baseColors.teal_40,
        dashedOutline: {
          background: baseColors.black,
        },
      },
      track: {
        filled: {
          background: baseColors.oatmeal_40,
        },
        unFilled: {
          background: baseColors.oatmeal_30,
        },
        minimumTint: {
          background: baseColors.teal_40,
        },
        maximumTint: {
          background: baseColors.oatmeal_30,
        },
      },
      stepMarker: {
        filled: {
          background: baseColors.teal_10,
        },
        active: {
          background: baseColors.white,
        },
        unFilled: {
          background: baseColors.oatmeal_40,
        },
      },
      floatingLabel: {
        border: baseColors.teal_40,
        background: baseColors.white,
        shadow: { fill: baseColors.shadow },
      },
    },
    multicolorSliderField: {
      text: baseColors.teal_50,
      thumb: {
        border: baseColors.white,
        dashedOutline: {
          background: baseColors.transparent,
        },
        shadow: { fill: '#00000029' },
      },
      track: {
        filled: {
          background: baseColors.white,
        },
        unFilled: {
          background: baseColors.white,
        },
        minimumTint: {
          background: baseColors.white,
        },
        maximumTint: {
          background: baseColors.white,
        },
      },
      stepMarker: {
        filled: {
          background: baseColors.transparent,
        },
        active: {
          background: baseColors.transparent,
        },
        unFilled: {
          background: baseColors.transparent,
        },
      },
      floatingLabel: {
        border: baseColors.transparent,
        background: baseColors.transparent,
        shadow: { fill: baseColors.shadow },
      },
    },
    triageSummary: {
      badge: {
        background: baseColors.teal_20,
        fill: baseColors.teal_50,
      },
      line: {
        stroke: { fill: baseColors.oatmeal_30 },
      },
    },
    toast: {
      success: { background: baseColors.green_50 },
      info: { background: baseColors.peri_40 },
      error: { background: baseColors.red_50 },
      warning: { background: baseColors.gold_50 },
    },
    toolbar: {
      background: baseColors.teal_60,
    },
    treatmentCard: {
      background: baseColors.white,
      findCounsellorSection: {
        icon: {
          fill: baseColors.teal_60,
        },
      },
    },
    unsupportedLanguageDisclaimer: {
      text: baseColors.teal_50,
    },
    wellnessCheckInResultsDomainScore: {
      low: {
        outerCircle: {
          fill: baseColors.red_10,
        },
        innerCircle: {
          fill: baseColors.red_30,
        },
        text: baseColors.red_40,
      },
      moderate: {
        outerCircle: {
          fill: baseColors.gold_10,
        },
        innerCircle: {
          fill: baseColors.gold_30,
        },
        text: baseColors.gold_40,
      },
      high: {
        outerCircle: {
          fill: baseColors.green_10,
        },
        innerCircle: {
          fill: baseColors.green_30,
        },
        text: baseColors.green_40,
      },
      default: {
        outerCircle: {
          fill: baseColors.oatmeal_10,
        },
        innerCircle: {
          fill: baseColors.oatmeal_30,
        },
        text: baseColors.oatmeal_40,
      },
    },
    videoCallPreview: {
      background: '#343A40', // FIXME: charcoal6 currently, need to find an oatmeal equivalent
      cameraContainer: {
        background: baseColors.black,
      },
    },
    videoControlButton: {
      enabledColor: {
        fill: 'rgba(255, 255, 255, 0.2)', // FIXME: highlight20 - need updated value
      },
      disabledColor: {
        fill: baseColors.teal_30,
      },
      messengerDisabledColor: {
        fill: 'rgba(255, 255, 255, 0.2)', // FIXME: highlight20 - need updated value
      },
      endButton: {
        fill: baseColors.red_40,
      },
    },
    videoLessonComplete: {
      check: { fill: baseColors.teal_20 },
      completeText: { text: baseColors.teal_20 },
      videoTitleText: { text: baseColors.white },
    },
    videoSessionControls: {
      dark: { background: 'rgba(0, 0, 0, 0.6)' },
      default: { background: 'rgba(0, 0, 0, 0.4)' },
    },
    videoSessionLocalViewContainer: {
      background: '#343A40',
    },
    videoSessionMiniViewContainer: {
      background: '#343A40',
    },
    videoSessionParticipantViewContainer: {
      background: baseColors.black,
    },
    videoSessionParticipantView: {
      defaultView: { background: baseColors.black },
      miniView: { background: baseColors.oatmeal_40 },
    },
    videoPlayer: {
      background: baseColors.black,
      thumbTint: { background: baseColors.white },
      minimumTrackTint: { background: baseColors.teal_20 },
      maximumTrackTint: { background: 'rgba(255, 255, 255, 0.8)' },
    },
    videoPlayerModal: {
      background: baseColors.black,
    },
    videoSession: {
      backgroundDark: { background: baseColors.black },
    },
    weekItem: {
      activityBadge: { background: baseColors.green_50 },
      pressedBackground: { background: baseColors.oatmeal_20 },
    },
    welcomeSplashScreen: {
      backButton: { fill: baseColors.teal_60 },
    },
    wellnessCheckInOverview: {
      bulletPoint: {
        fill: baseColors.teal_40,
      },
    },
    wellnessToolkitEssentials: {
      text: baseColors.teal_50,
    },
    wordBubbleGradient: {
      text: baseColors.oatmeal_50,
    },
    zoomEmptyView: {
      background: baseColors.oatmeal_50,
      text: baseColors.white,
    },
    zoomMuteButton: {
      background: baseColors.white,
      fill: baseColors.red_40,
    },
    zoomParticipantStatus: {
      left: {
        background: baseColors.oatmeal_40,
        text: baseColors.white,
      },
      waiting: {
        background: baseColors.oatmeal_50,
        text: baseColors.white,
      },
      ready: {
        background: baseColors.teal_40,
        text: baseColors.white,
      },
    },
    zoomSelfView: {
      background: baseColors.oatmeal_50,
      text: baseColors.white,
    },
    zoomVideoControls: {
      enabled: { background: baseColors.oatmeal_40 },
      disabled: { background: baseColors.teal_40 },
      icon: { text: baseColors.white },
      end: { background: baseColors.red_50 },
    },
    zoomVideoSession: {
      background: baseColors.white,
    },
  },
  icons: {
    activityIcon: {
      notepadBack: { fill: baseColors.gold_40 },
      notepadPaper: { fill: baseColors.oatmeal_20 },
      notepadText: { fill: baseColors.oatmeal_30 },
      notepadSpiral: { fill: baseColors.oatmeal_60 },
      pencil: { fill: baseColors.gold_40 },
      pencilFerrule: { fill: baseColors.oatmeal_60 },
      pencilEraser: { fill: '#C7574E' },
      pencilWood: { fill: '#E6C097' },
      pencilTip: { fill: baseColors.oatmeal_60 },
    },
    alarmIcon: {
      leftBulb: { fill: baseColors.red_50 },
      rightBulb: { fill: baseColors.red_50 },
      center: { fill: baseColors.red_60 },
      rightBase: { fill: baseColors.oatmeal_50 },
      leftBase: { fill: baseColors.oatmeal_60 },
    },
    appIcon: {
      background: baseColors.teal_60,
      text: baseColors.white,
    },
    appleIcon: {
      fill: baseColors.black,
    },
    arrowIcon: {
      fill: baseColors.oatmeal_10,
    },
    avatarIcon: {
      body: { fill: baseColors.teal_10 },
      shadow: { fill: baseColors.teal_20 },
      fill: baseColors.teal_30,
    },
    bellIcon: {
      clipPath: { fill: baseColors.white },
    },
    boltIcon: {
      fill: baseColors.oatmeal_60,
    },
    briefCaseIcon: {
      rect: { fill: baseColors.oatmeal_10 },
    },
    calendarBookingIcon: {
      fill: '#A19D99',
    },
    chevronIcon: {
      fill: baseColors.oatmeal_10,
    },
    checkMarkIcon: {
      fill: baseColors.teal_30,
    },
    checkMarkInCircleIcon: {
      fill: baseColors.green_50,
    },
    closeCircleBorderedIcon: {
      fill: baseColors.oatmeal_30,
    },
    closeCircleIcon: {
      fill: baseColors.oatmeal_60,
    },
    creditCardIcon: {
      fill: '#22485C',
    },
    essentialsIcon: {
      fill: baseColors.teal_60,
    },
    eyeIcon: {
      fill: baseColors.oatmeal_60,
    },
    feedbackStarIcon: {
      // Used in Mask fill
      fill: baseColors.white,
    },
    fingerprintIcon: {
      fill: baseColors.teal_20,
    },
    linkIcon: {
      chain: { fill: baseColors.teal_30 },
    },
    listIcon: {
      fill: baseColors.oatmeal_60,
    },
    liveChatMessagingIcon: {
      fill: baseColors.teal_10,
    },
    liveMessagingIcon: {
      background: baseColors.peri_20,
      text: baseColors.peri_50,
    },
    lockPromptIcon: {
      shackle: { fill: baseColors.teal_30 },
      lockBody: { fill: baseColors.teal_10 },
      rightBody: { fill: baseColors.teal_20 },
      keyHole: { fill: baseColors.teal_30 },
    },
    logoutIcon: {
      rectangle: { fill: baseColors.teal_20 },
      arrow: { fill: baseColors.teal_40 },
    },
    menuIcon: {
      fill: baseColors.teal_60,
    },
    messageIcon: {
      numberBadge: { border: baseColors.white },
    },
    messagePromptIcon: {
      backgroundMessageLight: { fill: baseColors.teal_20 },
      backgroundMessageDark: { fill: baseColors.teal_30 },
      foregroundMessage: { fill: baseColors.teal_10 },
    },
    messagingIcon: {
      border: baseColors.teal_60,
    },
    notificationIcon: {
      fill: baseColors.teal_30,
    },
    numberBadgeIcon: {
      background: baseColors.red_40,
      border: baseColors.red_40,
    },
    phoneIcon: {
      fill: baseColors.oatmeal_60,
    },
    progressIcon: {
      fill: baseColors.teal_60,
    },
    scoringIcon: {
      negative: {
        arrow: { fill: baseColors.gold_40 },
        stroke: { fill: baseColors.gold_20 },
        fill: baseColors.gold_10,
      },
      positive: {
        arrow: { fill: baseColors.green_40 },
        stroke: { fill: baseColors.green_20 },
        fill: baseColors.green_10,
      },
      neutral: {
        arrow: { fill: baseColors.teal_40 },
        stroke: { fill: baseColors.teal_20 },
        fill: baseColors.teal_10,
      },
    },
    searchIcon: {
      fill: baseColors.oatmeal_60,
    },
    selfCareIcon: {
      fill: baseColors.teal_30,
      clipPath: { fill: baseColors.white },
    },
    sendIcon: {
      fill: baseColors.oatmeal_20,
    },
    sessionIcon: {
      fill: baseColors.teal_60,
    },
    sparklesAvatarIcon: {
      fill: baseColors.teal_30,
    },
    sparklesIcon: {
      clipPath: { fill: baseColors.white },
    },
    starRatingIcon: {
      fill: baseColors.oatmeal_50,
    },
    timeIcon: {
      background: baseColors.teal_10,
      clockHandBackground: {
        fill: baseColors.teal_20,
      },
      clockHandForeground: {
        fill: baseColors.teal_30,
      },
    },
    warningIcon: {
      fill: baseColors.oatmeal_10,
    },
    xIcon: {
      fill: baseColors.oatmeal_50,
    },
    xIconRound: {
      fill: baseColors.oatmeal_50,
      border: baseColors.red_50,
    },
  },
}

export const darkThemeColors: ThemeColors = {
  ...lightThemeColors,

  // Text
  textPrimary: baseColors.oatmeal_10,
  textSecondary: baseColors.oatmeal_20,
  textInactive: baseColors.oatmeal_40,
  textActive: baseColors.teal_30,
  textSuccess: baseColors.green_20,
  textWarning: baseColors.gold_20,
  textError: baseColors.red_20,
  textButton: baseColors.white,
  textInverse: baseColors.black,

  // Link
  linkDefault: baseColors.teal_30,
  linkHover: baseColors.teal_20,
  linkInactive: baseColors.oatmeal_30,

  // Background
  backgroundPrimary: baseColors.teal_75,
  backgroundSecondary: baseColors.teal_80,
  backgroundSection: baseColors.teal_70,
  backgroundHighlightPrimary: baseColors.teal_60,
  backgroundHighlightTertiary: baseColors.peach_60,
  backgroundHighlightSecondary: baseColors.teal_75,
  backgroundTransparent: baseColors.transparent,
  backgroundActive: baseColors.teal_30,
  backgroundHover: baseColors.teal_40,
  backgroundPressed: baseColors.teal_50,
  backgroundLoading: baseColors.teal_50,
  backgroundFocus: baseColors.teal_60,
  backgroundInactive: baseColors.teal_60,
  backgroundSuccess: baseColors.green_60,
  backgroundWarning: baseColors.gold_60,
  backgroundError: baseColors.red_60,
  backgroundTooltip: baseColors.white,
  backgroundScrim: baseColors.scrim,
  backgroundElement: baseColors.teal_70,
  backgroundIcon: baseColors.teal_70,
  backgroundHeaderPeriwinkle: baseColors.peri_10,
  backgroundHeaderPrimary: baseColors.teal_75,
  backgroundNavHeader: baseColors.teal_70,
  backgroundPhoto: baseColors.teal_60,
  backgroundTopMatch: baseColors.teal_65,

  // Border
  borderDefault: baseColors.teal_65,
  borderActive: baseColors.teal_60,
  borderHover: baseColors.teal_30,
  borderFocus: baseColors.teal_30,
  borderInput: baseColors.teal_30,
  borderSuccess: baseColors.green_20,
  borderWarning: baseColors.gold_20,
  borderError: baseColors.red_20,
  borderOverlay: baseColors.oatmeal_20,
  borderTopMatch: baseColors.teal_50,
  borderContrast: baseColors.black,

  // Shadows - same as light mode

  // Icon
  iconDefault: baseColors.oatmeal_20,
  iconActive: baseColors.teal_30,
  iconInactive: baseColors.oatmeal_30,
  iconSuccess: baseColors.green_20,
  iconWarning: baseColors.gold_20,
  iconError: baseColors.red_20,
  iconAccent: baseColors.peach_40,
  iconInverse: baseColors.black,

  // Divider
  dividerPrimary: baseColors.teal_60,
  dividerSecondary: baseColors.teal_50,

  // Primary button
  primaryButtonBackgroundDefault: baseColors.teal_40,
  primaryButtonBackgroundHover: baseColors.teal_50,
  primaryButtonBackgroundPressed: baseColors.teal_30,
  primaryButtonBackgroundInactive: baseColors.oatmeal_50,
  primaryButtonBackgroundLoading: baseColors.teal_30,
  primaryButtonBackgroundFocus: baseColors.teal_30,
  primaryButtonTextActive: baseColors.white,
  primaryButtonTextInactive: baseColors.oatmeal_30,

  // Primary button variants
  primaryButtonPeriwinkleBackgroundDefault: baseColors.peri_30,
  primaryButtonPeriwinkleBackgroundHover: baseColors.peri_20,
  primaryButtonPeriwinkleBackgroundPressed: baseColors.peri_10,
  primaryButtonPeriwinkleBackgroundInactive: baseColors.oatmeal_50,
  primaryButtonPeriwinkleBackgroundLoading: baseColors.peri_10,
  primaryButtonPeriwinkleBackgroundFocus: baseColors.peri_20,

  // Secondary button
  secondaryButtonBackgroundDefault: baseColors.transparent,
  secondaryButtonBackgroundHover: baseColors.teal_60,
  secondaryButtonBackgroundPressed: baseColors.teal_50,
  secondaryButtonBackgroundInactive: baseColors.transparent,
  secondaryButtonBackgroundFocus: baseColors.teal_60,
  secondaryButtonOutlineDefault: baseColors.teal_30,
  secondaryButtonOutlineInactive: baseColors.oatmeal_40,
  secondaryButtonTextActive: baseColors.teal_20,
  secondaryButtonTextInactive: baseColors.oatmeal_30,

  // Secondary button variants
  secondaryButtonInverseBackgroundDefault: baseColors.transparent,
  secondaryButtonInverseBackgroundHover: baseColors.teal_10,
  secondaryButtonInverseBackgroundPressed: baseColors.teal_10,
  secondaryButtonInverseBackgroundFocus: baseColors.teal_10,
  secondaryButtonInverseOutlineDefault: baseColors.teal_50,
  secondaryButtonInverseOutlinePressed: baseColors.teal_30,
  secondaryButtonInverseOutlineInactive: baseColors.oatmeal_30,
  secondaryButtonInverseTextActive: baseColors.teal_50,
  secondaryButtonInverseTextInactive: baseColors.oatmeal_30,
  secondaryButtonBlackBackgroundDefault: baseColors.black,
  secondaryButtonBlackOutlineDefault: baseColors.white,
  secondaryButtonBlackTextActive: baseColors.white,
  secondaryButtonPeriwinkleOutlineDefault: baseColors.peri_50,
  secondaryButtonPeriwinkleTextActive: baseColors.peri_50,
  secondaryButtonPeriwinkleBackgroundHover: baseColors.peri_10,
  secondaryButtonPeriwinkleBackgroundPressed: baseColors.peri_30,
  secondaryButtonPeriwinkleBackgroundLoading: baseColors.peri_10,
  secondaryButtonPeriwinkleBackgroundFocus: baseColors.peri_10,

  // Tertiary button (icon button)
  tertiaryButtonBackgroundDefault: baseColors.teal_70,
  tertiaryButtonBackgroundHover: baseColors.oatmeal_50,
  tertiaryButtonBackgroundPressed: baseColors.oatmeal_40,
  tertiaryButtonBackgroundInactive: baseColors.transparent,
  tertiaryButtonBackgroundFocus: baseColors.oatmeal_50,
  tertiaryButtonTextActive: baseColors.oatmeal_20,
  tertiaryButtonTextInactive: baseColors.oatmeal_30,

  // Secondary Tertiary button (icon button) tokens
  secondaryTertiaryButtonBackgroundDefault: baseColors.oatmeal_50,
  secondaryTertiaryButtonBackgroundHover: baseColors.oatmeal_40,
  secondaryTertiaryButtonBackgroundPressed: baseColors.transparent,
  secondaryTertiaryButtonBackgroundInactive: baseColors.oatmeal_50,
  secondaryTertiaryButtonBackgroundFocus: baseColors.oatmeal_40,
  secondaryTertiaryButtonTextActive: baseColors.oatmeal_20,
  secondaryTertiaryButtonTextInactive: baseColors.oatmeal_30,

  // Word cloud button
  wordCloudButtonBackgroundDefault: baseColors.teal_75,
  wordCloudButtonBackgroundSelected: baseColors.teal_60,
  wordCloudPeriwinkleButtonBackgroundSelected: baseColors.peri_60,
  wordCloudButtonOutlineDefault: baseColors.teal_60,
  wordCloudButtonOutlineHover: baseColors.teal_30,
  wordCloudPeriwinkleButtonOutlineHover: baseColors.peri_30,
  wordCloudButtonOutlinePressed: baseColors.teal_30,
  wordCloudPeriwinkleButtonOutlinePressed: baseColors.peri_30,
  wordCloudButtonOutlineInactive: baseColors.oatmeal_40,
  wordCloudButtonOutlineSelected: baseColors.teal_30,
  wordCloudPeriwinkleButtonOutlineSelected: baseColors.peri_40,
  wordCloudButtonTextActive: baseColors.oatmeal_20,
  wordCloudButtonTextInactive: baseColors.oatmeal_30,
  wordCloudButtonTextPressed: baseColors.teal_20,
  wordCloudPeriwinkleButtonTextPressed: baseColors.peri_20,
  wordCloudButtonTextSelected: baseColors.teal_20,
  wordCloudPeriwinkleButtonTextSelected: baseColors.peri_50,

  // Input field
  inputOutlineDefault: baseColors.teal_60,
  inputOutlineSelected: baseColors.teal_50,
  inputOutlineInactive: baseColors.teal_65,
  inputOutlineHover: baseColors.teal_30,
  inputOutlineFocus: baseColors.teal_30,
  inputOutlineError: baseColors.red_20,
  inputOutlineTriageDefault: baseColors.teal_60,
  inputTextDefault: baseColors.oatmeal_20,
  inputTextPlaceholder: baseColors.oatmeal_30,
  inputTextActive: baseColors.oatmeal_30,
  inputTextInactive: baseColors.oatmeal_30,
  inputTextSuccess: baseColors.green_20,
  inputTextWarning: baseColors.gold_20,
  inputTextError: baseColors.red_20,
  inputBackgroundDefault: baseColors.teal_75,

  // Checkbox
  checkboxBackgroundPrimary: baseColors.teal_75,
  checkboxBackgroundSelected: baseColors.teal_40,
  checkboxBackgroundPressed: baseColors.teal_40,
  checkboxBackgroundSelectedInactive: baseColors.teal_20,
  checkboxOutlineDefault: baseColors.teal_60,
  checkboxOutlineInactive: baseColors.teal_70,
  checkboxOutlineSelected: baseColors.teal_50,
  checkboxOutlineSelectedInactive: baseColors.teal_60,
  checkboxCheckSelected: baseColors.white,

  // Radio
  radioBackgroundPrimary: baseColors.teal_75,
  radioBackgroundSelected: baseColors.teal_20,
  radioBackgroundSelectedInactive: baseColors.teal_40,
  radioTextDefault: baseColors.oatmeal_20,
  radioTextHighlight: baseColors.white,
  radioTextSelected: baseColors.teal_20,
  radioOutlineDefault: baseColors.teal_60,
  radioOutlineInactive: baseColors.teal_65,
  radioOutlineSelected: baseColors.teal_50,
  radioOutlineSelectedInactive: baseColors.teal_60,
  radioOutlineFocused: baseColors.teal_30,

  // Radio with outline
  radioWithOutlineBackgroundHighlight: baseColors.teal_65,
  radioWithOutlinePrimaryTextHighlight: baseColors.teal_10,
  radioWithOutlineBackgroundSelected: baseColors.teal_60,
  radioWithOutlineBorderSelected: baseColors.teal_30,
  radioWithOutlinePrimaryTextSelected: baseColors.teal_20,
  radioWithOutlineSecondaryTextSelected: baseColors.teal_30,
  radioWithOutlineBorderHovered: baseColors.teal_40,
  radioWithOutlinePrimaryTextInactive: baseColors.oatmeal_40,
  radioWithOutlineSecondaryTextInactive: baseColors.oatmeal_50,
  radioWithOutlineBackgroundDefault: baseColors.teal_75,
  radioWithOutlineBorderInactive: baseColors.teal_60,
  radioWithOutlinePrimaryTextDefault: baseColors.oatmeal_10,
  radioWithOutlineSecondaryTextDefault: baseColors.oatmeal_20,
  radioWithOutlineBorderDefault: baseColors.teal_50,

  // Cards
  cardBackgroundPrimary: baseColors.teal_70,
  cardOutlinePrimary: baseColors.teal_50,
  cardBackgroundSecondary: baseColors.peri_70,
  cardOutlineSecondary: baseColors.peri_70,
  cardOutlineInactive: baseColors.teal_65,

  // Progress indicator
  progressIndicator: baseColors.peach_30,
  progressIndicatorBackgroundTop: baseColors.teal_65,
  progressIndicatorBackgroundBottom: baseColors.oatmeal_50,

  // ScrollView Refresher
  scrollViewRefresherLoadingIcon: baseColors.teal_40,

  // Gradient Tokens
  gradientBubbleStart: baseColors.teal_10,
  gradientBubbleEnd: baseColors.teal_0,
  gradientBoxStart: baseColors.gradient_box_purple,
  gradientBoxEnd: baseColors.gradient_box_teal,
  gradientBoxText: baseColors.oatmeal_60,

  // Page background gradient tokens
  backgroundGradientFirst: baseColors.teal_60,
  backgroundGradientSecond: baseColors.black,

  components: {
    ...lightThemeColors.components,
    activityBadge: {
      background: baseColors.peri_20,
      text: baseColors.black,
    },
    activityCard: {
      activityBadgeWarn: {
        background: baseColors.gold_50,
        text: baseColors.gold_10,
      },
    },
    activityHeader: {
      headline: {
        border: baseColors.teal_40,
      },
    },
    alertBanner: {
      danger: { background: baseColors.red_50, text: baseColors.red_10 },
      success: { background: baseColors.green_50, text: baseColors.green_10 },
      warning: { background: baseColors.gold_50, text: baseColors.gold_10 },
      info: { background: baseColors.peri_50, text: baseColors.oatmeal_10 },
      notification: { background: baseColors.red_20, text: baseColors.black },
      calm: { background: baseColors.teal_10, text: baseColors.black },
    },
    assignmentsListItem: {
      success: { background: baseColors.green_60, text: baseColors.green_20 },
      default: { background: baseColors.gold_60, text: baseColors.gold_20 },
      rightActionHover: { background: baseColors.oatmeal_50 },
      rightActionPressed: { background: baseColors.oatmeal_40 },
    },
    avatar: {
      tealTheme: { background: baseColors.teal_60, text: baseColors.teal_30 },
      greenTheme: { background: baseColors.green_60, text: baseColors.green_30 },
      goldTheme: { background: baseColors.gold_60, text: baseColors.gold_30 },
      peachTheme: { background: baseColors.peach_60, text: baseColors.peach_20 },
      periwinkleTheme: { background: baseColors.peri_60, text: baseColors.peri_30 },
    },
    backButton: {
      defaultIcon: { fill: baseColors.oatmeal_10 },
    },
    banner: {
      outlineButton: {
        border: baseColors.black,
      },
    },
    biometrics: { background: baseColors.black },
    bulletedList: {
      fill: baseColors.oatmeal_20,
    },
    calendarCard: {
      appointmentTodayText: {
        text: baseColors.peri_30,
      },
    },
    chatBubble: {
      background: baseColors.black,
      border: baseColors.oatmeal_50,
      outOfOffice: {
        background: baseColors.teal_30,
        text: baseColors.black,
      },
    },
    chatHeader: {
      textBadge: {
        background: baseColors.red_60,
        text: baseColors.red_20,
      },
    },
    chatInput: {
      border: baseColors.black,
    },
    chatMenu: {
      active: { background: baseColors.oatmeal_40 },
      hover: { background: baseColors.oatmeal_50 },
    },
    checkIconListItem: {
      checkmark: { fill: baseColors.peach_30 },
    },
    coachMark: {
      background: baseColors.teal_10,
      arrow: {
        border: baseColors.teal_10,
      },
    },
    connectionIndicator: {
      signalHigh: { background: baseColors.green_40 },
      signalMedium: { background: baseColors.gold_40 },
      signalLow: { background: baseColors.red_50 },
      signalDefault: { background: baseColors.oatmeal_40 },
    },
    countryIcon: {
      background: baseColors.green_50,
      text: baseColors.green_10,
    },
    coverageMessagingModal: {
      fill: baseColors.peach_30,
    },
    dateBlock: {
      border: baseColors.teal_30,
      text: baseColors.teal_30,
      background: baseColors.teal_60,
    },
    eligibilityWarningBanner: {
      background: baseColors.gold_60,
    },
    feedbackStarIcon: {
      default: {
        fill: baseColors.white,
        border: baseColors.oatmeal_40,
        hover: {
          fill: baseColors.white,
          border: baseColors.teal_40,
        },
        highlighted: {
          fill: baseColors.teal_40,
          border: baseColors.teal_40,
        },
      },
      periwinkle: {
        fill: baseColors.white,
        border: baseColors.peri_40,
        hover: {
          fill: baseColors.peri_40,
          border: baseColors.peri_40,
        },
        highlighted: {
          fill: baseColors.peri_40,
          border: baseColors.peri_40,
        },
      },
    },
    formBody: {
      submitButton: {
        border: baseColors.oatmeal_50,
        boxShadow: { fill: baseColors.oatmeal_20 },
      },
    },
    formErrorBanner: {
      background: baseColors.red_20,
    },
    genericHeader: {
      background: baseColors.teal_10,
    },
    genericListItem: {
      badge: { background: baseColors.peri_30 },
    },
    homeToolbar: {
      toolbarIcon: { fill: baseColors.black },
    },
    indicator: {
      text: baseColors.gold_20,
    },
    inlineInfoBanner: {
      background: baseColors.peri_60,
      text: baseColors.oatmeal_10,
      infoIcon: {
        fill: baseColors.oatmeal_20,
      },
    },
    inlineWarningBanner: {
      background: baseColors.gold_60,
      border: baseColors.gold_10,
      text: baseColors.gold_10,
      warningIcon: {
        fill: baseColors.gold_10,
      },
    },
    inputAccessory: {
      border: baseColors.oatmeal_50,
    },
    integratedBenefitsEntryCard: {
      fencepost: {
        border: baseColors.oatmeal_50,
      },
    },
    integratedBenefitsLabeledItemGroup: {
      itemGroup: {
        boxShadow: { fill: 'rgba(0, 0, 0, 0.1)' },
        shadow: { fill: baseColors.white },
      },
    },
    internationalCareNavigatorContactInfo: {
      tooltip: { text: baseColors.teal_40 },
    },
    lockedField: {
      lockIcon: {
        fill: baseColors.oatmeal_10,
      },
    },
    lessonPreview: {
      activityBadge: {
        background: baseColors.gold_50,
        text: baseColors.gold_10,
      },
      progressBar: {
        fill: baseColors.teal_30,
      },
    },
    lessonThumbnail: {
      iconDisplay: {
        background: baseColors.teal_50,
      },
      disabledIcon: {
        fill: baseColors.teal_40,
      },
      progressBar: {
        fill: baseColors.teal_30,
      },
    },
    locationSearchNavigation: {
      highlightColor: { background: baseColors.teal_40 },
      separatorColor: { background: '#C8C7CC' },
    },
    messageAttachment: {
      innerContainer: {
        background: baseColors.teal_20,
      },
    },
    messageItem: {
      chatBubbleReceiver: {
        background: baseColors.black,
        border: baseColors.oatmeal_40,
      },
      chatBubbleSender: {
        background: baseColors.teal_20,
        border: baseColors.teal_20,
      },
      icon: {
        receiver: { fill: baseColors.white },
      },
    },
    modal: {
      background: 'rgba(65, 68, 78, 0.8)', // FIXME: shadow80 equivalent
      modalCardShadow: { background: baseColors.black },
      scrollableModalShadow: { background: baseColors.oatmeal_10 },
      sidePanelShadow: { background: 'rgba(0, 0, 0, 0.1)' }, // FIXME: shadow10 equivalent
      animationShadow: { background: 'rgba(0, 0, 0, 0.4)' }, // FIXME: shadow40 equivalent
    },
    newMessageIndicator: {
      arrow: { fill: baseColors.oatmeal_10 },
    },
    nextVideo: {
      background: baseColors.white,
    },
    notificationChannel: {
      warningContainer: {
        background: baseColors.gold_20,
      },
      warningIcon: {
        fill: baseColors.black,
      },
    },
    notificationsOverview: {
      notificationIcon: {
        fill: baseColors.teal_10,
      },
    },
    passwordChecklist: {
      checklistItemSuccess: {
        fill: baseColors.green_10,
        text: baseColors.green_10,
      },
      checklistBullet: {
        fill: baseColors.oatmeal_30,
      },
    },
    platformEngagementQuarterlyDetails: {
      text: baseColors.teal_10,
    },
    privacyScreen: {
      textContainer: { background: baseColors.teal_60 },
    },
    profileFooter: {
      logOut: {
        text: baseColors.red_10,
      },
    },
    profilePageCard: { border: baseColors.oatmeal_50 },
    progressBar: {
      fill: baseColors.teal_50,
      background: baseColors.oatmeal_30,
    },
    progressBarSecondary: {
      fill: baseColors.teal_30,
      background: baseColors.teal_20,
    },
    providerCredentials: {
      checkIcon: { fill: baseColors.peach_30 },
    },
    providerLanguagesSpoken: {
      bulletPoint: {
        background: baseColors.peach_30,
      },
      textBadge: {
        text: baseColors.teal_20,
      },
    },
    providerBadges: {
      bctFellowshipBadge: {
        shootingStarIcon: { fill: baseColors.gold_40 },
      },
      overflowBadge: {
        open: { fill: baseColors.oatmeal_40 },
        closed: { fill: baseColors.oatmeal_50 },
      },
      preferenceMatchBadge: {
        checkIcon: { fill: baseColors.teal_40 },
      },
      modalityBadge: {
        background: baseColors.black,
      },
      listItemBadge: {
        background: baseColors.oatmeal_15,
      },
    },
    providerBaseHeader: {
      border: baseColors.black,
    },
    providerSpecializations: {
      bulletPoint: {
        background: baseColors.peach_30,
      },
    },
    providerWithNoPreferenceMatchModal: {
      noPreferenceMatchIcon: {
        fill: baseColors.oatmeal_10,
      },
    },
    s4cLoadingIndicator: {
      text: baseColors.teal_10,
    },
    s4cHeader: {
      background: baseColors.black,
    },
    selectedBenefitItem: {
      badge: { fill: baseColors.oatmeal_40 },
    },
    selfHarmNotice: {
      border: baseColors.gold_40,
    },
    sessionModality: {
      separator: { fill: baseColors.oatmeal_30 },
      modalityBullet: { fill: baseColors.peach_40 },
    },
    sliderField: {
      text: baseColors.teal_20,
      thumb: {
        border: baseColors.teal_40,
        dashedOutline: {
          background: baseColors.white,
        },
      },
      track: {
        filled: {
          background: baseColors.oatmeal_30,
        },
        unFilled: {
          background: baseColors.oatmeal_40,
        },
        minimumTint: {
          background: baseColors.teal_40,
        },
        maximumTint: {
          background: baseColors.oatmeal_50,
        },
      },
      stepMarker: {
        filled: {
          background: baseColors.teal_10,
        },
        active: {
          background: baseColors.white,
        },
        unFilled: {
          background: baseColors.oatmeal_30,
        },
      },
      floatingLabel: {
        border: baseColors.teal_40,
        background: baseColors.transparent,
        shadow: { fill: baseColors.shadow },
      },
    },
    triageSummary: {
      badge: {
        background: baseColors.teal_60,
        fill: baseColors.teal_30,
      },
      line: {
        stroke: { fill: baseColors.oatmeal_40 },
      },
    },
    toast: {
      success: { background: baseColors.green_20 },
      info: { background: baseColors.peri_40 },
      error: { background: baseColors.red_20 },
      warning: { background: baseColors.gold_50 },
    },
    toolbar: {
      background: baseColors.teal_10,
    },
    treatmentCard: {
      background: baseColors.teal_70,
      findCounsellorSection: {
        icon: {
          fill: baseColors.teal_60,
        },
      },
    },
    unsupportedLanguageDisclaimer: {
      text: baseColors.teal_20,
    },
    videoCallPreview: {
      background: '#343A40', // FIXME: charcoal6 currently, need to find an oatmeal equivalent
      cameraContainer: {
        background: baseColors.black,
      },
    },
    wellnessCheckInResultsDomainScore: {
      low: {
        outerCircle: {
          fill: baseColors.red_10,
        },
        innerCircle: {
          fill: baseColors.red_30,
        },
        text: baseColors.red_40,
      },
      moderate: {
        outerCircle: {
          fill: baseColors.gold_10,
        },
        innerCircle: {
          fill: baseColors.gold_30,
        },
        text: baseColors.gold_40,
      },
      high: {
        outerCircle: {
          fill: baseColors.green_10,
        },
        innerCircle: {
          fill: baseColors.green_30,
        },
        text: baseColors.green_40,
      },
      default: {
        outerCircle: {
          fill: baseColors.oatmeal_10,
        },
        innerCircle: {
          fill: baseColors.oatmeal_30,
        },
        text: baseColors.oatmeal_40,
      },
    },
    videoControlButton: {
      enabledColor: {
        fill: 'rgba(255, 255, 255, 0.2)', // FIXME: highlight20 - need updated value
      },
      disabledColor: {
        fill: baseColors.teal_40,
      },
      messengerDisabledColor: {
        fill: 'rgba(255, 255, 255, 0.2)', // FIXME: highlight20 - need updated value
      },
      endButton: {
        fill: baseColors.red_30,
      },
    },
    videoLessonComplete: {
      check: { fill: baseColors.teal_20 },
      completeText: { text: baseColors.teal_20 },
      videoTitleText: { text: baseColors.white },
    },
    videoSessionControls: {
      dark: { background: 'rgba(255, 255, 255, 0.6)' },
      default: { background: 'rgba(255, 255, 255, 0.4)' },
    },
    videoSessionLocalViewContainer: {
      background: '#343A40',
    },
    videoSessionMiniViewContainer: {
      background: '#343A40',
    },
    videoSessionParticipantViewContainer: {
      background: baseColors.black,
    },
    videoSessionParticipantView: {
      defaultView: { background: baseColors.black },
      miniView: { background: baseColors.oatmeal_30 },
    },
    videoPlayer: {
      background: baseColors.black,
      thumbTint: { background: baseColors.white },
      minimumTrackTint: { background: baseColors.teal_20 },
      maximumTrackTint: { background: 'rgba(255, 255, 255, 0.8)' },
    },
    videoPlayerModal: {
      background: baseColors.black,
    },
    videoSession: {
      backgroundDark: { background: baseColors.black },
    },
    weekItem: {
      activityBadge: { background: baseColors.green_20 },
      pressedBackground: { background: baseColors.oatmeal_50 },
    },
    welcomeSplashScreen: {
      backButton: { fill: baseColors.teal_30 },
    },
    wellnessToolkitEssentials: {
      text: baseColors.teal_20,
    },
    wordBubbleGradient: {
      text: baseColors.oatmeal_50,
    },
    zoomVideoControls: {
      ...lightThemeColors.components.zoomVideoControls,
      enabled: { background: baseColors.oatmeal_50 },
    },
    zoomVideoSession: {
      background: baseColors.oatmeal_60,
    },
  },
  icons: {
    ...lightThemeColors.icons,
    appleIcon: {
      fill: baseColors.white,
    },
    arrowIcon: {
      fill: baseColors.oatmeal_60,
    },
    avatarIcon: {
      body: { fill: baseColors.teal_60 },
      shadow: { fill: baseColors.teal_50 },
      fill: baseColors.teal_40,
    },
    bellIcon: {
      clipPath: { fill: baseColors.black },
    },
    boltIcon: {
      fill: baseColors.oatmeal_10,
    },
    briefCaseIcon: {
      rect: { fill: baseColors.oatmeal_60 },
    },
    chevronIcon: {
      fill: baseColors.oatmeal_60,
    },
    closeCircleBorderedIcon: {
      fill: baseColors.oatmeal_40,
    },
    closeCircleIcon: {
      fill: baseColors.oatmeal_10,
    },
    essentialsIcon: {
      fill: baseColors.teal_10,
    },
    eyeIcon: {
      fill: baseColors.oatmeal_10,
    },
    feedbackStarIcon: {
      // Used in Mask fill
      fill: baseColors.black,
    },
    fingerprintIcon: {
      fill: baseColors.teal_50,
    },
    linkIcon: {
      chain: { fill: baseColors.teal_40 },
    },
    listIcon: {
      fill: baseColors.oatmeal_10,
    },
    liveMessagingIcon: {
      background: baseColors.peri_50,
      text: baseColors.peri_20,
    },
    lockPromptIcon: {
      shackle: { fill: baseColors.teal_40 },
      lockBody: { fill: baseColors.teal_60 },
      rightBody: { fill: baseColors.teal_50 },
      keyHole: { fill: baseColors.teal_40 },
    },
    logoutIcon: {
      rectangle: { fill: baseColors.teal_50 },
      arrow: { fill: baseColors.teal_30 },
    },
    menuIcon: {
      fill: baseColors.teal_10,
    },
    messageIcon: {
      numberBadge: { border: baseColors.black },
    },
    messagePromptIcon: {
      backgroundMessageLight: { fill: baseColors.teal_50 },
      backgroundMessageDark: { fill: baseColors.teal_40 },
      foregroundMessage: { fill: baseColors.teal_60 },
    },
    messagingIcon: {
      border: baseColors.teal_10,
    },
    notificationIcon: {
      fill: baseColors.teal_40,
    },
    numberBadgeIcon: {
      background: baseColors.red_30,
      border: baseColors.red_30,
    },
    phoneIcon: {
      fill: baseColors.oatmeal_10,
    },
    progressIcon: {
      fill: baseColors.teal_10,
    },
    scoringIcon: {
      negative: {
        arrow: { fill: baseColors.gold_30 },
        stroke: { fill: baseColors.gold_50 },
        fill: baseColors.gold_60,
      },
      positive: {
        arrow: { fill: baseColors.green_30 },
        stroke: { fill: baseColors.green_50 },
        fill: baseColors.green_60,
      },
      neutral: {
        arrow: { fill: baseColors.teal_30 },
        stroke: { fill: baseColors.teal_50 },
        fill: baseColors.teal_60,
      },
    },
    searchIcon: {
      fill: baseColors.oatmeal_10,
    },
    selfCareIcon: {
      fill: baseColors.teal_40,
      clipPath: { fill: baseColors.black },
    },
    sendIcon: {
      fill: baseColors.oatmeal_50,
    },
    sessionIcon: {
      fill: baseColors.teal_10,
    },
    sparklesIcon: {
      clipPath: { fill: baseColors.black },
    },
    timeIcon: {
      background: baseColors.teal_60,
      clockHandBackground: {
        fill: baseColors.teal_50,
      },
      clockHandForeground: {
        fill: baseColors.teal_40,
      },
    },
    warningIcon: {
      fill: baseColors.oatmeal_60,
    },
    xIcon: {
      fill: baseColors.oatmeal_20,
    },
    xIconRound: {
      fill: baseColors.oatmeal_20,
      border: baseColors.red_20,
    },
  },
}
