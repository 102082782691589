import React, { FunctionComponent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { toNumber } from 'lodash-es'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  getInitialWellnessCheckinValues,
  getWellnessCheckInTotalNumPages,
  REGISTRATION_ENTRY_POINTS,
  WellbeingResponse,
  WELLNESS_CHECK_IN_EVENTS,
  WELLNESS_CHECK_IN_PAGES,
} from '@lyrahealth-inc/shared-app-logic'
import { WellnessCheckInCopyrightFooter, WellnessCheckInForm } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  WELLNESS_CHECK_IN_PAGE_TYPE,
  WellnessCheckInHeader,
} from '../../common/components/header/WellnessCheckInHeader'
import { useGetLegalFooterProps } from '../../common/hooks/useGetLegalFooterProps'
import { useShouldShowWellnessCheckIn } from '../../common/hooks/useShouldShowWellnessCheckIn'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import { postWellnessCheckInResult } from '../../common/http/data/wellnessCheckIn'
import { logToSumoLogic } from '../../common/utils/userUtils'
import { getDeviceUUID } from '../../data/appGlobals/appGlobalsSelectors'
import { getIsLyraSocialCareEnabled } from '../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getId, getIsUserLoggedIn } from '../../data/user/userSelectors'
import {
  clearWellnessCheckInResults,
  setWellnessCheckInForm,
  setWellnessCheckInResults,
} from '../../data/wellnessCheckIn/wellnessCheckInActions'
import { getWellnessCheckInForm } from '../../data/wellnessCheckIn/wellnessCheckInSelectors'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'

const WellnessCheckInFormContainer: FunctionComponent = ({}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const socialCareEnabled = useSelector(getIsLyraSocialCareEnabled)
  const showWellnessCheckIn = useShouldShowWellnessCheckIn()
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const userId = useSelector(getId)
  const deviceUUID = useSelector(getDeviceUUID)
  const { pageIndex } = useParams()
  const currentPage = toNumber(pageIndex)
  const totalPages = getWellnessCheckInTotalNumPages(socialCareEnabled)
  const validPageNumber = currentPage <= totalPages
  const legalFooterProps = useGetLegalFooterProps()
  const mixpanelPageName = Object.values(WELLNESS_CHECK_IN_PAGES)[currentPage]
  const wellnessCheckInValues =
    useSelector(getWellnessCheckInForm) || getInitialWellnessCheckinValues(socialCareEnabled)
  const setWellnessCheckInFormValues = (values: object) => {
    dispatch(setWellnessCheckInForm(values))
  }

  useTrackViewPage(
    mixpanelPageName,
    { [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD },
    { skipAppendPageToEventName: true },
  )

  useEffect(() => {
    if (!showWellnessCheckIn) {
      navigate('/')
    } else if (!validPageNumber) {
      navigate('/wellness-check-in')
    }
  }, [showWellnessCheckIn, navigate, validPageNumber])

  const handleSubmit = async (values: object) => {
    dispatch(setWellnessCheckInResults(values as WellbeingResponse))
    dispatch(trackEventWithObj({ event: WELLNESS_CHECK_IN_EVENTS.ASSESSMENT_COMPLETION, assessmentResults: values }))
    // This should only be for logged in flow, pre-registered will use the v2 token flow.
    if (isLoggedIn && userId) {
      try {
        await postWellnessCheckInResult(userId, {
          deviceUUID,
          body: JSON.stringify(values),
        })
        dispatch(clearWellnessCheckInResults())
        navigate(PAGE_ROUTES.WELLNESS_CHECK_IN.RESULTS)
      } catch (error) {
        console.error(`Unable save wellness check in : ${error}`)
        if (error instanceof Error) {
          logToSumoLogic('postWellnessCheckInResult', userId, `Unable save wellness check in : ${error.message}`)
        }
      }
    } else {
      navigate(PAGE_ROUTES.WELLNESS_CHECK_IN.PREVIEW, {
        state: { mixpanelEntryPoint: REGISTRATION_ENTRY_POINTS.WELLNESS_CHECK_IN },
      })
    }
  }

  const handleCTAPress = (formValues: object) => {
    dispatch(
      trackEventWithObj({
        event: `click ${mixpanelPageName} - Continue`,
        response: formValues,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      }),
    )
    if (currentPage < totalPages) {
      navigate(`/wellness-check-in/${currentPage + 1}`)
    }
  }

  return (
    <>
      <WellnessCheckInHeader pageType={WELLNESS_CHECK_IN_PAGE_TYPE.Questionnaire} />
      <WellnessCheckInForm
        currentPage={currentPage}
        totalPages={totalPages}
        socialCareEnabled={socialCareEnabled}
        onSubmit={handleSubmit}
        onCTAPress={handleCTAPress}
        currValues={wellnessCheckInValues}
        setCurrValues={setWellnessCheckInFormValues}
      />
      <WellnessCheckInCopyrightFooter {...legalFooterProps} />
    </>
  )
}

export default WellnessCheckInFormContainer
