import React from 'react'
import { connect, ConnectedProps, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  COUPLES_PARAMS,
  DirectLinkIntent,
  DirectLinkSource,
  FAMILY_PARAMS,
  FORKED_TRIAGE_ENTRY_POINTS,
  MEDS_PARAMS,
  PARENTING_PARAMS,
  PathwayOptions,
  PathwaysRedirectUrls,
  TRIAGE_ENTRY_POINTS,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'

import { useGoToDirectPathBooking } from '../../../common/hooks/useGoToDirectPathBooking'
import { useNavigateToSearchForCare } from '../../../common/hooks/useNavigateToSearchForCare'
import { useNavigateToSearchForCareChild } from '../../../common/hooks/useNavigateToSearchForCareChild'
import { getId } from '../../../data/user/userSelectors'
import { handleZeroViewSelect } from '../../appointmentDashboard/data/appointmentDashboardActions'
import { setSelectedPathwayOption, setTriageEntryPoint } from '../../register/data/registerActions'
import ForkedTriageContainer from '../ForkedTriageContainer'
import { forkedTriageNavigationRoutes } from '../types'

type Props = ConnectedProps<typeof connector>

const ForkedTriage: React.FC<Props> = ({ setSelectedPathwayOption, handleZeroViewSelect, setTriageEntryPoint }) => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const isLoggedIn = Boolean(useSelector(getId))
  const navigateToSearchForCare = useNavigateToSearchForCare()
  const goToDirectPath = useGoToDirectPathBooking()
  const { navigateToChildSearch } = useNavigateToSearchForCareChild()
  const { forkedTriageV2Enabled } = useFlags()

  const setPathwayOptionAndNavigateToRegistration = (
    pathwayOption: PathwayOptions,
    mixpanelEntryPoint: FORKED_TRIAGE_ENTRY_POINTS,
  ) => {
    setSelectedPathwayOption(pathwayOption)
    navigate('/register/connect', {
      state: { ...state, mixpanelEntryPoint, origin: TRIAGE_ENTRY_POINTS.FORKED_TRIAGE },
    })
  }

  const navigateToRegistrationRoutes: forkedTriageNavigationRoutes = {
    onSelfSearchPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.SELF_SEARCH,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_SELF,
      )
    },
    onChildSearchPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.CHILD_SEARCH,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_CHILD,
      )
    },
    onCoachingToolkitPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.COACHING_TOOLKIT,
        FORKED_TRIAGE_ENTRY_POINTS.COACHING_FOR_SELF,
      )
    },
    onParentingToolkitPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.PARENTING_TOOLKIT,
        FORKED_TRIAGE_ENTRY_POINTS.COACHING_FOR_CHILD,
      )
    },
    onMedsConsultPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.MEDICATION_CONSULTATION,
        FORKED_TRIAGE_ENTRY_POINTS.MEDICATION_CONSULTATION_FOR_SELF,
      )
    },
    onFamilyTherapyPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.FAMILY_THERAPY,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_FAMILY,
      )
    },
    onCouplesTherapyPress: () => {
      setPathwayOptionAndNavigateToRegistration(
        PathwayOptions.COUPLES_THERAPY,
        FORKED_TRIAGE_ENTRY_POINTS.FIND_CARE_FOR_COUPLES,
      )
    },
  }

  const navigateToLoggedInRoutes: forkedTriageNavigationRoutes = {
    onSelfSearchPress: () => {
      setTriageEntryPoint(TRIAGE_ENTRY_POINTS.FORKED_TRIAGE)
      navigateToSearchForCare()
    },
    onChildSearchPress: () => {
      setTriageEntryPoint(TRIAGE_ENTRY_POINTS.FORKED_TRIAGE)
      navigateToChildSearch()
    },
    onCoachingToolkitPress: () => {
      navigate(PathwaysRedirectUrls.COACHING_TOOLKIT, { state })
    },
    onParentingToolkitPress: () => {
      if (forkedTriageV2Enabled) {
        goToDirectPath({
          params: {
            ...PARENTING_PARAMS,
            directLinkSource: DirectLinkSource.FORKED_TRIAGE,
          },
        })
      } else {
        navigate(PathwaysRedirectUrls.PARENTING_TOOLKIT, { state })
      }
    },
    onMedsConsultPress: () => {
      goToDirectPath({
        params: {
          ...MEDS_PARAMS,
          directLinkIntent: DirectLinkIntent.MEDS_CONSULT,
          directLinkSource: DirectLinkSource.FORKED_TRIAGE,
        },
      })
    },
    onFamilyTherapyPress: () => {
      goToDirectPath({
        params: {
          ...FAMILY_PARAMS,
          directLinkIntent: DirectLinkIntent.FAMILY_SUPPORT,
          directLinkSource: DirectLinkSource.FORKED_TRIAGE,
        },
      })
    },
    onCouplesTherapyPress: () => {
      goToDirectPath({
        params: {
          ...COUPLES_PARAMS,
          directLinkIntent: DirectLinkIntent.COUPLES_THERAPY,
          directLinkSource: DirectLinkSource.FORKED_TRIAGE,
        },
      })
    },
  }

  return (
    <ForkedTriageContainer navigationRoutes={isLoggedIn ? navigateToLoggedInRoutes : navigateToRegistrationRoutes} />
  )
}

const connector = connect(null, {
  setSelectedPathwayOption,
  handleZeroViewSelect,
  setTriageEntryPoint,
})

export default connector(ForkedTriage)
