import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import axios from 'axios'
import queryString from 'query-string'

import {
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  ESSENTIALS_IS_INITIAL_LOAD_PARAM,
  MIXPANEL_EVENT_ATTRIBUTES,
  TRIAGE_ENTRY_POINTS,
  useFlags,
  WORK_HUB_EVENT_DEREGISTRATION_RETRY_MESSAGE,
  WORK_HUB_EVENT_REGISTRATION_LIMIT_REACHED_MESSAGE,
  WORK_HUB_EVENT_REGISTRATION_RETRY_MESSAGE,
} from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator } from '@lyrahealth-inc/ui-core'
import { toJS, useMediaQuerySize } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './essentials.module.scss'
import { isURLPathForEssentialsPreviewContent } from './essentialsUtils'
import { ESSENTIALS_IFRAME_MESSAGES, LYRA_HEALTH_NAME } from '../../common/constants/appConstants'
import { useDefaultMixpanelEntryPoint } from '../../common/hooks/useDefaultMixpanelEntryPoint'
import { useNavigateToSearchForCare } from '../../common/hooks/useNavigateToSearchForCare'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import { axiosInstance } from '../../common/http/axiosInstance'
import { getCourseCompletionCertificate } from '../../common/http/data/libraryCourseCertificate'
import { getDeviceUUID } from '../../data/appGlobals/appGlobalsSelectors'
import { setTimeInactivityWarning, setTimeLastActive } from '../../data/auth/authActions'
import { trackEventWithObj } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'
import { getId, getUserDisplayLanguage } from '../../data/user/userSelectors'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'
import { setTriageEntryPoint } from '../register/data/registerActions'

type EssentialsProps = {
  lyraId: string
  userDisplayLanguage: string
  deviceUUID: string
  trackEventWithObj: TrackEventWithObjAction
  setTimeInactivityWarning: (value: number | null) => void
  setTimeLastActive: (value: number) => void
  setTriageEntryPoint: (entryPoint: TRIAGE_ENTRY_POINTS, redirectUrlOnTriageExit?: string) => void
}

// Ensure the entryPoint is included in the URL when navigating
// to an essential page, so Essentials.tsx can parse it for Mixpanel.
// Use the title of the clicked component as the entryPoint
// (e.g., for a CTA titled "explore essential," use entryPoint="explore essential").

const Essentials: React.FC<EssentialsProps> = ({
  lyraId,
  userDisplayLanguage,
  deviceUUID,
  setTimeInactivityWarning,
  setTimeLastActive,
  setTriageEntryPoint,
}) => {
  const [iFrameHeight, setIFrameHeight] = useState<number>(0)
  const [isEssentialsLoading, setIsEssentialsLoading] = useState(true)
  const [iframeUrl, setIframeUrl] = useState<string>('')
  const [contentPath, setContentPath] = useState<string>('')
  const modalOpenRef = useRef<boolean>(false)
  const essentialsRef = useRef<HTMLIFrameElement>(null)
  const intervalRef = useRef(0)
  const location = useLocation()
  const { pathname, state } = location
  const referrerPage = useRef<string>(state?.[COMMON_MIXPANEL_PROPERTIES.REFERRER_PAGE])
  const intl = useIntl()
  const headerHeight = 80
  const minIFrameHeight = 680
  const navigate = useNavigate()
  const navigateToSearchForCare = useNavigateToSearchForCare()
  const [searchParams] = useSearchParams()
  const parentAppReferrer = searchParams.get('parentAppReferrer')
  const mixpanelEntryPoint = useDefaultMixpanelEntryPoint(MIXPANEL_EVENT_ATTRIBUTES.ENTRY_POINT.DIRECT_LINK)
  const localeOverride = searchParams.get('locale')
  const { isMobileSized } = useMediaQuerySize()
  const { essentialsInitialLoadParamEnabled: isInitialLoadParamEnabled } = useFlags()

  // If we are entering the component with an unsecured URL that is not allow-listed, redirect
  // back to the secure/index version of the URL and the axiosInstance will redirect to the Essentials
  // homepage widget.
  if (
    window.location.pathname.startsWith(PAGE_ROUTES.ESSENTIALS_UNGATED_CONTENT) &&
    !isURLPathForEssentialsPreviewContent()
  ) {
    const contentSlug = window.location.pathname.split('/').pop()
    window.location.href = `${PAGE_ROUTES.ESSENTIALS}/articles/${contentSlug}`
  }

  let essentialsUrl = `${window.location.origin}${contentPath}`
  const essentialsUrlObj = new URL(essentialsUrl)
  essentialsUrlObj.searchParams.set(
    'mixpanelProps',

    JSON.stringify({
      [COMMON_MIXPANEL_PROPERTIES.LD_DEVICE_UUID]: deviceUUID,
    }),
  )
  if (isInitialLoadParamEnabled) {
    essentialsUrlObj.searchParams.set(ESSENTIALS_IS_INITIAL_LOAD_PARAM, 'true')
  }
  essentialsUrl = essentialsUrlObj.toString()
  const essentialsTitle = intl.formatMessage({
    defaultMessage: 'Library',
    description: 'Browser tab title text when visiting the Essentials and Work Hub home page',
  })
  document.title = `${essentialsTitle} | ${LYRA_HEALTH_NAME}`

  useTrackViewPage(
    COMMON_MIXPANEL_PAGE.ESSENTIALS,
    {
      referrer_page: referrerPage.current ?? COMMON_MIXPANEL_PAGE.NONE,
    },
    { skipAppendPageToEventName: true, entryPoint: mixpanelEntryPoint },
  )

  const onIFrameLoad = useCallback(() => {
    setIsEssentialsLoading(false)
    if (essentialsRef?.current) {
      if (essentialsRef.current.contentWindow) {
        setIframeUrl(essentialsRef.current.contentWindow?.document.URL)
        if (modalOpenRef.current === false) {
          setIFrameHeight(essentialsRef?.current?.contentWindow.document?.body.scrollHeight)
        }

        // Watch iframe url so we can scroll to the top on page change.
        // Also watch the iframe content height to set the height of the iframe on page change.
        intervalRef.current = window.setInterval(() => {
          setIframeUrl(essentialsRef?.current?.contentWindow?.document.URL as string)
          if (modalOpenRef.current === false && essentialsRef?.current?.contentWindow) {
            setIFrameHeight(essentialsRef?.current?.contentWindow.document?.body.scrollHeight)
          }
        }, 500)
      }
    }
  }, [modalOpenRef])

  useEffect(() => {
    if (iframeUrl) {
      scroll(0, 0)
    }
  }, [iframeUrl])

  useEffect(() => {
    window.addEventListener('message', handleMessage, false)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // clear entryPoint params after use
  useEffect(() => {
    const params = {
      parentAppReferrer,
      locale: localeOverride !== userDisplayLanguage ? localeOverride : null,
    }
    const query = queryString.stringify(params, { skipNull: true, encode: false })
    const redirectUrl = location.pathname + (query.length > 0 ? `?${query}` : '')

    window.history.replaceState(null, '', redirectUrl)
  }, [location, parentAppReferrer, localeOverride, userDisplayLanguage])

  // Set the content pathname when the iframe is loaded
  // Add the query param to indicate un-gated content if necessary
  useEffect(() => {
    const pathname = window.location.pathname
    const search = window.location.search
    const hash = window.location.hash

    // If this is a valid request for preview content, add the preview search param and update the content path
    if (pathname.startsWith(`${PAGE_ROUTES.ESSENTIALS_UNGATED_CONTENT}`) && isURLPathForEssentialsPreviewContent()) {
      const receivedSearchParams = queryString.parse(search)
      receivedSearchParams.preview = 'true'
      const updatedSearch = `?${queryString.stringify(receivedSearchParams)}`

      setContentPath(`/secure${pathname}${updatedSearch}${hash}`)
    } else {
      setContentPath(`${pathname.replace('/index', '')}${search}${hash}`)
    }
  }, [setContentPath])

  const handleMessage = (message: MessageEvent) => {
    if (message.origin === window.location.origin) {
      switch (message.data.event) {
        case ESSENTIALS_IFRAME_MESSAGES.MODAL_CLOSED:
          modalOpenRef.current = false
          break
        case ESSENTIALS_IFRAME_MESSAGES.MODAL_OPENED:
          const applicationContentHeight = window.innerHeight - headerHeight
          // The iframe should have a minimum height of 680px when a modal is open
          const iFrameHeightForModal =
            applicationContentHeight < minIFrameHeight && !isMobileSized ? minIFrameHeight : applicationContentHeight
          modalOpenRef.current = true
          setIFrameHeight(iFrameHeightForModal)
          essentialsRef?.current?.contentWindow?.focus()
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_TO_PARENT_APP_ROUTE:
          navigate(-1)
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_TO_SEARCH_FOR_CARE:
          setTriageEntryPoint(TRIAGE_ENTRY_POINTS.ESSENTIALS, window.location.pathname)
          navigateToSearchForCare()
          break
        case ESSENTIALS_IFRAME_MESSAGES.ROUTE_CHANGE:
          handleRouteChange(message)
          break
        case ESSENTIALS_IFRAME_MESSAGES.CONTENT_PLAYING:
          setTimeInactivityWarning(null)
          setTimeLastActive(Date.now())
          break
        case ESSENTIALS_IFRAME_MESSAGES.REGISTER_FOR_EVENT:
          handleRegisterForEvent(message)
          break
        case ESSENTIALS_IFRAME_MESSAGES.DEREGISTER_FOR_EVENT:
          handleDeregisterForEvent(message)
          break
        case ESSENTIALS_IFRAME_MESSAGES.EVENT_REGISTERED_COUNT:
          handleEventRegisteredCounts(message)
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_BETWEEN_LIBRARY_APPS:
          handleNavigateBetweenLibraryApps(message)
          break
        case ESSENTIALS_IFRAME_MESSAGES.CREATE_COURSE_COMPLETION_CERTIFICATE:
          getCourseCompletionCertificate(lyraId, message.data.courseName, message.data.courseCompletionDate)
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_TO_COACHING_DIRECT_PATH:
          window.open(message.data.coachingUrl, '_self')
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_TO_LOGIN:
          navigate(PAGE_ROUTES.LOGIN)
          break
        case ESSENTIALS_IFRAME_MESSAGES.NAVIGATE_TO_REGISTER:
          navigate(PAGE_ROUTES.REGISTER)
          break
      }
    }
  }

  const handleRegisterForEvent = async (message: MessageEvent) => {
    const data = {
      zoom_event_id: message.data.zoom_event_id,
      zoom_event_type: message.data.zoom_event_type,
      ticket_type_id: message.data.ticket_type_id,
    }
    try {
      const response = await axiosInstance.post(`/v1/zoom-event/register/${lyraId}`, data)
      essentialsRef.current?.contentWindow?.postMessage(
        {
          ...data,
          ...response.data,
          event: ESSENTIALS_IFRAME_MESSAGES.REGISTER_FOR_EVENT,
          success: true,
        },
        essentialsRef.current.contentWindow.origin,
      )
    } catch (error) {
      let errorMessage = intl.formatMessage(WORK_HUB_EVENT_REGISTRATION_RETRY_MESSAGE)
      if (axios.isAxiosError(error) && error.response?.status === 429) {
        errorMessage = intl.formatMessage(WORK_HUB_EVENT_REGISTRATION_LIMIT_REACHED_MESSAGE)
      }
      essentialsRef.current?.contentWindow?.postMessage(
        {
          ...data,
          event: ESSENTIALS_IFRAME_MESSAGES.REGISTER_FOR_EVENT,
          success: false,
          error_message: errorMessage,
        },
        essentialsRef.current.contentWindow.origin,
      )
    }
  }

  const handleDeregisterForEvent = async (message: MessageEvent) => {
    const data = {
      zoom_event_id: message.data.zoom_event_id,
      zoom_event_type: message.data.zoom_event_type,
      registrant_id: message.data.registrant_id,
    }
    try {
      await axiosInstance.post(`/v1/zoom-event/unregister/${lyraId}`, data)
    } catch (error) {
      essentialsRef.current?.contentWindow?.postMessage(
        {
          ...data,
          event: ESSENTIALS_IFRAME_MESSAGES.DEREGISTER_FOR_EVENT,
          success: false,
          error_message: intl.formatMessage(WORK_HUB_EVENT_DEREGISTRATION_RETRY_MESSAGE),
        },
        essentialsRef.current.contentWindow.origin,
      )
      return
    }
    essentialsRef.current?.contentWindow?.postMessage(
      {
        ...data,
        event: ESSENTIALS_IFRAME_MESSAGES.DEREGISTER_FOR_EVENT,
        success: true,
      },
      essentialsRef.current.contentWindow.origin,
    )
  }
  const handleEventRegisteredCounts = async (message: MessageEvent) => {
    const data = {
      events: message.data.events,
      lyra_id: lyraId,
    }
    try {
      const response = await axiosInstance.post(`/v1/zoom-event/get-zoom-event-infos`, data)
      essentialsRef.current?.contentWindow?.postMessage(
        {
          event: ESSENTIALS_IFRAME_MESSAGES.EVENT_REGISTERED_COUNT,
          success: true,
          events: response.data.events,
        },
        essentialsRef.current.contentWindow.origin,
      )
    } catch (e) {
      essentialsRef.current?.contentWindow?.postMessage(
        {
          event: ESSENTIALS_IFRAME_MESSAGES.EVENT_REGISTERED_COUNT,
          success: false,
        },
        essentialsRef.current.contentWindow.origin,
      )
    }
  }

  const handleNavigateBetweenLibraryApps = (message: MessageEvent) => {
    const pathName = new URL(message.data.url).pathname
    let url
    if (pathName.includes('work-hub')) {
      navigate('/secure/index/work-hub')
      url = `${window.location.origin}/secure/index/work-hub`
      setContentPath('/secure/index/work-hub')
    } else {
      navigate('/secure/index/essentials')
      url = `${window.location.origin}/secure/index/essentials`
      setContentPath('/secure/index/essentials')
    }
    history.replaceState(null, '', url)
  }

  // Update the URL and browser tab title when a routeChange message is received.
  const handleRouteChange = (message: MessageEvent) => {
    let path
    if (isInitialLoadParamEnabled) {
      // The route change URL will include the initial load query param. This tries to remove it as cleanly as possible
      // so it does not get displayed to the user.
      const placeholderOrigin = 'https://placeholder.com'
      const messageUriAsURL = new URL(placeholderOrigin + String(message.data.uri))
      messageUriAsURL.searchParams.delete(ESSENTIALS_IS_INITIAL_LOAD_PARAM)
      path = String(messageUriAsURL.href).replace(placeholderOrigin, '')
    } else {
      path = message.data.uri
    }

    const newUrl = new URL(window.location.href)
    const pathName = newUrl.pathname

    let url

    if (pathName.includes('work-hub')) {
      url = `${window.location.origin}/secure/index/work-hub${path}`
    } else {
      url = `${window.location.origin}/secure/index/essentials${path}`
    }

    // If the content is an ungated article we do not wish to update the URL
    if (!pathname.startsWith(`${PAGE_ROUTES.ESSENTIALS_UNGATED_CONTENT}`) && !isURLPathForEssentialsPreviewContent()) {
      history.replaceState(null, '', url)
    }

    if (path === '/' || path === '/#0') {
      // Essentials page was forwarded into an article instead of home tab. This will need to rerender/re-navigate the original Essentials.
      if (parentAppReferrer) {
        navigate('/secure/index/essentials/')
      } else {
        document.title = `${essentialsTitle} | ${LYRA_HEALTH_NAME}`
      }
    } else {
      setTimeout(() => {
        const essentialsIframe: HTMLIFrameElement | null = document.querySelector('iframe[title="Essentials"]')
        if (essentialsIframe) {
          const essentialsTitlePieces = essentialsIframe.contentDocument?.title?.split('|')
          document.title = `${essentialsTitlePieces![0]} | ${LYRA_HEALTH_NAME}`
        }
      }, 500) // Timeout required for the iframe document.title to be updated
    }
  }

  useEffect(() => () => window.clearInterval(intervalRef.current), [])
  return (
    <>
      <div className={styles['loading-container']} style={isEssentialsLoading ? undefined : { display: 'none' }}>
        <LoadingIndicator size={45} />
      </div>
      <iframe
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        title='Essentials'
        onLoad={onIFrameLoad}
        ref={essentialsRef}
        src={essentialsUrl}
        width='100%'
        height={iFrameHeight}
        scrolling='no'
        frameBorder='0'
      />
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    lyraId: getId(state),
    userDisplayLanguage: getUserDisplayLanguage(state),
    deviceUUID: getDeviceUUID(state),
  }
}

const mapDispatchToProps = {
  trackEventWithObj,
  setTimeInactivityWarning,
  setTimeLastActive,
  setTriageEntryPoint,
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Essentials))
