import { useCallback, useEffect, useMemo, useState } from 'react'
import { NavigateFunction } from 'react-router-dom'

import { OktaAuth } from '@okta/okta-auth-js'
import queryString from 'query-string'

import { UNAUTHENTICATED_REDIRECT_URL } from '@lyrahealth-inc/lyraweb-mobile/src/hooks/useShareSessionStorageAcrossTabs'
import {
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  getShowProviderResultsSurveyUsingDateLastSeen,
  IDENTITY_PROVIDERS,
  LOGIN_EVENTS,
  LoginResponse,
  PROVIDER_RESULTS_SURVEY_DATE_LAST_SEEN_KEY,
  SHOW_PROVIDER_RESULTS_SURVEY_KEY,
} from '@lyrahealth-inc/shared-app-logic'

import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { makeOktaAuthOptions } from '../../features/login/loginUtils'
import { ATTESTATION_VERIFICATION } from '../constants/appConstants'
import { GENERIC, INVALID, REGISTERED } from '../constants/errorConstants'
import { BASIC_INFO_FORM_PATH } from '../constants/registrationFormConstants'

type UseAuthenticateProps = {
  login: Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  trackEventWithObj: Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  markUserAsStale: Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  mixpanelInitialized: boolean
  getOktaSession?: () => Promise<{ idp?: { id: string; type: string } }>
  navigate: NavigateFunction
  oktaClientId: string
  oktaBaseUrl: string
  oktaAppleSSOClientId: string
  oktaGoogleSSOClientId: string
  setSsoIdentityProvider: Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  setSsoToken: Function // eslint-disable-line @typescript-eslint/no-unsafe-function-type
  userId?: string
  customerId?: string
}
const useAuthenticate = ({
  login,
  trackEventWithObj,
  markUserAsStale,
  mixpanelInitialized,
  getOktaSession,
  navigate,
  oktaClientId,
  oktaBaseUrl,
  oktaAppleSSOClientId,
  oktaGoogleSSOClientId,
  setSsoIdentityProvider,
  setSsoToken,
  userId,
  customerId,
}: UseAuthenticateProps) => {
  const { code, state, error, error_description, fromLogin } = queryString.parse(window.location.search) || {}

  // For SSO with partner identity providers:
  // Okta sets a `fromLogin` query parameter in the redirect after authenticating a user from a federated identity provider
  const isLoginFromPartnerSSO = fromLogin === 'true'

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(
    (Boolean(code) && Boolean(state)) || isLoginFromPartnerSSO,
  )
  const [loginEvent, setLoginEvent] = useState<LOGIN_EVENTS>()
  const [loginEventProps, setLoginEventProps] = useState({})
  const [hasTrackedLogin, setHasTrackedLogin] = useState(false)

  const doLogin = useCallback(async () => {
    try {
      const loginResponse: LoginResponse = await login({ code, redirect_uri: window.location.origin + '/login/okta' })
      markUserAsStale()
      setIsAuthenticating(false)
      if (getOktaSession) {
        getOktaSession()
          .then((session) => {
            if (session.idp?.type === 'SOCIAL') {
              if (session.idp?.id === oktaAppleSSOClientId) {
                setLoginEvent(LOGIN_EVENTS.LOGIN_APPLE_SSO)
              } else if (session.idp?.id === oktaGoogleSSOClientId) {
                setLoginEvent(LOGIN_EVENTS.LOGIN_GOOGLE_SSO)
              }
            } else if (session.idp?.type === 'FEDERATION') {
              setLoginEvent(LOGIN_EVENTS.LOGIN_PARTNER_SSO)
              if (loginResponse.partnerIdp?.idpId === session.idp?.id) {
                setLoginEventProps({ [COMMON_MIXPANEL_PROPERTIES.PARTNER_NAME]: loginResponse.partnerIdp.shortName })
              } else {
                console.error('Unexpected: Partner IDP mismatch between Okta session and login response!')
              }
            } else {
              setLoginEvent(LOGIN_EVENTS.LOGIN)
            }
          })
          .catch((error) => {
            console.warn('Failed to get Okta session', error)
            setLoginEvent(LOGIN_EVENTS.LOGIN)
          })
      } else {
        setLoginEvent(LOGIN_EVENTS.LOGIN)
      }

      if (loginResponse.ssoUserInfo) {
        // unregistered user from partner SSO, redirect to registration
        setSsoIdentityProvider(IDENTITY_PROVIDERS.PARTNER)
        setSsoToken('')
        navigate(
          {
            pathname: BASIC_INFO_FORM_PATH,
            search: `?${new URLSearchParams({ id: loginResponse.ssoUserInfo.email, isSSO: 'true' })}`,
          },
          { state: loginResponse },
        )
      } else if (loginResponse.needsAttestation) {
        const attestationUrl = `/verify-attestation?token=${loginResponse.needsAttestation.token}&id=${loginResponse.needsAttestation.id}&showEligibility=${loginResponse.needsAttestation.showEligibility}`
        window.localStorage.setItem(ATTESTATION_VERIFICATION, attestationUrl)
        navigate(attestationUrl)
      } else {
        window.sessionStorage.removeItem(ATTESTATION_VERIFICATION)
        const redirectUrl = window.sessionStorage.getItem(UNAUTHENTICATED_REDIRECT_URL)
        if (redirectUrl) {
          window.sessionStorage.removeItem(UNAUTHENTICATED_REDIRECT_URL)
          navigate(redirectUrl, { replace: true })
        } else {
          navigate('/secure/index')
        }
      }
    } catch {
      setIsAuthenticating(false)
      navigate('/login')
    }
  }, [
    login,
    code,
    markUserAsStale,
    getOktaSession,
    oktaAppleSSOClientId,
    oktaGoogleSSOClientId,
    setSsoIdentityProvider,
    setSsoToken,
    navigate,
  ])

  useEffect(() => {
    const confirmationEmailErrors = [REGISTERED, INVALID, GENERIC]
    const queryParams = queryString.stringify({ error, error_description })
    if (mixpanelInitialized) {
      if (error) {
        trackEventWithObj({
          event: LOGIN_EVENTS.LOGIN_FAILED,
        })
      }
      if (error && typeof error === 'string' && !confirmationEmailErrors.includes(error)) {
        navigate(`/login?${queryParams}`)
      } else if (error && typeof error === 'string' && error === REGISTERED) {
        navigate(`/register/verify?${queryParams}`)
      }
    }
  }, [error, error_description, navigate, trackEventWithObj, mixpanelInitialized])

  const oktaAuth = useMemo(() => {
    if (oktaBaseUrl && oktaClientId) {
      return new OktaAuth(makeOktaAuthOptions(oktaClientId, oktaBaseUrl, window.location.origin))
    }
    return undefined
  }, [oktaBaseUrl, oktaClientId])

  useEffect(() => {
    if (isLoginFromPartnerSSO && oktaAuth) {
      // The user should have a session with Okta after authenticating with the federated identity provider
      // This starts the OIDC authentication code flow to obtain tokens and log in with LyraWeb
      oktaAuth.token.getWithRedirect()
    } else if (isAuthenticating && mixpanelInitialized) {
      doLogin()
    }
  }, [doLogin, mixpanelInitialized, isAuthenticating, oktaBaseUrl, oktaClientId, isLoginFromPartnerSSO, oktaAuth])

  // Call mixpanel track, set alias and register super props
  useEffect(() => {
    if (!hasTrackedLogin && !!userId && !!customerId && !!loginEvent) {
      mixpanel.register({
        lyra_id: userId,
        customer_id: customerId,
      })
      mixpanel.alias(userId)
      trackEventWithObj({
        page: COMMON_MIXPANEL_PAGE.LOGIN,
        event: loginEvent,
        ...loginEventProps,
      })
      setHasTrackedLogin(true)
      const showProviderResultsSurveyUsingDateLastSeen = getShowProviderResultsSurveyUsingDateLastSeen({
        providerResultsSurveyDateLastSeen:
          localStorage.getItem(PROVIDER_RESULTS_SURVEY_DATE_LAST_SEEN_KEY) ?? undefined,
      })
      if (showProviderResultsSurveyUsingDateLastSeen) {
        localStorage.setItem(SHOW_PROVIDER_RESULTS_SURVEY_KEY, 'true')
      } else {
        localStorage.setItem(SHOW_PROVIDER_RESULTS_SURVEY_KEY, 'false')
      }
    }
  }, [customerId, hasTrackedLogin, loginEvent, loginEventProps, trackEventWithObj, userId])

  return isAuthenticating
}

export default useAuthenticate
