import { VENDOR_BASE_API_URL } from './constants'

// When naming new mixpanel events please follow the best practices: https://lyrahealth.atlassian.net/wiki/spaces/DS/pages/2598110057/Mixpanel+event+naming+best+practices
export enum COMMON_BCX_SCREENS {
  GUIDE = 'guide',
  ACTIVITIES_LIST = 'activities list',
  LESSON_OVERVIEW = 'lesson overview',
  LESSON_VIDEO_PLAYER = 'lesson video player',
  VIDEO_PLAYER = 'video player',
  LESSON_REVIEW = 'lesson review',
  EXERCISE_OVERVIEW = 'exercise overview',
  PASSIVE_SI_MESSAGE = 'passive SI message',
  ACTIVE_SI_MESSAGE = 'active SI message',
  HOMEBASE = 'homebase',
  PROGRESS = 'progress tab',
  SUMMARY_PAGE = 'summary page',
  GRADUATION_PAGE = 'graduation page',
  ESSENTIALS = 'essentials',
  VIDEO_SESSION_COMPLETED = 'video session completed',
  COMPLEX_CARE_LANDING_PAGE = 'complex care landing page',
  COMPLEX_CARE_SUB_PAGE = 'complex care programs subpage',
}

export enum COMMON_REGISTRATION_SCREENS {
  REGISTRATION_FORM_DEFAULT = 'registration form - default',
  REGISTRATION_FORM_BACKGROUND_INFO = 'registration form - background info',
  REGISTRATION_FORM_CARE_TEAM = 'registration form - care team',
  REGISTRATION_FORM_HPI_EMAIL = 'registration form - HPI email',
  REGISTRATION_FORM_PARENT_LED_TEEN = 'registration form - parent-led lct teen booking',
  REGISTRATION_FORM_AUTO_ENROLLMENT = 'registration form - auto enrollment',
}

export enum COMMON_ELIGIBILITY_SCREENS {
  CONFIRM_ELIGIBILITY_FORM = 'confirm eligibility form',
}

export type MIXPANEL_EVENT =
  | COMMON_MIXPANEL_EVENTS
  | (typeof BCX_EVENTS)[keyof typeof BCX_EVENTS]
  | (typeof S4C_EVENTS)[keyof typeof S4C_EVENTS]
  | (typeof REGISTRATION_EVENTS)[keyof typeof REGISTRATION_EVENTS]
  | (typeof ELIGIBILITY_EVENTS)[keyof typeof ELIGIBILITY_EVENTS]
  | TRIAGE_EVENTS
  | LATEST_PROVIDER_EVENTS
  | LOGIN_EVENTS
  | PATHWAYS_EVENTS

export enum COMMON_MIXPANEL_EVENTS {
  BUTTON_PRESS = 'Button Press',
  ESSENTIALS_ERROR = 'view essentials error',
  COMPLEX_CARE_ERROR = 'view complex care error',
  ELECTION_ERROR = 'view election error',
  NEW_YEAR_TOOLKIT_ERROR = 'view new year toolkit error',
  FILTER_CHANGE = 'Filter Change',
  GENERIC_ERROR = 'view generic error',
  PAGE_LOAD = 'Page Load',
  CHAT_COUNTRY_DETECTION_FAILURE = 'Chat country detection failure',
  CLICK = 'click',
  CLICK_LYRA_HEALTH_APP = 'click lyra health app',
  CLICK_LYRA_LEARN = 'click lyra learn',
  CLICK_EXTERNAL_RESOURCE = 'click external resource',
  CLICK_SCHEDULE_A_CN_CALL = 'click schedule a cn call',
  CLICK_PHONE_CN = 'click cn phone contact',
  CLICK_EMAIL_CN = 'click email cn',
  ERROR_BANNER = 'view error banner',
  CLICK_HAMBURGER_MENU = 'click hamburger menu',
  CLICK_MY_BENEFITS = 'click my benefits',
  CLICK_ACCOUNT = 'click account',
  CLICK_FAQS = 'click FAQs',
  CLICK_LOGOUT = 'click logout',
}

export type MIXPANEL_PROPERTY =
  | COMMON_MIXPANEL_PROPERTIES
  | S4C_PROPERTIES
  | ELIGIBILITY_PROPERTIES
  | LATEST_PROVIDER_PROPERTIES
  | INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES
  | HEALTHCARE_BOOKING_PROPERTIES
  | WELLNESS_TOOLKIT_EVENTS_PROPERTIES
  | PROVIDER_VIDEO_PROPERTIES
  | PATHWAYS_PROPERTIES
  | ZOOM_PROPERTIES
  | ERROR_BANNER_PROPERTIES
  | URGENCY_TO_BOOK_PROPERTIES

export enum COMMON_MIXPANEL_PROPERTIES {
  REGISTERED_COUNTRY = 'registered_country',
  GUIDE_NAME = 'guideName',
  ASSIGNMENT_ID = 'assignmentId',
  PROGRAM = 'program',
  ASSESSMENT_NAME = 'assessmentName',
  ASSESSMENT_TYPE = 'assessmentType',
  EXERCISE_NAME = 'exerciseName',
  PERCENT_PROGRESS = 'percentProgress',
  LESSON_NAME = 'lessonName',
  LESSON_STATUS = 'lessonStatus',
  SCREEN = 'screen',
  PREVIOUS_SCREEN = 'previousScreen',
  PAGE = 'page',
  ACTION = 'action',
  CTA_CLICKED = 'cta_clicked',
  CUSTOMER_ID = 'customer_id',
  CUSTOMER_NAME = 'customer_name',
  DETAILS = 'details',
  ERROR_TYPE = 'error_type',
  ERROR_DETAIL = 'error_detail',
  STATUS = 'status',
  SELECTED_COUNTRY = 'selectedCountry',
  ENTRY_POINT = 'entryPoint',
  WORKLIFE_SERVICE = 'worklife_service',
  APP_ID = 'app_id',
  APP_PLATFORM = 'app_platform',
  LYRA_ID = 'lyra_id',
  CUSTOMER_CONFIGURATION = 'customer_configuration',
  COUNTRY_FLAG = 'country_flag',
  USER_TIMEZONE = 'user_timezone',
  MESSAGE_LOCATION = 'message_location',
  RESOURCE_NAME = 'resource_name',
  BUTTON_TEXT = 'button_text',
  COUNTRY_CATEGORY = 'country_category',
  ATTEMPT_COUNT = 'attempt_count',
  PARTNER_NAME = 'partner_name',
  EVENT = 'event',
  TRIGGER = 'trigger',
  REFERRER_PAGE = 'referrer_page',
  TEXT_INPUT = 'text_input',
  PREFERRED_NAME = 'preferred_name',
  VALUES = 'values',
  ERRORS = 'errors',
  // Used for experiments
  ARM = 'arm',
  NOTIFICATION_TYPE = 'notification_type',
  NOTIFICATION_BADGE_SHOWN = 'notification_badge_shown',

  // on LW USER ID is automatically added
  USER_ID = ' User ID',
  LD_DEVICE_UUID = 'ld_device_uuid',
  TAB_ID = 'tab_id',
}

export enum ERROR_BANNER_PROPERTIES {
  ENDPOINT_URL = 'endpoint_url',
  ENDPOINT_METHOD = 'endpoint_method',
  HTTP_ERROR_CODE = 'http_error_code',
  ERROR_MESSAGE = 'api_error_message',
  LYRA_ERROR_MESSAGE = 'lyra_error_message',
}

export enum INTEGRATED_BENEFITS_MIXPANEL_PROPERTIES {
  BENEFITS = 'benefits',
  MATCH_FOR = 'match_for',
}

export enum S4C_PROPERTIES {
  PRIMARY_RECOMMENDATIONS = 'primary_recommedations',
  SECONDARY_RECOMMENDATIONS = 'secondary_recommedations',
  CHILD_LYRA_ID = 'child_lyra_id',
  CHILD_LYRA_IDS = 'child_lyra_ids',
  MATCH_FOR = 'match_for',
  HEALTH_PLAN_NAME = 'health_plan_name',
  MATCHER_REQUEST_ID = 'matcher_request_id',
  SEARCH_ID = 'search_id',
  TREATMENT_OPTION = 'treatmentOption',
  IS_PRIMARY_RECOMMENDATION = 'is_primary_recommendation',
  CTA_ENTRYPOINT = 'CTA_entrypoint',
  MEETING_FORMAT = 'meetingFormat',
  CLIENT_TIER_LEVEL = 'client_tier_level',
  CLINICAL_TIER = 'clinicalTier',
  CLINICAL_TIER_WITH_EXCLUSIONS = 'clinicalTierWithExclusions',
  PAGE_LAYOUT = 'pageLayout',
  RECOMMENDED_PROGRAMS = 'recommendedPrograms',
  ASSERTIVE_TRIAGE = 'assertive_triage',
  PROVIDER_ID = 'providerID',
  CALENDAR_SLOT_CLICKED = 'calendar_slot_clicked',
  NUM_PROVIDERS_SHOWN = 'numProvidersShown',
  ONSITE = 'onsite',
  PROVIDERS_SHOWN = 'providersShown',
  NUM_CALENDAR_SLOTS = 'num_calendar_slots',
  CALENDAR_SLOTS_SHOWN = 'calendar_slots_shown',
  DIRECT_LINK = 'directLink',
  DIRECT_ACCESS_OFFERED = 'direct_access_offered',
  SESSION_COST = 'session_cost',
  OTHER_NEED = 'otherNeed',
  PRIMARY_NEED = 'primaryNeed',
  HOURS_TO_INITIAL_SESSION = 'hoursToInitialSession',
  PROVIDER_TYPE = 'providerType',
  PREFERENCE_CATEGORY = 'preference_category',
  PREFERENCE_CATEGORIES = 'preference_categories',
  PREFERENCE_DETAIL = 'preference_detail',
  PREFERENCES_SHOWN = 'preferences_shown',
  CN_entrypoint = 'CN_entrypoint',
  ENTRYPOINT_DETAIL = 'entrypoint_detail',
  RESULTS_TYPE = 'results_type',
  CONTACT_OPTIONS = 'contact_options',
  CHILD_CONCERN_TYPE = 'concern_type',
  HAS_PREVIOUS_SEARCH = 'has_previous_search',
  REQUEST_TYPE = 'request_type',
  LOCATION_INPUT = 'location_input',
  IN_PERSON_PROVIDERS_SHOWN = 'in_person_providers_shown',
  IS_DIRECT_PATH = 'is_direct_path',
  TREATMENT_OPTION_CTPO = 'treatment_option_ctpo',
  HAS_CONNECTED_CALENDAR = 'has_connected_calendar',
  PROVIDER_RANK = 'provider_rank',
  DAY_OF_WEEK = 'day_of_week',
  THERAPY_MODALITY = 'therapyModality',
  DIRECT_LINK_INTENT = 'direct_link_intent',
  DIRECT_LINK_SOURCE = 'direct_link_source',
  SHOWN_CLINICAL_CONDITIONS = 'shown_clinical_conditions',
  AGE = 'age',
  GUARDIAN_AGE = 'guardian_age',
  TESTIMONIAL_INDEX = 'testimonial_index',
  SELECTED_MODALITY = 'selected_modality',
  CONTACT_INFO_CHECKBOX = 'contact_info_checkbox',
  AI_BIO = 'AI bio',
  PROVIDER_DETAIL = 'provider_detail',
  SMS_CONSENT = 'sms_consent',
  PHONE_TYPE = 'phone_type',
  SURVEY_RESPONSE = 'survey_response',
  PROVIDER_AVAILABLE_SLOTS = 'provider_available_slots',
  AVAILABILITY_PREFERENCE_MATCH = 'availability_preference_match',
  MATCHED_CALENDAR_SLOTS_SHOWN = 'matched_calendar_slots_shown',
  SHOWN_INPRODUCT_SURVEY = 'shown_inproduct_survey',
}

export enum SURVEY_RESPONSE_PROPERTIES {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export enum ELIGIBILITY_PROPERTIES {
  EAP_ELIGIBILITY_UNCONFIRMED_BANNER = 'eap_eligibility_unconfirmed_banner',
  ELIGIBILITY_ERROR_CAUSE = 'eligibility_error_cause',
  ELIGIBILITY_TYPE = 'eligibility_type',
}

export enum ELIGIBILITY_PROPERTY_VALUE {
  ELIGIBILITY_ERROR_CAUSE_INELIGIBILITY = 'ineligibility',
  ELIGIBILITY_ERROR_CAUSE_UNEXPECTED_ERROR = 'unexpected error',
  ELIGIBILITY_BANNER_ENTRY_POINT = 'eligibility banner',
  REQUIRE_ELIGIBILITY_ENTRY_POINT = 'hard eligibility check',
}

/** `page` prop values */
export enum COMMON_MIXPANEL_PAGE {
  VIDEO_SESSION = 'video session',
  COACHING_SESSION_MODALITY = 'Coaching - Session Modality',
  MEDS_PROGRAM_OPTIONS = 'med program options',
  PROGRAM_UNAVAILABLE = 'Program Unavailable',
  CONTACT_CARE_TEAM_NAVIGATOR = 'Contact Care Navigator Team',
  CLINICAL_SCREENERS = 'Clinical Screeners',
  LOCATION = 'location',
  PROVIDER_RESULTS = 'Provider Results',
  PROVIDER_PROFILE = 'Provider Profile',
  PROVIDER_SCHEDULING_CALENDAR = 'Provider Scheduling Calendar',
  INTERNATIONAL_CONSENT_FOR_DATA_COLLECTION = 'gdpr consent',
  HPI_OVERVIEW = 'hpi overview',
  HPI_CONFIRMATION = 'hpi confirmation',
  ADD_HEALTH_PLAN = 'add health plan',
  ADD_HPI_PAYMENT_METHOD = 'add hpi payment method',
  ADD_INFRACTION_PAYMENT_METHOD = 'add infraction payment method',
  APPOINTMENT_BOOKING_PAYMENT = 'appointment booking payment',
  TREATMENT_RECOMMENDATIONS = 'treatment recommendations',
  ASSERTIVE_TRIAGE_TREATMENT_OPTIONS = 'assertive triage treatment options',
  RECOMMENDATIONS_EXPLAINED_PAGE = 'Recommendations Explained Page',
  PREFERENCES_CATEGORY_PAGE = 'Preferences Category Page',
  PREFERENCES_CATEGORY_V2_PAGE = 'Preferences Category V2 Page',
  PROFILE = 'profile',
  AUD_UNAVAILABLE = 'aud unavailable',
  ZERO_SEARCH_RESULTS_REDIRECT = 'zero search results redirect',
  CARE_PREFERENCES = 'care preferences',
  APPOINTMENT_SELECTION = 'appointment selection',
  ZERO_RESULTS = 'zero results',
  ZERO_TREATMENT_RECOMMENDATIONS = 'zero treatment recommendations',
  GENERAL_CN_CONTACT_PAGE = 'general CN contact page',
  ASSERTIVE_TRIAGE = 'assertive triage',
  CHILD_DIRECT_CN_PATH = 'child direct cn path',
  APPOINTMENT_CONFLICT_MODAL = 'appointment conflict modal',
  TEN_LOCATION_SEARCHES = 'ten location searches',
  PROVIDER_LOADING_SCREEN = 'Provider loading screen',
  APPOINTMENT_BOOKING = 'Appointment booking',
  GLOBAL_NAV_CARE_NAVIGATOR = 'global nav care navigator',
  CN_CONTACT_CONFIRMATION_PAGE = 'CN contact confirmation page',
  CHILD_SELECTION_FORM = 'child selection form',
  APPOINTMENT_REQUEST = 'appointment request',
  INTEGRATED_BENEFITS_PAGE = 'integrated benefits page',
  REGISTER_BY_EMAIL = 'Register by Email input',
  SESSIONS = 'Sessions',
  ASSIGNMENTS_HOME = 'Assignments Home',
  HOME_BASE = 'homebase',
  NONE = 'N/A',
  MICROSITE = 'microsite',
  FIND_EMPLOYER = 'find employer',
  FORKED_TRIAGE = 'forked triage',
  FAMILY_CARE_OPTIONS = 'family care options',
  WELLNESS_TOOLKIT = 'wellness toolkit',
  ELECTION_STRESS_TOOLKIT = 'election stress toolkit',
  ADD_FAMILY_MEMBER = 'Family Hub - Add family member',
  SELF_MANAGED_DEPENDENT_CONFIRMATION = 'Family Hub - Self managed dependent confirmation',
  FAMILY_HUB = 'family hub',
  CARE_TAB = 'care tab',
  PROVIDER_CARE_PATHWAY = 'provider care pathway', // Forked triage
  NEW_YEAR_TOOLKIT = 'new year toolkit',
  MARKETING_TOOLKIT = 'marketing toolkit',
  ESSENTIALS = 'essentials',
  LOGIN = 'login',
  ESSENTIALS_HOMEPAGE_WIDGET = 'essentials homepage widget',
  CONTACT_CARE_TEAM_CONFIRMATION = 'Contact Care Navigator Team - Confirmation',
  WELLNESS_CHECK_IN = 'wellness check-in',
  RESOURCE_COORDINATOR = 'resource coordinator page',
  NICOTINE_QUITLINE_MICROSITE = 'nicotine microsite page',
  CHILD_INFORMATION = 'create new child page for search',
  CHILD_SELECTION = 'existing child selection page for search',
  PAST_ACTIVITIES = 'past activities list',
  WELCOME_SPLASH = 'logged out home',
  WELCOME_TO_APP = 'welcome to app',
  BIOMETRICS_PROMPT = 'biometrics prompt',
  RESOURCE_COORDINATOR_MICROSITE = 'resource coordinator microsite',
  MESSAGE_PROMPT = 'message prompt',
  DISCLAIMER = 'disclaimer',
  JAILBROKEN_DEVICE_DISABLED = 'jailbroken device disabled',
  VIDEO_CALL_PREVIEW = 'video call preview',
  ACCOUNT_NOT_FOUND = 'account not found',
  LYRAWEB_MOBILE_DISABLED = 'Lyraweb mobile disabled',
  WEEK_ACTIVITIES = 'week activities',
  ZERO_COACHING_RESULTS = '0 results coaching page',
  MESSAGES = 'Messages',
  LCX_CLIENT_ONBOARDING = 'LCx client onboarding',
  REBOOK = 'rebook',
  SATISFACTION_SURVEY = 'Satisfaction Survey',
  LT_PROGRESS = 'LT Progress',
  UNSUBSCRIBE = 'Unsubscribe',
}

export enum COMMON_SEARCH_FOR_CARE_SCREENS {
  CHILD_SELECTION = 'existing child selection page for search',
  CHILD_INFORMATION = 'create new child page for search',
  CHILD_CONCERN = 'care advocate page for child',
  TRIAGE = 'triage',
  LOCATION_FINDER = 'location input',
  SEARCH_FOR_CARE_LOADING = 'provider loading screen',
  PROVIDER_RESULTS = 'provider recommendations',
  PROVIDER_PROFILE = 'provider profile',
  CALENDAR_BOOKING = 'provider scheduling calendar',
  APPOINTMENT_BOOKING = 'appointment booking page',
  APPOINTMENT_REQUEST = 'appointment request page',
  CHILD_CN_EMAIL_REQUEST = 'child cn email request page',
  TRIAGE_SUMMARY = 'triage summary page',
  HIGH_RISK_MODAL = 'high risk modal',
}

/** `message_location` prop values */
export enum MESSAGE_LOCATION {
  POST_TRIAGE = 'post triage',
  POST_ASSESSMENT = 'post assessment',
  WORDCLOUD = 'word cloud',
}

/** `button_text` prop values */
export enum MIXPANEL_BUTTON_TEXT {
  CONTACT_A_CARE_NAVIGATOR = 'Contact a Care Navigator',
  GET_IN_TOUCH = 'Get in touch',
}

/** `country_category prop values */
export enum COUNTRY_CATEGORY {
  DOMESTIC = 'Domestic',
  INTERNATIONAL = 'International',
}

export enum LOGIN_EVENTS {
  CLICK_LOGIN = 'click log in', // for login CTA on splash screen that takes user to log in screen
  VIEW_LOGIN_PAGE = 'view login page', // Logged by useTrackScreen for mobile
  LOGIN = 'login', // successful user login (non sso)
  LOGIN_GOOGLE_SSO = 'login with google sso',
  LOGIN_PARTNER_SSO = 'login with partner sso',
  LOGIN_APPLE_SSO = 'login with apple sso',
  REQUEST_PASSWORD_RESET = 'request password reset',
  TAPS_BIOMETRICS_PROMPT = 'taps biometrics prompt',
  LOGIN_FAILED = 'login failed',
  CLICK_LOG_OUT = 'click log out',
}

export const ELIGIBILITY_EVENTS = {
  VIEW_CONFIRM_ELIGIBILITY_FORM: `view ${COMMON_ELIGIBILITY_SCREENS.CONFIRM_ELIGIBILITY_FORM}`,
  VIEW_CONFIRM_ELIGIBILITY_BANNER: 'view confirm eligibility banner',
}

export enum LCT_TEEN_EMAIL_ERROR_TYPE {
  REGISTERED_EMAIL = 'registered email',
  BELONGS_TO_SELF = 'same as current user',
}

export enum REGISTRATION_PAGES {
  EMAIL_CAPTURE = 'email capture',
  PARENT_LED_LCT_TEEN_WELCOME_PAGE = 'parent-led lct teen welcome',
}

export enum REGISTRATION_ENTRY_POINTS {
  PARENT_LED_LCT_TEEN_BOOKING = 'parent-led lct teen booking',
  FAMILY_HUB_TEEN_INVITATION = 'family hub teen invitation',
  FAMILY_HUB_ADULT_INVITATION = 'family hub adult invitation',
  AUTO_ENROLLMENT_REGISTRATION = 'auto enrollment',
  WELLNESS_CHECK_IN = COMMON_MIXPANEL_PAGE.WELLNESS_CHECK_IN,
}

export const REGISTRATION_EVENTS = {
  // Create an Account / Find Employer / Email Capture Actions
  CLICK_CREATE_AN_ACCOUNT: 'click create an account',
  SUBMIT_ORG_NAME: 'submit organization name',
  REQUEST_EMAIL_VERIFICATION: 'Request Email Verification',
  CLICK_SIGN_UP_WITH_GOOGLE: 'Click Sign up with Google',
  CLICK_SIGN_UP_WITH_APPLE: 'Click Sign up with Apple',
  RESEND_EMAIL_VERIFICATION: 'Resend email verification',
  CLICK_OPEN_IN_GMAIL: 'click open in gmail',
  CLICK_OPEN_IN_YAHOO: 'click open in yahoo',
  CLICK_OPEN_IN_OUTLOOK: 'click open in outlook',
  CLICK_OPEN_IN_APPLE_MAIL: 'click open in apple mail',
  INPUT_INCORRECT_COMPANY_NAME: 'input incorrect company name',
  CLICK_UPDATE_EMAIL_ADDRESS: 'click update email address',
  SUBMIT_REGISTRATION_FORM: 'submit registration form',

  // Views
  VIEW_EMAIL_VERIFICATION_PAGE: 'View email verification page',
  VIEW_REGISTRATION_FORM_DEFAULT: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_DEFAULT}`,
  VIEW_REGISTRATION_FORM_BACKGROUND_INFO: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_BACKGROUND_INFO}`,
  VIEW_REGISTRATION_FORM_CARE_TEAM: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_CARE_TEAM}`,
  VIEW_REGISTRATION_FORM_HPI_EMAIL: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_HPI_EMAIL}`,
  VIEW_REGISTRATION_FORM_PARENT_LED_TEEN: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_PARENT_LED_TEEN}`,
  VIEW_REGISTRATION_FORM_AUTO_ENROLLMENT: `View ${COMMON_REGISTRATION_SCREENS.REGISTRATION_FORM_AUTO_ENROLLMENT}`,
  VIEW_FIND_EMPLOYER_PAGE: `view ${COMMON_MIXPANEL_PAGE.FIND_EMPLOYER} page`,

  // Eligibility
  EAP_SOFT_ELIGIBILITY_CHECK_ERROR: 'view eap soft eligibility check error',
  EAP_SOFT_ELIGIBILITY_CHECK_SUCCESS: 'succeed eap soft eligibility check',
  EAP_HARD_ELIGIBILITY_CHECK_ERROR: 'view eap hard eligibility check error',
  EAP_HARD_ELIGIBILITY_CHECK_SUCCESS: 'succeed eap hard eligibility check',

  // 2-instance redirection
  CLICK_REDIRECTION_LINK: 'click redirection link',
  SELECT_REDIRECTION_MODAL_OPTION: 'select redirection modal option',

  REGISTRATION_BACKGROUND_INFO_LEARN_MORE_PRIVACY_POLICY: 'Background info - learn more privacy policy',

  REGISTRATION_SUCCESS: 'Register: Success',
  REGISTRATION_GOOGLE_SSO_SUCCESS: 'Register GoogleSSO: Success',
  REGISTRATION_APPLE_SSO_SUCCESS: 'Register AppleSSO: Success',
  REGISTRATION_PARTNER_SSO_SUCCESS: 'Register PartnerSSO: Success',
  ONBOARDING_SUCCESS: 'onboarding success',
  REGISTRATION_FAILED: 'Registration Failed',
  ONBOARDING_FAILED: 'onboarding failed',
  ONBOARDING_SKIPPED: 'onboarding skipped',

  AUTO_ENROLLMENT_DOB_HARD_CHECK_ERROR: 'auto enrollment dob hard check error',
  AUTO_ENROLLMENT_INVALID_TOKEN: 'auto enrollment invalid token redirect',
}

export enum REGISTRATION_PROPERTIES {
  HAS_VALIDATION_ERROR = 'has_validation_error',
  REGISTRATION_TYPE = 'registration_type',
}

export const FAMILY_HUB_EVENTS = {
  CLICK_FAMILY_HUB_EXPLORE_YOUR_OPTIONS: 'click family hub explore your options',
  CLICK_ADD_DEPENDENT: 'click add a dependent',
  CLICK_CONTINUE_ON_ADD_DEPENDENT: 'click continue from add a dependent form',
  CLICK_SEND_INVITE_ON_ADD_DEPENDENT: 'click continue to finish non-child account creation',
  CLICK_GOT_IT_ON_SELF_MANAGED_CONFIRMATION_PAGE: 'click got it to exit add a dependent',
  CLICK_GET_STARTED_DEPENDENT_CONFIRMATION_PAGE: 'click get started after adding child dependent account',
  CLICK_FIND_CARE_LATER_DEPENDENT_CONFIRMATION_PAGE: 'click find care later after adding child dependent account',
  FAMILY_CARE_OPTIONS_PARENT_COACHING: 'family care options parent coaching',
}

export const BCX_EVENTS = {
  JOIN_TEXT_SESSION: 'join live messaging session',
  JOIN_VIDEO_SESSION: 'join video session',
  OPEN_VIDEO_SESSION: 'open video session',
  END_VIDEO_SESSION: 'end video session',
  CLOSE_VIDEO_SESSION: 'close video session',
  GET_VIDEO_SESSION_DATA: 'get video session data',
  SEND_MESSAGE: 'send message',
  SEND_MESSAGE_AGAIN: 'message send failure try again',
  DELETE_MESSAGE: 'message send failure delete',
  START_EXERCISE_ENTRY: 'start exercise entry',
  OPEN_EXERCISE_DRAFT: 'open exercise draft',
  DELETE_EXERCISE_DRAFT: 'delete exercise draft',
  SAVE_EXERCISE_DRAFT: 'save exercise draft',
  SUBMIT_EXERCISE_ENTRY: 'submit exercise entry',
  REVIEW_SUBMITTED_EXERCISE: 'review submitted exercise',
  START_ASSESSMENT: 'start assessment',
  RESUME_ASSESSMENT: 'resume assessment',
  SUBMIT_ASSESSMENT: 'submit assessment',
  REVIEW_SUBMITTED_ASSESSMENT: 'review submitted assessment',
  OPEN_GUIDE_LINK: 'open guide link',
  SUBMIT_LESSON_REVIEW: 'submit lesson review',
  SUBMIT_VIDEO_SESSION_FEEDBACK: 'submit video session feedback form',
  VIDEO_SESSION_ERROR: 'view video session error modal',
  CONFIRM_JOIN_SESSION: 'confirm join session',
  CLICK_END_CALL_BUTTON: 'click end call button',
  YES_END_CALL: 'yes end call',
  DO_NOT_END_CALL: 'do not end call',
  CANCEL_SESSION: 'tap cancel session',
  SUBMIT_PAYMENT: 'Submit Payment',
  CLICK_ADD_REFLECTION: 'click add reflection',
  CLICK_GRAD_PAGE_CTA: 'click grad page CTA',
  SUBMIT_REFLECTION: 'submit new reflection',
  CARE_HOME_PROVIDER_CARD_CLICKED: 'Care Home Provider Card Clicked',
  // TODO: ICE-3255
  VIEW_RESULTS_OVERTIME: 'view results overtime',
  OPEN_MESSAGE_PROVIDER_ALTERNATE_TIMES_MODAL: 'Alternate Times Prompt Button Clicked',
  DISMISS_MESSAGE_PROVIDER_ALTERNATE_TIMES_MODAL: 'Alternate Times Modal Dismiss Button Clicked',
  SEND_PROVIDER_ALTERNATE_TIMES: 'Alternate Times Modal Send Button Clicked',
  SESSIONS_REMINDER_CARD_BUTTON_CLICKED: 'Sessions Reminder Card Button Clicked',
  SESSIONS_REMINDER_CARD_DISMISSED: 'Sessions Reminder Card Dismissed',
  SMS_ENROLLMENT_LOGIN_MODAL_SHOWN: 'SMS Notification modal is surfaced',
  SMS_ENROLLMENT_LOGIN_MODAL_ENROLL_CLICKED: 'Turn On Notification clicked',
  SMS_ENROLLMENT_LOGIN_MODAL_DISMISSED: 'Closing Notification Modal',

  // SCREENS
  VIEW_GUIDE: `view ${COMMON_BCX_SCREENS.GUIDE}`,
  VIEW_ACTIVITIES_LIST: `view ${COMMON_BCX_SCREENS.ACTIVITIES_LIST}`,
  VIEW_LESSON_OVERVIEW: `view ${COMMON_BCX_SCREENS.LESSON_OVERVIEW}`,
  LESSON_VIDEO_PLAYER: `view ${COMMON_BCX_SCREENS.LESSON_VIDEO_PLAYER}`,
  VIEW_VIDEO_PLAYER: `view ${COMMON_BCX_SCREENS.VIDEO_PLAYER}`,
  VIEW_LESSON_REVIEW: `view ${COMMON_BCX_SCREENS.LESSON_REVIEW}`,
  VIEW_EXERCISE_OVERVIEW: `view ${COMMON_BCX_SCREENS.EXERCISE_OVERVIEW}`,
  VIEW_PASSIVE_SI_MESSAGE: `view ${COMMON_BCX_SCREENS.PASSIVE_SI_MESSAGE}`,
  VIEW_ACTIVE_SI_MESSAGE: `view ${COMMON_BCX_SCREENS.ACTIVE_SI_MESSAGE}`,
  VIEW_SUMMARY_PAGE: `view ${COMMON_BCX_SCREENS.SUMMARY_PAGE}`,
  VIEW_ESSENTIALS: `view ${COMMON_BCX_SCREENS.ESSENTIALS}`,
  VIEW_GRAD_PAGE: `view ${COMMON_BCX_SCREENS.GRADUATION_PAGE}`,
  VIEW_COMPLEX_CARE_LANDING_PAGE: `view ${COMMON_BCX_SCREENS.COMPLEX_CARE_LANDING_PAGE}`,
  VIEW_COMPLEX_CARE_SUBPAGE: `view ${COMMON_BCX_SCREENS.COMPLEX_CARE_SUB_PAGE}`,
  VIEW_VIDEO_SESSION_COMPLETED: `view ${COMMON_BCX_SCREENS.VIDEO_SESSION_COMPLETED}`,

  // LINKS
  VIEW_CASELOAD_MANAGEMENT_ARTICLE: 'View Caseload Management Dashboard Zendesk Article',
} as const

export const S4C_EVENTS = {
  CLICK_HOW_IT_WORKS_TREATMENT_RECOMMENDATION_DETAILS: 'click how it works on treatment recommendation details',
  CLICK_FAQ_TREATMENT_RECOMMENDATION_DETAILS: 'click faq on treatment recommendation details',
  CLOSE_TREATMENT_RECOMMENDATION_DETAILS: 'close treatment recommendation details',
  VIEW_TREATMENT_RECOMMENDATION_DETAILS: 'view treatment recommendation details',
  CLICK_LEARN_MORE_ON_PRIMARY_RECOMMENDATION: 'click learn more on primary recommendation',
  CLICK_OPEN_BCT_FELLOW_VIDEO_MODAL: 'click BCT fellowship video',
  CLICK_OPEN_PRACTICE_INFO_MODAL: 'click practice info provider profile',
  CLICK_PRACTICE_INFO_PHONE_NUMBER: 'call from provider practice info',
  CLICK_PRACTICE_INFO_EMAIL: 'email from provider practice info',
  CLICK_PRACTICE_INFO_GET_DIRECTIONS: 'directions from provider practice info',
  CLICK_PROVIDER_CARD: 'click provider card',
  CLICK_PROVIDER_NAVIGATION_BUTTON: 'click provider navigation button',
  CLICK_FAST_TRACK_BOOKING: 'click fast track booking',
  CLICK_CONTINUE_WITH_BOOKING: 'click continue with booking',
  SHOW_APPOINTMENT_CONFLICT_MODAL: 'show appointment conflict modal',
  VIEW_CARE_PLAN_HOME: `view care plan home`,
  CLICK_FIND_CARE: 'click find care',
  CLICK_FIND_CARE_CHILD: 'click find care child',
  CREATE_CHILD_ACCOUNT: 'create child account',
  CLICK_CONTINUE_AFTER_SELECTING_CHILD: 'click continue after selecting child',
  CLICK_ADD_ANOTHER_CHILD: 'click add another child',
  VIEW_GDPR_CONSENT: 'view gdpr consent',
  CLICK_AGREE_TO_GDPR_CONSENT: 'agree to gdpr consent',
  CLICK_DECLINE_GDPR_CONSENT: 'click decline gdpr consent',
  VIEW_GDPR_DECLINED_PAGE: 'declined gpr',
  SELECT_PRIMARY_NEED: 'select primary need',
  SELECT_TREATMENT_OPTION: 'select treatment option',
  SELECT_INDIVIDUAL_THERAPY_FROM_AUD: 'select individual therapy from aud',
  CLICK_LEARN_MORE_FOR_MED_PROGRAMS: 'click learn more for med programs',
  REQUEST_BIPOC_PROVIDERS: 'request bipoc providers',
  CLICK_BACK_TO_PROVIDER_RECOMMENDATIONS: 'click back to provider recommendations',
  VIEW_PROVIDER_RECOMMENDATIONS: 'view provider recommendations',
  CLICK_PROGRAM_BOOKING_LINK: 'click program booking link',
  VIEW_LOCATION_MATCHER: 'view location input',
  CLICK_APPOINTMENT_TIME: 'click appointment time',
  VIEW_MORE_APPOINTMENT_SLOTS: 'view more appointment slots',
  BOOK_INITIAL_SESSION: 'book initial session',
  CONTACT_CARE_ADVOCATE_FOR_CHILD: 'contact care advocate for child',
  VIEW_PROVIDER_CONTACT_INFO: 'view provider contact info',
  SUBMIT_EMAIL_REQUEST_TO_BOOK: 'submit email request to book',
  VIEW_RECOMMENDATIONS_EXPLAINED: 'view recommendations explained',
  VIEW_MEMBER_PREFERENCES_CATEGORY_PAGE: 'view member preferences category page',
  VIEW_MEMBER_PREFERENCES_LEARN_MORE: 'view learn more about member preferences',
  VIEW_NO_PREFERENCE_MATCHES_MODAL: 'view no preference matches modal',
  VIEW_PREFERENCE: 'view preference',
  VIEW_PREFERENCE_DISCLAIMER: 'view preference disclaimer',
  CLICK_FLEXIBLE_AVAILABILITY: 'click flexible availability',
  CLICK_SPECIFY_AVAILABILITY: 'click specify availability',
  RESET_MEMBER_PREFERENCES: 'reset member preferences',
  CLICK_ADD_MEMBER_PREFERENCES: 'click to add member preferences',
  CLICK_MEMBER_PREFERENCES_MODAL_DONE: 'click done in member preference modal',
  CLICK_MEET_PROVIDERS: 'click meet providers',
  CLICK_TO_CONTACT_CN: 'click to contact cn',
  VIEW_GENERAL_CONTACT_CN_TEAM: 'view general contact cn team page',
  CLICK_SCHEDULE_CN_CALL: 'click schedule a cn call',
  CLICK_CHAT_LIVE: 'click chat live',
  SUBMIT_EMAIL_CN: 'submit cn email',
  CONTACT_CN_TEAM_CONFIRMATION: 'contact cn team email confirmation',
  VIEW_PROVIDER_LOADING_SCREEN: 'view provider loading screen',
  VIEW_PROVIDER_SCHEDULING_CALENDAR: 'view provider scheduling calendar',
  VIEW_CHILD_DIRECT_CN_PATH: 'view child direct cn path',
  CLICK_CHILD_EXPLORE_CARE_OPTIONS: 'click child explore care options',
  CLICK_CHILD_PERSONALIZED_GUIDANCE_DROPDOWN: 'click child personalized guidance dropdown',
  VIEW_CHILD_CN_EMAIL_REQUEST_PAGE: 'view child cn email request page',
  CLICK_PROVIDER_VIDEO: 'click provider video',
  CLICK_PROVIDER_VIDEO_FULL_SCREEN: 'click provider video full screen',
  STOP_PROVIDER_VIDEO: 'stop provider video',
  VIEW_PROVIDER_PROFILE: 'view provider profile',
  CLICK_CLINICAL_EXPERIENCE_SHOW_MORE: 'click clinical experience show more',
  PARENT_LED_LCT_TEEN_BOOKING_EMAIL_ERROR: 'parent led lct teen booking email error',
  OPEN_LYRA_CARE_MODAL: 'open lyra care modal',
  OPEN_LYRA_CARE_TESTIMONIALS: 'open lyra care testimonials',
  VIEW_MORE_LYRA_CARE_TESTIMONIALS: 'view more lyra care testimonials',
  CLOSE_LYRA_CARE_MODAL: 'close lyra care modal',
  OPEN_HOW_MATCHING_WORKS_MODAL: 'open how matching works modal',
  CLOSE_HOW_MATCHING_WORKS_MODAL: 'close how matching works modal',
  CLICK_RECOMMENDED_FAMILY_PROGRAM: 'click recommended family program',
  CLOSE_RECOMMENDED_FAMILY_PROGRAM: 'close recommended family program',
  CLICK_CONTINUE_FROM_ADD_A_CHILD_FORM: 'click continue from add a child form',
  CLICK_CONTINUE_TRIAGE_SUMMARY: 'click continue on triage summary page',
  SUBMIT_EMAIL_REQUEST_TO_BOOK_ERROR: 'submit email request to book error',
  CLICK_REQUEST_CALL: 'click request a call from care navigators',
  CLICK_SUBMIT_PHONE: 'click submit phone number',
  OPEN_PROVIDER_SUMMARY_EXPLANATION: 'open provider summary explanation',
  CLOSE_PROVIDER_SUMMARY_EXPLANATION: 'close provider summary explanation',
  CLICK_IN_PAGE_PRODUCT_RATING: 'click in page product rating',
  OPEN_FAQ_MODAL: 'open faq modal',
  CLOSE_FAQ_MODAL: 'close faq modal',
} as const

export const CARE_TOOL_EVENTS = {
  CLICK_SCHEDULE_VIA_CONNECTED_CALENDAR: 'Click Schedule Via Connected Calendar',
  CLICK_SERVICE_CONFIRMED: 'Click Service Confirmed',
  CLICK_SERVICE_NOT_CONFIRMED: 'Click Service Not Confirmed',
}

export enum PATHWAYS_EVENTS {
  VIEW_PROVIDER_CARE_PATHWAY = 'view provider care pathway',
  VIEW_SELF_CARE_PATHWAY_TILE = 'view self care pathway tile',
  CLICK_EXPLORE_ON_YOUR_OWN = 'click explore on your own',
  VIEW_MEET_WITH_AN_EXPERT_TAB = 'view meet with an expert tab',
  CLICK_MEET_WITH_AN_EXPERT = 'click meet with an expert',
  VIEW_CONTENT_LIBRARY_TAB = 'view content library tab',
  CLICK_ACCESS_FULL_LIBRARY = 'click access full library',
  VIEW_ALL_OFFERINGS_TAB = 'view all offerings tab',
  CLICK_CREATE_ACCOUNT = 'click create account',
  CLICK_CHAT_LIVE = 'click chat live',
  CLICK_FAQS = COMMON_MIXPANEL_EVENTS.CLICK_FAQS,
  CLICK_NOTIFY_ME_ON_LAUNCH = 'click notify me at launch',
  CLICK_SELF_CARE_RESOURCE = 'click self care resource',
  CLICK_MOBILE_APP_DOWNLOAD_LINK = 'click mobile app download link',
}

export enum PATHWAYS_PROPERTIES {
  FAQ_QUESTION = 'FAQ question',
}

export enum TRIAGE_EVENTS {
  VIEW_PRIMARY_NEED = 'view primary need',
  VIEW_WORD_CLOUD_0 = 'view word cloud 0',
  VIEW_WORD_CLOUD_1 = 'view word cloud 1',
  VIEW_SEVERITY_SLIDERS = 'view severity sliders',
  VIEW_IMPAIRMENT_OF_FUNCTIONING = 'view impairment of functioning',
  VIEW_FELT_LIKE_USUAL_SELF_TRIAGE = 'view felt like usual self triage',
  VIEW_CURRENT_THERAPY_TRIAGE = 'view current therapy triage',
  VIEW_PAST_THERAPY_TRIAGE = 'view past therapy triage',
  VIEW_CLINICAL_SCREENERS = 'view clinical screeners',
  VIEW_CARE_EXPERIENCES = 'view care experiences',
  VIEW_LIFE_CHANGES = 'view life changes',
}

export enum COMMON_PREFERENCE_CATEGORIES {
  MEETING_STYLE = 'meeting style',
  CARE_LANGUAGE = 'care language',
}

// Generally used for recording where the Contact CN link click originates from
export enum MEMBER_PREFERENCE_ENTRY_POINTS {
  NO_PREFERENCES_MODAL = 'no preferences modal',
  LEARN_MORE_MODAL = 'preferences learn more modal',
  CARE_LANGUAGE = 'care language',
}

export enum CHILD_ACCOUNT_ENTRY_POINTS {
  CREATE_CHILD_ACCOUNT_PROFILE = 'member profile - add a minor',
}

export enum GRADUATION_PAGE_CTA {
  ESSENTIALS_GRADUATION_CONTENT = 'essential graduation content',
  FINAL_ASSESSMENT = 'final assessment',
  POST_CARE_ASSESSMENT = 'post-care assessment',
  PAST_ACTIVITIES = 'past activities',
  ESSENTIALS_HOMEPAGE = 'essentials homepage',
}

export enum ESSENTIALS_ENTRY_POINTS {
  GRAD_PAGE_HOMEPAGE_CTA = 'Grad Page - Homepage CTA',
  GRAD_PAGE_GRAD_TIPS_CTA = 'Grad Page - Grad Tips CTA',
}

export enum PATHWAYS_ENTRY_POINTS {
  MICROSITE = 'microsite',
  SELF_CARE = 'selfcare pathway',
  LOGIN_PAGE = 'login page',
  ALL_OFFERINGS = 'all offerings',
  FAQS = 'FAQs',
  CARE_NAVIGATOR = 'care navigator',
  INTEGRATED_BENEFITS = 'integrated benefits',
  GLOBAL_NAV_BAR = 'global nav bar',
  FORKED_TRIAGE_FOOTER = 'footer',
  MEDS_CONSULT_MODAL = 'meds consult modal',
  HOMEBASE = 'homebase',
}

export enum COMPLEX_CARE_ENTRY_POINTS {
  LANDING_PAGE = 'complex care landing page',
}

export enum FORKED_TRIAGE_ENTRY_POINTS {
  FIND_CARE_FOR_SELF = 'find care for yourself',
  FIND_CARE_FOR_CHILD = 'find care for child',
  COACHING_FOR_SELF = 'coaching for yourself',
  COACHING_FOR_CHILD = 'coaching for child',
  MEDICATION_CONSULTATION_FOR_SELF = 'medication consultation for yourself',
  FIND_CARE_FOR_FAMILY = 'find care for family',
  FIND_CARE_FOR_COUPLES = 'find care for couples',
}

export enum PATHWAYS_PAGE {
  COMING_SOON = 'coming soon',
  CUSTOMER_MICROSITE = 'customer microsite',
}

export enum TRIAGE_ENTRY_POINTS {
  HOMEBASE = 'homebase',
  FORKED_TRIAGE = 'forked triage',
  ESSENTIALS = 'essentials',
  FAMILY_CARE_OPTIONS = 'family care options',
  WELLNESS_TOOLKIT = 'wellness toolkit',
  CARE_TAB = 'care tab',
  WELLNESS_CHECK_IN = COMMON_MIXPANEL_PAGE.WELLNESS_CHECK_IN,
}

export enum PATHWAYS_OPTIONS {
  EXPERT = 'meet with an expert',
  CONTENT_LIBRARY = 'content library',
  FAMILY_SUPPORT = 'family support',
}

export const MIXPANEL_EVENT_ATTRIBUTES = {
  ENTRY_POINT: {
    NAV_BAR: 'Nav Bar',
    LIBRARY: 'Library',
    HOME_BASE: 'Home Base',
    DIRECT_LINK: 'Direct Link',
    WELLNESS_TOOLKIT: 'Wellness Toolkit',
    INTEGRATED_BENEFITS: 'Integrated Benefits',
  },
}

export enum HEALTH_PLAN_EVENTS {
  CLICK_LEARN_MORE_ABOUT_HPI = 'click learn more about hpi',
  ADD_HEALTH_PLAN = 'add health plan',
  ADD_SECONDARY_HEALTH_PLAN = 'add secondary health plan',
  ADD_HPI_PAYMENT_METHOD = 'add hpi payment method',
  ADD_INFRACTION_PAYMENT_METHOD = 'add infraction payment method',
  VIEW_HEALTH_PLAN_INFO = 'view health plan',
  VIEW_HPI_PAYMENT_METHOD = 'view hpi payment method',
  VIEW_INFRACTION_PAYMENT_METHOD = 'view infraction payment method',
  VIEW_APPOINTMENT_BOOKING_PAYMENT = 'view appointment booking payment',
  REMOVE_HEALTH_PLAN = 'remove health plan',
  REMOVE_HPI_PAYMENT_METHOD = 'remove hpi payment method',
  REMOVE_INFRACTION_PAYMENT_METHOD = 'remove infraction payment method',
}

export const MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT = (isInternationalUser: boolean) => {
  if (isInternationalUser) {
    return MIXPANEL_BUTTON_TEXT.CONTACT_A_CARE_NAVIGATOR
  } else {
    return MIXPANEL_BUTTON_TEXT.GET_IN_TOUCH
  }
}

export enum MIXPANEL_CARE_NAVIGATOR_OPTIONS_LIST {
  SIDE_PANEL_DOMESTIC = 'Call | Live Chat | Email | Schedule a call',
  DOMESTIC = 'Call | Schedule a Call | Email',
  INTERNATIONAL = 'Call | Live Chat | Email',
  LYRIAN = 'Lyrian Support | FAQs',
}

export const MIXPANEL_CARE_NAVIGATOR_OPTIONS = ({
  isInternationalUser,
  isLyrian,
  isSidePanel,
}: {
  isInternationalUser?: boolean
  isLyrian?: boolean
  isSidePanel?: boolean
}) => {
  if (isLyrian) {
    return MIXPANEL_CARE_NAVIGATOR_OPTIONS_LIST.LYRIAN
  } else if (isInternationalUser) {
    return MIXPANEL_CARE_NAVIGATOR_OPTIONS_LIST.INTERNATIONAL
  } else if (isSidePanel) {
    return MIXPANEL_CARE_NAVIGATOR_OPTIONS_LIST.SIDE_PANEL_DOMESTIC
  } else {
    return MIXPANEL_CARE_NAVIGATOR_OPTIONS_LIST.DOMESTIC
  }
}

export enum LATEST_PROVIDER_EVENTS {
  VIEW_LATEST_PROVIDER_RECOMMENDATIONS = 'view last provider recommendations',
  CLICK_START_NEW_SEARCH = 'click start new search',
}

export enum LANGUAGE_PROPERTIES {
  ACTIVE_LANGUAGE = 'active_language',
  DEVICE_PREFERRED_LANGUAGES = 'device_preferred_languages',
}

export enum LATEST_PROVIDER_PROPERTIES {
  MATCHER_REQUEST_ID = 'matcher_request_id', // request_id of the last search
  LAST_SEARCH_TIME = 'last_search_time',
  PROVIDER_AVAILABILITY = 'provider_availability',
  PAST_PROVIDER_RESULTS_FLAG = 'past_provider_results_flag',
  PAST_SEARCH_AVAILABLE = 'past_search_available',
}

export enum PROVIDER_VIDEO_PROPERTIES {
  PROVIDER_VIDEO_FLAG = 'provider_video_flag',
  PLAYED_INTERVALS = 'playedIntervals',
  VIDEO_ENDED = 'videoEnded',
  VIDEO_DURATION = 'videoDuration',
  PERCENT_VIEWED = 'percentViewed',
  VIDEO_ID = 'videoId',
}

export enum INTEGRATED_BENEFITS_EVENTS {
  VIEW_INTEGRATED_BENEFITS_EXPLORER = 'view integrated benefits explorer',
  VIEW_INTEGRATED_BENEFITS_PAGE = 'view integrated benefits page',
  CLICK_INTEGRATED_BENEFIT = 'click integrated benefit',
  VIEW_INTEGRATED_BENEFITS_ENTRYCARD = 'view integrated benefits entrycard',
  DISMISS_INTEGRATED_BENEFITS_ENTRYCARD = 'dismiss integrated benefits entrycard',
  CLICK_SCHEDULE_RESOURCE_COORDINATOR_CALL = 'click schedule a resource coordinator call',
  CLICK_SCHEDULE_NICOTINE_QUITLINE_CALL = 'click schedule a nicotine quitline call',
}

export enum INTEGRATED_BENEFITS_CATEGORY {
  LYRA_OFFERING = 'lyra offering',
}

export enum WELLNESS_TOOLKIT_EVENTS {
  CLICK_HOMEBASE_PROMOTION = 'click homebase bcc promotion',
  VIEW_WELLNESS_TOOLKIT = 'view wellness toolkit',
  CLICK_TO_ESSENTIALS_RESOURCE = 'click wellness toolkit resource',
  CLICK_TO_PROVIDER_SEARCH = 'click direct path search',
  CLICK_TO_SOMETHING_ELSE = 'click wellness toolkit something else',
}

export enum WELLNESS_TOOLKIT_EVENTS_PROPERTIES {
  TOOLKIT_TOPIC = 'toolkit_topic',
  ARTICLE_TITLE = 'article_title',
  LAST_SEARCH_TIME = 'last_search_time',
  CUSTOMER_CONFIGURATION = 'customer_configuration',
  USER_TIMEZONE = 'user_timezone',
  PAGE = 'page',
  DESTINATION_SLUG = 'destination_slug',
  CONTENT_FORMAT = 'content_format',
  CONTENT_TYPE = 'content_type',
  IS_BCC_PROMOTION_AVAILABLE = 'is_bcc_promotion_available',
  IS_PARENT_COACHING_SHOWN = 'is_parent_coaching_shown',
}

export enum FORKED_TRIAGE_EVENTS {
  CLICK_FIND_CARE_FOR_YOURSELF = 'click find care for yourself',
  CLICK_FIND_CARE_FOR_CHILD = 'click find care for a child or teen',
  CLICK_COACHING_FOR_YOURSELF = 'click coaching for yourself',
  CLICK_COACHING_FOR_PARENT = 'click coaching for parent',
  CLOSE_COACHING_FOR_PARENT = 'close coaching for parent',
  CLICK_MEDICATION_CONSULTATION_FOR_YOURSELF = 'click medication consultation for yourself',
  CLOSE_MEDICATION_CONSULTATION_FOR_YOURSELF = 'close medication consultation',
  CLICK_TO_SOMETHING_ELSE = 'click wellness toolkit something else',
  CLICK_LEARN_MORE_LINK_IN_MEDS_CONSULT_MODAL = 'click on learn more link in meds consult modal',
  CLICK_COUPLES_THERAPY = 'click couples therapy',
  CLICK_FAMILY_THERAPY = 'click family therapy',
  CLOSE_FAMILY_THERAPY = 'close family therapy',
  CLICK_DIRECT_PATH = 'click direct path',
}

export enum SELF_CARE_EVENTS {
  CLICK_SELF_CARE_CONTINUE = 'click self care topic preference - continue',
  CLICK_SELF_CARE_SKIP = 'click self care topic preference - skip',
}

export enum SELF_CARE_EVENTS_PROPERTIES {
  SELECTED_TOPIC_PREFERENCE = 'selected topic preference',
}

export enum INACTIVITY_EVENTS {
  RESUME = 'dismiss session timeout modal',
  SIGN_OUT = 'log out via session timeout modal',
  INACTIVE = 'display timeout modal to inactive user',
}

export enum INACTIVITY_PROPERTIES {
  USER_SIGN_OUT = 'user clicked sign out',
  SESSION_EXPIRED = 'session timed out',
}

export enum PROFILE_PAGES {
  SETTINGS = 'member profile - settings',
  SESSION_HISTORY = 'member profile - session history',
  PRIVACY_AND_LEGAL = 'member profile - privacy and legal',
  HEALTH_PLAN_AND_PAYMENT_INFO = 'member profile - health plan and payment information',
  ELIGIBILITY = 'member profile - eligibility',
  ACCOUNT_HOME = 'member profile - home',
  CHANGE_EMAIL = 'member profile - change email',
  DISPLAY_LANGUAGE = 'member profile - display language',
  LOCATION_AND_TIMEZONE = 'member profile - location and timezone',
  LYRA_UPDATES = 'member profile - lyra updates',
  MFA = 'member profile - multi-factor authentication',
  CUSTOM_NOTIFICATIONS = 'member profile - custom notifications',
  NOTIFICATIONS = 'member profile - notifications',
  PHONE_NUMBER = 'member profile - phone number',
  CHILD_INFO = 'child profile - personal info',
  PERSONAL_INFO = 'member profile - personal info',
  UPDATE_INFO_QUESTIONNAIRE = 'member profile - user info questionnaire',
  VERIFY_EMAIL_UPDATE_USER_INFO = 'member profile - verify email update user info',
  CHANGE_THEME = 'member profile - change theme',
  ADD_FAMILY_MEMBER = 'member profile - add family member',
}

export enum WELLNESS_CHECK_IN_PAGES {
  OVERVIEW = 'wellness check-in overview',
  HOW_ARE_YOU_FEELING_TODAY = 'wellness check-in question - how are you feeling today',
  MOOD_AND_EMOTIONS = 'wellness check-in question - mood and emotions',
  RESILIENCE_AND_COPING = 'wellness check-in question - resilience and coping',
  ROLES_AND_RELATIONSHIPS = 'wellness check-in question - roles and relationships',
  PHYSICAL_HEALTH_AND_ACTIVITY = 'wellness check-in question - physical health and activity',
  FINANCES_AND_WORK = 'wellness check-in question - finances and work',
  SPIRITUALITY_AND_EXPLORATION = 'wellness check-in question - spirituality and exploration',
  SOCIAL_CARE = 'wellness check-in question - social care',
  PREVIEW = 'wellness check-in preview results',
  RESULTS = 'wellness check-in full results',
  EMPTY_STATE_RESULTS = 'wellness check-in results empty state',
}

export type PAGES =
  | PROFILE_PAGES
  | REGISTRATION_PAGES
  | COMMON_MIXPANEL_PAGE
  | COMMON_SEARCH_FOR_CARE_SCREENS
  | COMMON_REGISTRATION_SCREENS
  | WELLNESS_CHECK_IN_PAGES
  | COMMON_BCX_SCREENS

export const createPageViewEventName = (page: PAGES, skipAppendPageToEventName?: boolean) => {
  return `view ${page}${skipAppendPageToEventName ? '' : ' page'}`
}

export const createPageViewEventNameWithoutPageSuffix = (page: PAGES) => {
  return `view ${page}`
}

export enum PROFILE_EVENTS {
  PROFILE_CLICK_NAME_CHANGE = 'Profile - Click name change',
  CHANGE_EMAIL_CONFIRMATION = 'Send Email Change Verification',
  RESEND_CHANGE_EMAIL_CONFIRMATION = 'Resend Email Change Verification',
  CONFIRM_EMAIL_CHANGE = 'Email Change Success',
  ENROLL_MFA = 'Send enroll mfa verification code',
  VERIFY_MFA = 'Enter mfa verification code',
  ENROLLED_MFA = 'Successfully enrolled in mfa',
  DISABLE_MFA = 'Click disable mfa',
  UPDATE_DISPLAY_LANGUAGE = 'Update user display language',
  UPDATE_PHONE_NUMBER = 'Update phone number',
  UPDATE_NOTIFICATION_SETTINGS = 'Update notification settings',
  CANCEL_CUSTOM_NOTIFICATION_SETTINGS = 'Cancel Custom Notification settings',
  DELETE_CUSTOM_NOTIFICATION_SETTINGS = 'Delete Custom Notification settings',
  UPDATE_CUSTOM_NOTIFICATION_SETTINGS = 'Update Custom Notification settings',
  RESEND_MFA_EMAIL = 'Resend mfa email for verification code',
  CHANGE_COUNTRY = 'User account country updated to ',
  CHANGE_TIMEZONE = 'User account timezone updated to ',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_FOR_LIFE_EVENT = 'Locked Profile - Name Change - Click for life event',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_CHILD_DEPENDENT = 'Locked Profile - Name Change - Click care for child dependent',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_SELF = 'Locked Profile - Name Change - Click care for self',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_CARE_FOR_ADULT_DEPENDENT = 'Locked Profile - Name Change - Click care for adult dependent',
  LOCKED_PROFILE_NAME_CHANGE_CLICK_REASON_NOT_LISTED_ON_PAGE = 'Locked Profile - Name Change - Click reason not listed on page',
  UPDATE_USER_INFO_SUBMITTED = 'Update user info submitted',
  USER_CLICK_DOWNLOAD_DATA_FILE = 'Click download user data file',
  CHANGE_THEME = 'User display theme updated to ',
  UPDATE_CHILD_INFO_SUCCESS = 'Child profile - update child info success',
  UPDATE_CHILD_INFO_FAILED = 'Child profile - update child info failed',
  CLICK_ADD_A_DEPENDENT = 'click add a dependent',
  ADD_PREFERRED_NAME = 'add preferred name',
  DISMISS_BANNER_NOTIFICATION = 'dismiss account page banner',
  CLICK_UPDATE_EMAIL_BANNER = 'click update email account page banner',
}

export enum ADD_DEPENDENT_PROPERTIES {
  DEPENDENT_AGE = 'dependent_age',
  DEPENDENT_LYRA_ID = 'dependent_lyra_id',
  DEPENDENT_TYPE = 'dependent_type',
}

export enum HEALTHCARE_BOOKING_EVENTS {
  BOOKED_LYRA_APPOINTMENT = 'booked lyra treatment',
  BOOKED_EXTERNAL_APPOINTMENT = 'booked external treatment',
}

export enum HEALTHCARE_BOOKING_PROPERTIES {
  HEALTHCARE_SERVICE_NAME = 'healthcare_service_name',
}

export const MIXPANEL_VENDOR_API_URL = `${VENDOR_BASE_API_URL}/mixpanel`

export enum COMMON_ENTRY_POINTS {
  EMAIL = 'email',
  HAMBURGER_MENU = 'hamburger menu',
}

export enum REFERRAL_EVENTS {
  CLICK_REFERRAL_CTA = 'click referral CTA',
  SHARE_REFERRAL_LINK = 'share referral link',
  SHARE_REFERRAL_LINK_CANCELLED = 'cancel share referral link',
  VIEW_COMING_SOON_MICROSITE = 'view coming soon landing page',
}

export enum REFERRAL_ENTRY_POINTS {
  LOGIN_PAGE = 'login page',
  HAMBURGER_MENU = COMMON_ENTRY_POINTS.HAMBURGER_MENU,
  MHAM_MICROSITE = 'mham microsite',
  WELCOME_EMAIL = 'welcome email',
}

export enum ZOOM_EVENTS {
  START_AUDIO = 'start audio',
  START_AUDIO_ERROR = 'start audio error',
  START_AUDIO_SUCCESS = 'start audio success',
  TOGGLE_MUTE = 'toggle mute',
  TOGGLE_MUTE_SUCCESS = 'toggle mute success',
  TOGGLE_MUTE_ERROR = 'toggle mute error',
  TOGGLE_VIDEO = 'toggle video',
  TOGGLE_VIDEO_SUCCESS = 'toggle video success',
  TOGGLE_VIDEO_ERROR = 'toggle video error',
  BAD_CONNECTION = 'bad connection',
  START_SCREENSHARE = 'start screenshare',
  STOP_SCREENSHARE = 'stop screenshare',
  RECEIVE_SCREENSHARE = 'receive screenshare',
  STOP_RECEIVE_SCREENSHARE = 'stop receive screenshare',
  RENDER_SCREENSHARE = 'render screenshare',
  STOP_RENDER_SCREENSHARE = 'stop render screenshare',
  CONNECTION_CONNECTED = 'connection connected',
  CONNECTION_CLOSED = 'connection closed',
}

export enum ZOOM_PROPERTIES {
  STREAM_EXISTS = 'stream_exists',
  MUTED = 'muted',
  VIDEO_OFF = 'video_off',
  ERROR = 'error',
  SESSION_STARTED = 'session_started',
  IS_SELF = 'is_self',
  LEVEL = 'level',
  CONNECTION_TYPE = 'connection_type',
  CONNECTION_CHANGE_REASON = 'connection_change_reason',
}

export enum LYRA_CARE_ENTRY_POINT {
  HEADER = 'header',
  LC_SECTION = 'LC section',
}

export enum ELECTION_EVENTS {
  CLICK_ELECTION_TOOLKIT_ENTRYPOINT = 'click election toolkit entrypoint',
  VIEW_ELECTION_STRESS_TOOLKIT_PAGE = 'view election stress toolkit page',
  CLICK_WORKSHOP_REGISTER = 'click workshop register',
  CLICK_SELF_CARE_RESOURCE = 'click self care resource',
}

export enum COVERAGE_MESSAGING_EVENTS {
  OPEN_COVERAGE_MODAL = 'open coverage modal',
  CLOSE_COVERAGE_MODAL = 'close coverage modal',
}

export enum COVERAGE_MESSAGING_ENTRY_POINT {
  FORKED_TRIAGE = 'Forked Triage',
  MY_BENEFITS = 'My Benefits',
}

export enum PERSONAL_INFO_PROPERTIES {
  IS_PERSONAL_INFO_EDITABLE = 'is_personal_info_editable',
  ARE_FIELDS_EDITABLE = 'are_fields_editable',
}

export enum MINDFUL_MINUTES_EVENTS {
  ENABLE_LOGGING = 'enable mindful minutes logging',
  DISABLE_LOGGING = 'disable mindful minutes logging',
}

export enum QUICK_ACTION_EVENTS {
  CLICK_QUICK_ACTION = 'click quick action',
}

export enum RESCHEDULE_PAGE {
  REBOOK_AFTER_CANCEL = 'Rebook after cancel',
  RESCHEDULE = 'Reschedule',
}

export enum WELLNESS_CHECK_IN_EVENTS {
  ASSESSMENT_COMPLETION = 'wellness check-in assessment completion',
  CLICK_EMPTY_STATE_START_AGAIN = 'click wellness check-in start again',
  CLICK_RESULTS_DROP_DOWN_MENU = 'click wellness check-in results drop down menu',
  CLICK_FIND_CARE = 'click find care - wellness check-in',
  CLICK_LEARN_MORE_ABOUT_SOCIAL_CARE = 'click learn more about social care',
  CLICK_EXIT_BUTTON = 'click exit button',
  CLICK_HEADER_LYRA_LOGO = 'click header lyra logo',
}

export enum WELLNESS_CHECK_IN_ENTRY_POINTS {
  MICROSITE = COMMON_MIXPANEL_PAGE.MICROSITE,
  ASSESSMENT = 'assessment',
  EMAIL = COMMON_ENTRY_POINTS.EMAIL,
}

export enum URGENCY_TO_BOOK_PROPERTIES {
  URGENCY_TO_BOOK_FLAG = 'urgency_to_book_flag',
  PROVIDER_CAPACITY = 'provider_capacity',
}
