import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import {
  CLEAR_TRIAGE,
  CREATING_SEARCH,
  GET_ALL_TRIAGE_SEARCHES,
  GET_NEXT_TRIAGE_SCREEN,
  GET_WORD_CLOUD_WORDS,
  LOADING_NEXT_QUESTION,
  LOADING_TREATMENT_RECOMMENDATIONS,
  LOADING_WORD_CLOUD,
  MARK_HIGH_ALERT_CALLBACK_REQUESTED,
  MARK_SELF_HARM_CONTACT_MODAL_AS_SHOWN,
  POST_RISK_FACTORS,
  POST_TREATMENT_RECOMMENDATIONS,
  POST_TRIAGE_SEARCH,
  PUT_OR_PATCH_TRIAGE_SEARCH,
  PUT_RESET_TRIAGE_SEARCH_PROGRESS,
  RESET_SELF_HARM_CONTACT_MODAL_SHOWN_INDICATOR,
  SET_HAS_RESUMED_COMPLETED_SEARCH,
  SET_HIGH_ALERT,
  SET_PRESELECTED_PRIMARY_NEED,
  SET_SCREENERS,
  SET_SEARCHER_INFO,
  SET_SELECTED_SEARCH_ID,
  SET_TRIAGE_QUESTIONS,
  SHOULD_CREATE_NEW_SEARCH,
  UI_BACK_FROM_TREATMENT_RECS,
  UPDATING_SEARCH,
} from './triageActions'

const initialState = fromJS({
  markSelfHarmContactModalAsShown: false,
  screeners: [],
  searches: [],
  selectedSearchId: null,
  triageQuestions: [],
  searcherInfo: null,
  treatmentRecommendations: null,
  lastTriageScreener: null,
  updatingSearch: false,
  loadingNextQuestion: false,
  loadingTreatmentRecommendations: false,
  highAlert: {
    showModal: false,
    isActiveSI: false,
  },
  highAlertCallbackRequested: false,
  navigationState: {
    hasResumedCompletedSearch: false,
    uiBackFromTreatmentRecs: false,
  },
  wordCloud: null,
  loadingWordCloud: false,
  fetchedMetadataVersion: null,
  preselectedPrimaryNeed: null,
})

export default createReducer(initialState, {
  [SET_SCREENERS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('screeners', fromJS(action.data.screeners))
    $$state = $$state.set('fetchedMetadataVersion', fromJS(action.data.version))
    return $$state
  },
  [GET_ALL_TRIAGE_SEARCHES]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('searches', fromJS(action.data))
    return $$state
  },
  [POST_RISK_FACTORS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('riskFactors', fromJS(action.data))
    return $$state
  },
  [POST_TRIAGE_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.updateIn(['searches'], ($$searches: $TSFixMe) => $$searches.push(fromJS(action.data)))
    $$state = $$state.set('selectedSearchId', fromJS(action.data.id))
    return $$state
  },
  [PUT_OR_PATCH_TRIAGE_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const searchIndex = $$state.get('searches').findIndex(($$search: $TSFixMe) => $$search.get('id') === action.data.id)
    if (searchIndex > -1) {
      $$state = $$state.setIn(['searches', searchIndex], fromJS(action.data))
    } else {
      $$state = $$state.updateIn(['searches'], ($$searches: $TSFixMe) => $$searches.push(fromJS(action.data)))
    }
    return $$state
  },
  [SET_SELECTED_SEARCH_ID]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('selectedSearchId', fromJS(action.data))
    return $$state
  },
  [SET_TRIAGE_QUESTIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('triageQuestions', fromJS(action.data))
    return $$state
  },
  [GET_NEXT_TRIAGE_SCREEN]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.updateIn(['triageQuestions'], ($$questions: $TSFixMe) =>
      $$questions.push(fromJS(action.data.name)),
    )
    $$state = $$state.set(
      'lastTriageScreener',
      action.data.is_last_triage_screener ? fromJS(action.data.name) : undefined,
    )
    return $$state
  },
  [POST_TREATMENT_RECOMMENDATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('treatmentRecommendations', fromJS(action.data))
    return $$state
  },
  [PUT_RESET_TRIAGE_SEARCH_PROGRESS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const searchIndex = $$state.get('searches').findIndex(($$search: $TSFixMe) => $$search.get('id') === action.data.id)
    if (searchIndex > -1) {
      $$state = $$state.setIn(['searches', searchIndex], fromJS(action.data))
    } else {
      $$state = $$state.updateIn(['searches'], ($$searches: $TSFixMe) => $$searches.push(fromJS(action.data)))
    }
    return $$state
  },
  [GET_WORD_CLOUD_WORDS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    const wordCloud = $$state.get('wordCloud')?.toJS() || {}
    $$state = $$state.set('wordCloud', fromJS({ ...wordCloud, [action.data.screenerName]: action.data.response }))
    return $$state
  },
  [UPDATING_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('updatingSearch', fromJS(action.data))
    return $$state
  },
  [CREATING_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('isCreatingSearch', fromJS(action.data))
    return $$state
  },
  [LOADING_NEXT_QUESTION]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('loadingNextQuestion', fromJS(action.data))
    return $$state
  },
  [MARK_SELF_HARM_CONTACT_MODAL_AS_SHOWN]: ($$state: $TSFixMe) => {
    $$state = $$state.set('markSelfHarmContactModalAsShown', true)
    return $$state
  },
  [RESET_SELF_HARM_CONTACT_MODAL_SHOWN_INDICATOR]: ($$state: $TSFixMe) => {
    $$state = $$state.set('markSelfHarmContactModalAsShown', false)
    return $$state
  },
  [CLEAR_TRIAGE]: ($$state: $TSFixMe) => {
    $$state = $$state.set('selectedSearchId', null)
    $$state = $$state.set('triageQuestions', fromJS([]))
    $$state = $$state.set('lastTriageScreener', null)
    $$state = $$state.set('searcherInfo', null)
    $$state = $$state.set('treatmentRecommendations', null)
    $$state = $$state.set('updatingSearch', false)
    $$state = $$state.set('isCreatingSearch', false)
    $$state = $$state.set('loadingNextQuestion', false)
    $$state = $$state.set('shouldCreateNewSearch', false)
    $$state = $$state.setIn(['navigationState', 'hasResumedCompletedSearch'], false)
    $$state = $$state.setIn(['navigationState', 'uiBackFromTreatmentRecs'], false)
    $$state = $$state.set('wordCloud', null)
    $$state = $$state.set('loadingWordCloud', false)
    $$state = $$state.set('fetchedMetadataVersion', null)
    $$state = $$state.set('preselectedPrimaryNeed', null)
    return $$state
  },
  [LOADING_TREATMENT_RECOMMENDATIONS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('loadingTreatmentRecommendations', fromJS(action.data))
    return $$state
  },
  [SET_SEARCHER_INFO]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('searcherInfo', fromJS(action.data))
    return $$state
  },
  [SET_HIGH_ALERT]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('highAlert', fromJS(action.data))
    return $$state
  },
  [MARK_HIGH_ALERT_CALLBACK_REQUESTED]: ($$state: $TSFixMe) => {
    $$state = $$state.set('highAlertCallbackRequested', true)
    return $$state
  },
  [SHOULD_CREATE_NEW_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('shouldCreateNewSearch', action.data)
    return $$state
  },
  [SET_HAS_RESUMED_COMPLETED_SEARCH]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['navigationState', 'hasResumedCompletedSearch'], action.data)
    return $$state
  },
  [UI_BACK_FROM_TREATMENT_RECS]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.setIn(['navigationState', 'uiBackFromTreatmentRecs'], action.data)
    return $$state
  },
  [LOADING_WORD_CLOUD]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('loadingWordCloud', fromJS(action.data))
    return $$state
  },
  [SET_PRESELECTED_PRIMARY_NEED]: ($$state: $TSFixMe, action: $TSFixMe) => {
    $$state = $$state.set('preselectedPrimaryNeed', fromJS(action.data))
    return $$state
  },
})
