import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { COMMON_MIXPANEL_PAGE, createPageViewEventName, PAGES } from '@lyrahealth-inc/shared-app-logic'

import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'

export function useTrackViewPage(
  page: PAGES,
  properties?: { [key: string]: any },
  options?: {
    entryPoint?: {
      entryPoint?: COMMON_MIXPANEL_PAGE
    } | null
    skipAppendPageToEventName?: boolean
    requiredProperties?: string[]
    customEvent?: string
  },
) {
  const { entryPoint, skipAppendPageToEventName, requiredProperties } = options || {}
  const dispatch = useAppDispatch()
  const [pageTracked, setPageTracked] = useState('')
  const { search } = useLocation()
  const waitForRequiredFields =
    !!properties && requiredProperties?.length && !requiredProperties.every((key) => key in properties)

  useEffect(() => {
    if (entryPoint === null) {
      // Block until entry point is ready
      return
    }
    const track = () => {
      dispatch(
        trackEventWithObj({
          event: options?.customEvent ?? createPageViewEventName(page, skipAppendPageToEventName),
          ...entryPoint,
          ...properties,
        }),
      )
    }
    if (page !== pageTracked && !search.includes('utm_medium=tbd') && !waitForRequiredFields) {
      setPageTracked(page)
      track()
    }
  }, [
    dispatch,
    entryPoint,
    options?.customEvent,
    page,
    pageTracked,
    properties,
    search,
    skipAppendPageToEventName,
    waitForRequiredFields,
  ])
}
