import React, { FunctionComponent } from 'react'
import { defineMessage, defineMessages, IntlShape, MessageDescriptor } from 'react-intl'

import { ThemeColors } from './theme/colors'
import { DependentTypes, FormMetadata } from '../../../models'

export enum KEYS {
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CONTROL = 'Control',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ESC = 'Escape',
  META = 'Meta', // CMD key for mac,
  SPACE = ' ',
}

export const overrideCompanyNames = {
  mgb: 'MGBHP - Employees Group',
  mgbhealthplan: 'MGBHP - Commercial Plan',
  amazonkids: 'Amazon - Kids',
  amazon: 'Amazon - HPI',
}

export const DEFAULT_CARE_LANGUAGE = 'en'

// TODO: Instead of maintaining our own translations, consider replacing this with Intl.DisplayNames (i.e. `intl.formatDisplayName()`),
// if the display names are accurate.
export const CARE_LANGUAGES = defineMessages({
  ar: {
    defaultMessage: 'Arabic',
    description: 'Name of a language a provider speaks: Arabic (all variants)',
  },
  ary: {
    defaultMessage: 'Darija',
    description: 'Name of a language a provider speaks: Darija',
  },
  ase: {
    // NOTE: `ase` is not defined in Intl.DisplayNames
    defaultMessage: 'American Sign Language',
    description: 'Name of a language a provider speaks: American Sign Language',
  },
  be: {
    defaultMessage: 'Belarusian',
    description: 'Name of a language a provider speaks: Belarusian',
  },
  ber: {
    // NOTE: `ber` is not defined in Intl.DisplayNames
    defaultMessage: 'Berber',
    description: 'Name of a language a provider speaks: Berber',
  },
  bg: {
    defaultMessage: 'Bulgarian',
    description: 'Name of a language a provider speaks: Bulgarian',
  },
  bn: {
    // NOTE: Intl.DisplayNames formats `bn` in English as `Bangla`
    defaultMessage: 'Bengali',
    description: 'Name of a language a provider speaks: Bengali',
  },
  ca: {
    defaultMessage: 'Catalan',
    description: 'Name of a language a provider speaks: Catalan',
  },
  ceb: {
    defaultMessage: 'Cebuano',
    description: 'Name of a language a provider speaks: Cebuano',
  },
  cmn: {
    // NOTE: Intl.DisplayNames formats `cmn` in English as `Chinese`
    defaultMessage: 'Mandarin',
    description: 'Name of a language a provider speaks: Mandarin',
  },
  cs: {
    defaultMessage: 'Czech',
    description: 'Name of a language a provider speaks: Czech',
  },
  da: {
    defaultMessage: 'Danish',
    description: 'Name of a language a provider speaks: Danish',
  },
  de: {
    defaultMessage: 'German',
    description: 'Name of a language a provider speaks: German (all dialects)',
  },
  'de-DE': {
    // NOTE: Intl.DisplayNames formats `de-DE` in English as `German (Germany)`
    defaultMessage: 'German',
    description: 'Name of a language a provider speaks: German (all dialects)',
  },
  el: {
    defaultMessage: 'Greek',
    description: 'Name of a language a provider speaks: Greek',
  },
  en: {
    defaultMessage: 'English',
    description: 'Name of a language a provider speaks: English (all dialects)',
  },
  'en-US': {
    // NOTE: Intl.DisplayNames formats `en-US` in English as `American English`
    defaultMessage: 'English',
    description: 'Name of a language a provider speaks: English (all dialects)',
  },
  es: {
    // NOTE: Intl.DisplayNames formats `es` in English as `Spanish`
    defaultMessage: 'Spanish (Spain)',
    description: 'Name of a language a provider speaks: Spanish from Spain',
  },
  'es-419': {
    // NOTE: Intl.DisplayNames formats `es-419` in English as `Latin American Spanish`
    defaultMessage: 'Spanish',
    description: 'Name of a language a provider speaks: Spanish',
  },
  fa: {
    // NOTE: Intl.DisplayNames formats `fa` in English as `Persian`
    defaultMessage: 'Farsi',
    description: 'Name of a language a provider speaks: Farsi',
  },
  fi: {
    defaultMessage: 'Finnish',
    description: 'Name of a language a provider speaks: Finnish',
  },
  fil: {
    defaultMessage: 'Filipino',
    description: 'Name of a language a provider speaks: Filipino',
  },
  fr: {
    defaultMessage: 'French',
    description: 'Name of a language a provider speaks: French (all dialects)',
  },
  'fr-CA': {
    // NOTE: Intl.DisplayNames formats `fr-CA` in English as `Canadian French`
    defaultMessage: 'French (Canada)',
    description: 'Name of a language a provider speaks: French from Canada',
  },
  'fr-FR': {
    // NOTE: Intl.DisplayNames formats `fr-FR` in English as `French (France)`
    defaultMessage: 'French',
    description: 'Name of a language a provider speaks: French (all dialects)',
  },
  ga: {
    defaultMessage: 'Irish',
    description: 'Name of a language a provider speaks: Irish',
  },
  gu: {
    defaultMessage: 'Gujarati',
    description: 'Name of a language a provider speaks: Gujarati',
  },
  ha: {
    defaultMessage: 'Hausa',
    description: 'Name of a language a provider speaks: Hausa',
  },
  he: {
    defaultMessage: 'Hebrew',
    description: 'Name of a language a provider speaks: Hebrew',
  },
  hi: {
    defaultMessage: 'Hindi',
    description: 'Name of a language a provider speaks: Hindi',
  },
  hil: {
    // NOTE: `hil` is not defined in Intl.DisplayNames
    defaultMessage: 'Hiligaynon',
    description: 'Name of a language a provider speaks: Hiligaynon',
  },
  hu: {
    defaultMessage: 'Hungarian',
    description: 'Name of a language a provider speaks: Hungarian',
  },
  id: {
    defaultMessage: 'Indonesian',
    description: 'Name of a language a provider speaks: Indonesian',
  },
  ilo: {
    // NOTE: Intl.DisplayNames formats `ilo` in English as `Iloko`
    defaultMessage: 'Ilocano',
    description: 'Name of a language a provider speaks: Ilocano',
  },
  it: {
    defaultMessage: 'Italian',
    description: 'Name of a language a provider speaks: Italian (all dialects)',
  },
  jp: {
    defaultMessage: 'Japanese',
    description: 'Name of a language a provider speaks: Japanese',
  },
  jv: {
    defaultMessage: 'Javanese',
    description: 'Name of a language a provider speaks: Javanese',
  },
  kn: {
    defaultMessage: 'Kannada',
    description: 'Name of a language a provider speaks: Kannada',
  },
  ko: {
    defaultMessage: 'Korean',
    description: 'Name of a language a provider speaks: Korean',
  },
  ku: {
    defaultMessage: 'Kurdish',
    description: 'Name of a language a provider speaks: Kurdish',
  },
  lb: {
    defaultMessage: 'Luxembourgish',
    description: 'Name of a language a provider speaks: Luxembourgish',
  },
  lt: {
    defaultMessage: 'Lithuanian',
    description: 'Name of a language a provider speaks: Lithuanian',
  },
  ml: {
    defaultMessage: 'Malayalam',
    description: 'Name of a language a provider speaks: Malayalam',
  },
  mr: {
    defaultMessage: 'Marathi',
    description: 'Name of a language a provider speaks: Marathi',
  },
  ms: {
    defaultMessage: 'Malay',
    description: 'Name of a language a provider speaks: Malay',
  },
  nb: {
    defaultMessage: 'Norwegian Bokmål',
    description: 'Name of a language a provider speaks: Norwegian Bokmål',
  },
  ne: {
    defaultMessage: 'Nepali',
    description: 'Name of a language a provider speaks: Nepali',
  },
  nl: {
    defaultMessage: 'Dutch',
    description: 'Name of a language a provider speaks: Dutch',
  },
  nn: {
    defaultMessage: 'Norwegian Nynorsk',
    description: 'Name of a language a provider speaks: Norwegian Nynorsk',
  },
  pa: {
    defaultMessage: 'Punjabi',
    description: 'Name of a language a provider speaks: Punjabi',
  },
  pl: {
    defaultMessage: 'Polish',
    description: 'Name of a language a provider speaks: Polish',
  },
  pnb: {
    // NOTE: Intl.DisplayNames formats `pnb` in English as `lah`
    defaultMessage: 'Punjabi, Western',
    description: 'Name of a language a provider speaks: Punjabi, Western',
  },
  ps: {
    defaultMessage: 'Pashto',
    description: 'Name of a language a provider speaks: Pashto',
  },
  pt: {
    defaultMessage: 'Portuguese',
    description: 'Name of a language a provider speaks: Portuguese (all dialects)',
  },
  'pt-BR': {
    // NOTE: Intl.DisplayNames formats `pt-BR` in English as `Brazilian Portuguese`
    defaultMessage: 'Portuguese (Brazil)',
    description: 'Name of a language a provider speaks: Portuguese from Brazil',
  },
  'pt-PT': {
    // NOTE: Intl.DisplayNames formats `pt-PT` in English as `European Portuguese`
    defaultMessage: 'Portuguese (Portugal)',
    description: 'Name of a language a provider speaks: Portuguese from Portugal',
  },
  ro: {
    defaultMessage: 'Romanian',
    description: 'Name of a language a provider speaks: Romanian',
  },
  ru: {
    defaultMessage: 'Russian',
    description: 'Name of a language a provider speaks: Russian',
  },
  sd: {
    defaultMessage: 'Sindhi',
    description: 'Name of a language a provider speaks: Sindhi',
  },
  sk: {
    defaultMessage: 'Slovak',
    description: 'Name of a language a provider speaks: Slovak',
  },
  sr: {
    defaultMessage: 'Serbian',
    description: 'Name of a language a provider speaks: Serbian',
  },
  su: {
    defaultMessage: 'Sundanese',
    description: 'Name of a language a provider speaks: Sundanese',
  },
  sv: {
    defaultMessage: 'Swedish',
    description: 'Name of a language a provider speaks: Swedish',
  },
  ta: {
    defaultMessage: 'Tamil',
    description: 'Name of a language a provider speaks: Tamil',
  },
  te: {
    defaultMessage: 'Telugu',
    description: 'Name of a language a provider speaks: Telugu',
  },
  th: {
    defaultMessage: 'Thai',
    description: 'Name of a language a provider speaks: Thai',
  },
  tl: {
    defaultMessage: 'Tagalog',
    description: 'Name of a language a provider speaks: Tagalog',
  },
  tr: {
    defaultMessage: 'Turkish',
    description: 'Name of a language a provider speaks: Turkish',
  },
  tss: {
    // NOTE: `tss` is not defined in Intl.DisplayNames
    defaultMessage: 'Isan',
    description: 'Name of a language a provider speaks: Isan',
  },
  uk: {
    defaultMessage: 'Ukranian',
    description: 'Name of a language a provider speaks: Ukrainian',
  },
  ur: {
    defaultMessage: 'Urdu',
    description: 'Name of a language a provider speaks: Urdu',
  },
  vi: {
    defaultMessage: 'Vietnamese',
    description: 'Name of a language a provider speaks: Vietnamese',
  },
  war: {
    // NOTE: `war` is not defined in Intl.DisplayNames
    defaultMessage: 'Waray',
    description: 'Name of a language a provider speaks: Waray',
  },
  wuu: {
    // NOTE: `wuu` is not defined in Intl.DisplayNames
    defaultMessage: 'Chinese (Shanghainese)',
    description: 'Name of a language a provider speaks: Shanghainese Chinese',
  },
  yue: {
    defaultMessage: 'Cantonese',
    description: 'Name of a language a provider speaks: Cantonese',
  },
  zh: {
    defaultMessage: 'Chinese',
    description: 'Name of a language a provider speaks: Chinese',
  },
  'zh-Hans': {
    // NOTE: This is a written script and not an actual spoken language but still might be used
    defaultMessage: 'Simplified Chinese',
    description: 'Name of a language a provider speaks: Simplified Chinese',
  },
  'zh-Hant': {
    // NOTE: This is a written script and not an actual spoken language but still might be used
    defaultMessage: 'Traditional Chinese',
    description: 'Name of a language a provider speaks: Traditional Chinese',
  },
  'zh-TW': {
    defaultMessage: 'Taiwanese Mandarin',
    description: 'Name of a language a provider speaks: Taiwanese Mandarin',
  },
})

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const IS_PRODUCTION_MOBILE = process.env.PROFILE === 'production'
export const IS_PRODUCTION_WEB_OR_MOBILE = (IS_PRODUCTION && !process.env.PROFILE) || IS_PRODUCTION_MOBILE

export const VENDOR_STAGING_URL = 'https://vendorapi.staging.lyrahealth.com'
export const VENDOR_PROD_URL = 'https://vendorapi.lyrahealth.com'

export const GOODNESS_STAGING_URL = 'https://lyra-microsite-builder-preview.netlify.app'
const GOODNESS_STAGING_SPANISH_URL_BASE = 'https://lyra-microsite-builder-es-preview.netlify.app'
export const GOODNESS_STAGING_SPANISH_URL = `${GOODNESS_STAGING_SPANISH_URL_BASE}/es`
export const GOODNESS_PROD_URL = 'https://lyra-microsite-builder.bukwild.com/secure'
export const GOODNESS_PROD_SPANISH_URL = 'https://lyra-microsite-builder-es.netlify.app/es/secure'

export const ALLOWED_CROSS_ORIGIN_URLS = IS_PRODUCTION_WEB_OR_MOBILE
  ? [GOODNESS_PROD_URL, GOODNESS_PROD_SPANISH_URL]
  : [GOODNESS_STAGING_URL, GOODNESS_STAGING_SPANISH_URL_BASE]

// For web: process.env.PROFILE will always be undefined
// For mobile: process.env.NODE_ENV is always 'production' and we use process.env.PROFILE to define the build
export const VENDOR_BASE_API_URL = IS_PRODUCTION_WEB_OR_MOBILE ? VENDOR_PROD_URL : VENDOR_STAGING_URL

export const RESOURCE_COORDINATOR_MICROSITE_URL = `${
  IS_PRODUCTION_WEB_OR_MOBILE ? GOODNESS_PROD_URL : GOODNESS_STAGING_URL
}/resource-coordinator-hub`

export const NICOTINE_QUITLINE_MICROSITE_URL = `${
  IS_PRODUCTION_WEB_OR_MOBILE ? GOODNESS_PROD_URL : GOODNESS_STAGING_URL
}/nicotine-quitline`

export const BCTFellowshipInfoVideoUrl = 'https://vimeo.com/748166679'
export const BCTFellowshipInfoVideoId = '748166679'
export const LYRA_ALL_CUSTOMER_HIGH_ALERT_TEAM_PHONE = '(877) 505-7147'
export const CARE_ADVISOR_DISPLAY_PHONE_NUMBER = '(877) 505-7147'
export const CARE_ADVISOR_TRUNK_PHONE_NUMBER = '1-877-505-7147'
export const RAW_TRUNK_PHONE_NUMBER = '18775057147'
export const CARE_NAVIGATOR_EXTENSION = 'ext. 2'
export const CARE_ADVISOR_EMAIL = 'care@lyrahealth.com'
export const ICAS_POLICIES_LINK = 'https://lyrahealthinternational.com/our-policies/'
export const ICAS_CONTACT_EMAIL = 'internationaleap@lyrahealth.com'
export const ICAS_LIVE_CHAT_LINK =
  'https://salesiq.zoho.eu/signaturesupport.ls?widgetcode=a8f1afc834944fe50b5aa15c7794568539b5e75df986f253a1b11fab47040512ada48887b90b8d00f40a15f507be9152'
export const ICAS_DEFAULT_MAIN_PHONE_NUMBER_DISPLAY = '+44 33 00 241 021'
export const DEFAULT_COUNTRY_ISO_CODE = 'US'
export const DOMESTIC_COUNTRY_ISO_CODES = ['US', 'UM', 'VI'] // Countries to be filtered out for OUS-only customers

export const RESOURCE_COORDINATOR_SCHEDULE_CALL_LINK =
  'https://go.oncehub.com/ResourceCoordination?referralsource=RcMicrosite'

export const NICOTINE_QUITLINE_SCHEDULE_CALL_LINK = 'https://go.oncehub.com/NicotineQuitline?referralsource=NqMicrosite'

export const DEFAULT_LAUNCH_DATE = '2023-01-01T00:00:00+00:00'
export const STAGING_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCCNsTEST' // This link is used for customers launching on or after 1/1/2023
export const STAGING_DEFAULT_DIRECT_CARE_ADVOCATE_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCAsTEST' // This link is used for customers that launched before 1/1/2023
export const STAGING_DIRECT_NAVGIATOR_LINK =
  'https://go.oncehub.com/DedicatedCareNavigatorTest?name=ebay&email=carestaging@lyrahealth.com'
export const PRODUCTION_DEFAULT_DIRECT_CARE_NAVIGATOR_BOOKING_LINK_BASE = 'https://go.oncehub.com/DirectBookingCNs'
export const PRODUCTION_ASD_BOOKING_LINK_BASE = 'https://go.oncehub.com/LyraASDCheckin?utm_source=child-search-for-care'

export const SCHEDULE_ONCE_ASSERTIVE_TRIAGE_LINK = 'https://go.oncehub.com/assertivetriage'

export const INTL_PRIVACY_POLICY_LINK = 'https://lyrahealthinternational.com/privacy-policy/'

export const SUICIDE_PREVENTION_LIFELINE = '988'
export const LYRIANS_SUPPORT_LINK = 'https://lyrians.zendesk.com/'
export const STAGING_REFER_ORGANIZATION_LINK = 'https://lyrastage.wpengine.com/refer'
export const PRODUCTION_REFER_ORGANIZATION_LINK = 'https://www.lyrahealth.com/refer'

export const DOMESTIC_PROVIDER_CONTACT_EMAIL = 'providers@lyrahealth.com'
export const INTERNATIONAL_PROVIDER_CONTACT_EMAIL = 'internationalproviders@lyrahealth.com'

export const CDN_ASSETS_LINK_BASE = 'https://assets.lyrahealth.com'

export const APPLE_APP_STORE_LINK = 'https://apps.apple.com/app/lyra-health/id1534654470'
export const GOOGLE_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.lyra.lyrawebmobile&pli=1'

export const LYRA_MOBILE_APP_LINK = 'https://www.lyrahealth.com/app/'
export const BRANCH_APP_LINK = IS_PRODUCTION ? 'https://lyra.app.link/' : 'https://lyra.test-app.link/'

export const USER_MIN_AGE_REQUIREMENT_DEPRECATED = {
  DEFAULT: 0,
  DOMESTIC_EMPLOYEE: 15,
  DOMESTIC_DEPENDENT: 18,
  INTERNATIONAL_EMPLOYEE: 16,
  INTERNATIONAL_DEPENDENT: 18,
}

export const USER_MIN_AGE_REQUIREMENT = {
  DEFAULT: 0,
  DOMESTIC_EMPLOYEE: 13,
  DOMESTIC_DEPENDENT: 13,
  INTERNATIONAL_EMPLOYEE: 16,
  INTERNATIONAL_DEPENDENT: 18,
}

const THIS_YEAR = new Date().getFullYear()

export const USER_MAX_AGE_REQUIREMENT = {
  // IRA limitation that minimum dob is 1900
  ADULT: Math.min(150, THIS_YEAR - 1900),
}

export const DEFAULT_AGE_CONSTRAINTS: { min: number; max: number } = {
  min: USER_MIN_AGE_REQUIREMENT.DEFAULT,
  max: USER_MAX_AGE_REQUIREMENT.ADULT,
}

export type EligibilityType = 'dependent_other' | 'employee' | 'unknown'

export enum ELIGIBILITY_TYPES {
  DEPENDENT = 'dependent_other',
  EMPLOYEE = 'employee',
  UNKNOWN = 'unknown',
}

export type GenerateCustomMetadata = (params: {
  intl: IntlShape
  isMobileSized: boolean
  isInternational: boolean
  eligibilityInfo: EligibilityInfo
  eligibilityType?: EligibilityType
  colors: ThemeColors
}) => FormMetadata

export interface CustomEligibilityMetadata {
  generateMetadata: GenerateCustomMetadata
  getCustomFields?: (eligibilityInfo: EligibilityInfo) => {
    [name: string]: FunctionComponent
  }
}

export type EligibilityInfo = {
  customerName: string
  eligibilityQuestionTitle?: MessageDescriptor | string
  eligibilityQuestionSubtitle?: string
  eligibilityQuestionSubtitleComponent?: string
  /** Title shown above form asking a dependent for the information of their benefit provider*/
  eligibilityDependentTitle?: MessageDescriptor | string
  dependentButtonText?: MessageDescriptor | string
  employeeRegistrationNomenclature?: string
  dependentRegistrationNomenclature?: string
  hideEmployeeInfo?: boolean
  hideDependentField?: boolean
  eligibilityTypeSelectionCopy?: React.ReactNode
  dependentTypes?: DependentTypes
  dependentTypeId?: string
  showRegistrationTips?: boolean
  showEligibilityQuestionForInternationalRegistration?: boolean
  customEligibilityMetadata?: CustomEligibilityMetadata
  hasBlockingEligibilityCheck?: boolean
  checkAdultDependentEligibilityUsingDependentInfo?: boolean
  employeeEligibilityError?: React.ReactNode
  isPristineConfirmEligibility?: boolean // true for confirm eligibility form is in pristine state
  isAttestation?: boolean // true for eligibility form during verifying attestation
  onContactCNT?: () => void
}

// Used to identify different types of customers and distinguish between our standard employer customers and all other non-standard customers
export enum PROGRAM_MEMBERSHIP_TYPES {
  EMPLOYEE = 'employee', // Standard customer
  MEMBER = 'member', // Customers whose clients are referred to as members rather than employees
}

export enum FetchStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const LYRA_LEARN_SSO_LINK =
  process.env.NODE_ENV === 'production'
    ? 'https://lyrahealth.okta.com/home/lyrahealth_starbuckslyralearn_1/0oafej0g41V558jek297/alnfej59laieeHAEs297'
    : 'https://lyrahealth.oktapreview.com/home/lyrahealth_testdocebointegration_1/0oa1ap9oujqvQ6ODb0h8/aln1ap9vap8Yrp71Z0h8'

/**
 * Meeting format required by the BE
 */
export const SESSION_FORMAT = [
  {
    id: 'Video',
    text: 'Video',
    group: 0,
  },
  {
    id: 'In-Person',
    text: 'In-Person',
    group: 0,
  },
  {
    id: 'Live-Messaging',
    text: 'Live-Messaging',
    group: 0,
  },
]
/**
 * Default services that Lyra does not cover.
 * You should only be importing this if you need this list in a custom string, e.g. a custom "servicesNotCovered" string
 * that doesn't match the content of the servicesNotCovered string here. Otherwise, you probably want to be importing
 * servicesNotCovered instead.
 * If your customer has a custom "servicesNotCovered" string and doesn't cover meds, check defaultWithoutMeds.js
 */
export const excludedServices = `inpatient or residential treatment, hospitalization (including partial),
  intensive outpatient treatment, emergent care, long-term care or counseling, prescription medication,
  autism spectrum disorder treatment, services for remedial education, executive coaching, and non-evidence-based behavioral health care`

export enum CUSTOMERS_SUBECTIONS {
  UNSUPPORTED_OPTIONS = 'unsupported_options',
}

export const CONTACT_CARE_TEAM_REQUEST_TYPES = {
  USER_INITIATED_DEFAULT: 'USER_INITIATED_DEFAULT',
  ZERO_PROVIDERS_FOUND: 'ZERO_PROVIDERS_FOUND',
  ZERO_POC_PROVIDERS_FOUND: 'ZERO_POC_PROVIDERS_FOUND',
  ASSERTIVE_TRIAGE_SCREENING: 'Assertive Triage Screening',
  CARE_PREFERENCES: 'CARE_PREFERENCES',
  HOMEBASE: 'HOMEBASE',
  CSS_TEAM_SUPPORT: 'CSS_TEAM_SUPPORT',
  SPECIALIZED_NEED: 'specialized need',
  OTHER_PRIMARY_NEEDS: 'other primary needs',
} as const

export const COMPONENT_CONFIGURATION = {
  [CONTACT_CARE_TEAM_REQUEST_TYPES.USER_INITIATED_DEFAULT]: {
    requestHeader: null,
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_PROVIDERS_FOUND]: {
    requestHeader: 'Zero providers found',
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_POC_PROVIDERS_FOUND]: {
    requestHeader: 'Zero POC providers found',
  },
  [CONTACT_CARE_TEAM_REQUEST_TYPES.CARE_PREFERENCES]: {
    requestHeader: 'Contact care team from care preferences',
  },
}

export const navigatorTicketTypes = {
  [CONTACT_CARE_TEAM_REQUEST_TYPES.USER_INITIATED_DEFAULT]: 'GET_OPTIONS',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_PROVIDERS_FOUND]: 'ZERO_MATCH',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ZERO_POC_PROVIDERS_FOUND]: 'ZERO_MATCH_POC',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.CARE_PREFERENCES]: 'CARE_PREFERENCES',
  [CONTACT_CARE_TEAM_REQUEST_TYPES.ASSERTIVE_TRIAGE_SCREENING]: 'Assertive Triage Screening',
}

export type ContactCareTeamRequestType =
  (typeof CONTACT_CARE_TEAM_REQUEST_TYPES)[keyof typeof CONTACT_CARE_TEAM_REQUEST_TYPES]

export const FOOTER_VERSION = {
  US_VERSION: 'US_VERSION',
  US_VERSION_OUS_SUPPORT: 'US_VERSION_OUS_SUPPORT',
  OUS_VERSION: 'OUS_VERSION',
}

export const INACTIVITY_WARNING_DURATION_MS = 60 * 1000

export const RESEND_EMAIL_TIMEOUT_DURATION_SECONDS = 60

export const SESSION_COUNT_STARTS_ON_FORMAT = 'MMM-dd'

export const STATES = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AS',
    label: 'American Samoa',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'DC',
    label: 'District of Columbia',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'GU',
    label: 'Guam',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
]

export const US_TERRITORIES = ['Puerto Rico', 'Guam']

// LaunchDarkly Experimentation custom metric events.
export enum LD_CUSTOM_EVENTS {
  LOCATION_FINDER_VIEWED = 'location-finder-viewed',
  MEMBER_PREFERENCES_SCREEN_VIEWED = 'member-preferences-screen-viewed',
  PROVIDER_PREFERENCES_SELECTION_SCREEN_VIEWED = 'provider-preferences-selection-screen-viewed',
  MEET_MY_MATCHES_CLICKED = 'meet-my-matches-clicked',
  PROVIDERS_RECOMMENDATIONS_SCREEN_VIEWED = 'providers-recommendations-screen-viewed',
  APPOINTMENT_BOOKING_SCREEN_VIEWED = 'appointment-booking-screen-viewed',
  BOOK_APPOINTMENT = 'book-appointment',
  MICROSITE_VIEWED = 'microsite-viewed',
  MICROSITE_SELF_CARE_TOPICS_VIEWED = 'microsite-self-care-topics-viewed',
  MICROSITE_SELF_CARE_REGISTER = 'microsite-self-care-register',
  REGISTRATION_CAPTURE_EMAIL_VIEWED = 'registration-capture-email-viewed',
  REGISTRATION_CAPTURE_EMAIL_SIGN_UP_CLICKED = 'registration-capture-email-sign-up-clicked',
  REGISTRATION_SET_UP_YOUR_ACCOUNT_VIEWED = 'registration-set-up-your-account-viewed',
  REGISTRATION_USER_COMPLETES_REGISTRATION = 'registration-user-completes-registration',
  FINISHED_TRIAGE = 'finished-triage',
  INDIVIDUAL_THERAPY_REC_CLICKED = 'individual-therapy-rec-clicked',
  INITIAL_VIDEO_SESSION_END = 'initial-video-session-end',
  HOMEBASE_VIEWED = 'homebase-viewed',
  TREATMENT_OPTION_SELECTED = 'treatment-option-selected',
}

export const VIDEO_ASPECT_RATIO = 16 / 9

export enum PATHWAYS_TYPE {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  COMING_SOON = 'comingSoon',
}

export enum SESSION_STORAGE_KEY {
  TAB_ID = 'tabId',
  NATIVE_APP_FROM_LW = 'nativeAppFromLW',
}

export const NATIVE_APP_PATH_SEGMENT = '1lyra'
export const NATIVE_APP_PATH_PREFIX = `/${NATIVE_APP_PATH_SEGMENT}`

export enum CoverageType {
  EAP_ONLY = 'eapOnly',
  HPI_ONLY = 'hpiOnly',
  EAP_AND_HPI = 'eapAndHpi',
}

export const COST_MESSAGING_COVERAGE_KEY_DEPENDENT_SUFFIX = 'WithDependent'
export const COST_MESSAGING_COVERAGE_KEY_HPI_MEDS_SUFFIX = 'WithHpiMeds'

export interface CostMessagingModalCoverageInfo {
  customerCoverageType?: CoverageType
}

export type CostMessagingCoverageModalData = {
  coverageModalContents: CostMessagingModalContent[]
  costMessagingModalCoverageInfo?: CostMessagingModalCoverageInfo
  isCostMessagingEnabled: boolean
}

export type CostMessagingModalContent = {
  title: React.ReactNode
  description: React.ReactNode
}

export const WORK_HUB_EVENT_REGISTRATION_RETRY_MESSAGE = defineMessage({
  defaultMessage: "We couldn't register you for this event right now. Refresh the page and try again.",
  description: 'Message to user indicating they could not be registered for a Zoom event at this time',
})
export const WORK_HUB_EVENT_REGISTRATION_LIMIT_REACHED_MESSAGE = defineMessage({
  defaultMessage: "You've hit today's limit of three registrations for this event. Try again tomorrow!",
  description: 'Message to user indicating they reached the limit for registering for this specific event',
})
export const WORK_HUB_EVENT_DEREGISTRATION_RETRY_MESSAGE = defineMessage({
  defaultMessage: "We couldn't unregister you for this event right now. Refresh the page and try again.",
  description: 'Message to user indicating they could not be deregistered for a Zoom event at this time',
})

export enum DEPENDENT_VARIANT {
  CHILD = 'child',
  TEEN = 'teen',
  SELF_MANAGED_TEEN = 'self-managed-teen',
  ADULT = 'adult',
}

export const ESSENTIALS_IS_INITIAL_LOAD_PARAM = 'X-Is-Initial-Load'

export const SENTRY_OMITTED_ERROR_CODES = [404, 401, 403]

export const NAME_FIELD_MAX_CHARACTER_LIMIT = 32
